import React, { useCallback, useContext, useState } from 'react'

import Loader from '../../../../shared/Loader'
import useFetch from '../../../../../hooks/useFetch'
import { getIncidentsStats } from '../../../../../helpers/requests/stats'
import Card from '../../../../shared/Card'
import HorizontalBarChart from '../../../Charts/HorizontalBarChart'
import VerticalBarChart from '../../../Charts/VerticalBarChart/VerticalBarChart'
import Total from '../../../TextStats/Total'
import { parseIncidentsByCourier, parseIncidentsByType, parseLastWeekIncidents } from '../../../../../helpers/processStats/IncindentsStats'
import IncidentsTable from '../../../TextStats/IncidentsTable'
import DashboardContext from '../../../DashboardContext'

const IncidentsStats = ({ currentFilters }) => {
  const [stats, setStats] = useState()
  const { setUpdateDate } = useContext(DashboardContext)

  const setStatsData = useCallback((statsData) => {
    setStats(statsData.incidentStats)
    setUpdateDate(statsData.incidentStats.updatedAt)
  }, [setUpdateDate])

  const { isLoading, error } = useFetch(getIncidentsStats, setStatsData, currentFilters)

  if (isLoading || error) {
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          {isLoading ? <Loader /> : <div>Ha ocurrido un error. Inténtelo más tarde.</div>}
        </div>
      </div>
    )
  }

  return (
    <div className="my-4 flex flex-wrap gap-5">
      <Card title="Incidencias">
        <Total
          total={stats.totalCount}
          variation={stats.totalCountVariation}
          positive={stats.totalCountVariation >= 0}
        />
      </Card>
      <Card
        title="Incidencias por día"
        secondText={`Promedio: ${stats.lastWeek.weeklyIncidentsCountAverage}`}
      >
        <HorizontalBarChart
          data={parseLastWeekIncidents(stats.lastWeek.lastWeekCounts)}
          average={stats.lastWeek.weeklyIncidentsCountAverage}
        />
      </Card>
      <Card title="Incidencias por Courier">
        <VerticalBarChart
          data={parseIncidentsByCourier(stats.byCourier, stats.totalCount)}
          maxValue={stats.totalCount}
        />
      </Card>
      <Card title="Incidencias por Tipo">
        <div className="flex">
          <VerticalBarChart
            data={parseIncidentsByType(stats.byType, stats.totalCount)}
            maxValue={stats.totalCount}
          />
        </div>
      </Card>
      {stats.activeIncidents.length > 0 && (
        <IncidentsTable incidents={stats.activeIncidents} />
      )}
    </div>
  )
}

export default IncidentsStats
