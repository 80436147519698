import React, { useCallback, useContext, useState } from 'react'

import Card from '../../../shared/Card'
import HalfDonutPieChart from '../../Charts/HalfDonutPieChart'
import VerticalBarChart from '../../Charts/VerticalBarChart/VerticalBarChart'
import StatusBox from '../../TextStats/StatusBox'
import Operator from '../../../shared/Operator'
import { WAITING_TIMES } from '../../../../constants/stats'
import Loader from '../../../shared/Loader'
import useFetch from '../../../../hooks/useFetch'
import { getSLAStats } from '../../../../helpers/requests/stats'
import {
  getSum,
  parseSLAByCourier,
  parseSLAByDeliveryType,
  parseSLABySection
} from '../../../../helpers/processStats/SLAStats'
import DashboardContext from '../../DashboardContext'

const SLAAndCourier = ({ currentFilters }) => {
  const [stats, setStats] = useState()
  const { setUpdateDate } = useContext(DashboardContext)

  const setStatsData = useCallback((statsData) => {
    setStats(statsData.slaStats)
    setUpdateDate(statsData.slaStats.updatedAt)
  }, [setUpdateDate])

  const { isLoading, error } = useFetch(getSLAStats, setStatsData, currentFilters)

  if (isLoading || error) {
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          {isLoading ? <Loader /> : <div>Ha ocurrido un error. Inténtelo más tarde.</div>}
        </div>
      </div>
    )
  }

  return (
    <div className="my-4 flex flex-wrap gap-5">
      <Card title="SLA Total">
        <HalfDonutPieChart
          data={[
            {
              name: '',
              value: parseInt(stats.totalSLA* 100, 10),
              percentage: `${(stats.totalSLA * 100).toFixed(2)}%`,
              fill: '#DC94FF'
            }
          ]}
          title=""
        />
      </Card>
      <Card title="SLA Courier">
        <HalfDonutPieChart
          data={[
            {
              name: '',
              value: parseInt(stats.totalCourierSLA* 100, 10),
              percentage: `${(stats.totalCourierSLA * 100).toFixed(2)}%`,
              fill: '#FFC061'
            }
          ]}
          title=""
        />
      </Card>
      <Card title="SLA por courier">
        <VerticalBarChart data={parseSLAByCourier(stats.byCourier)} maxValue={100} />
      </Card>
      <Card title="SLA por tipo de envío">
        <div className="flex flex-wrap">
          {parseSLAByDeliveryType(stats.byDeliveryType).map((dataDonut) => (
            <HalfDonutPieChart data={dataDonut} title={dataDonut[0].name} key={dataDonut[0].name} />
          ))}
        </div>
      </Card>
      <Card>
        <div className="flex">
          <Card title="SLA por Tramo">
            <VerticalBarChart data={parseSLABySection(stats.bySection)} maxValue={100} />
          </Card>
          <Card title="Tiempos promedio por tramo">
            <div className="mx-4 flex flex-wrap gap-4">
              {WAITING_TIMES.map((waitingTime, index) => (
                <div className="flex items-center">
                  <StatusBox
                    key={waitingTime.name}
                    status={waitingTime.name}
                    amount={stats.sectionAverageDays[waitingTime.paramName]}
                    icon={waitingTime.icon}
                    days
                  />
                  {index === WAITING_TIMES.length - 1 ? (
                    <Operator value="=" />
                  ) : (
                    <Operator value="+" />
                  )}
                </div>
              ))}
              <StatusBox
                status="Total Estimado"
                amount={getSum(stats.sectionAverageDays)}
                days
                result
              />
            </div>
          </Card>
        </div>
      </Card>
    </div>
  )
}

export default SLAAndCourier
