import { React, useState } from 'react'

import EditGroupButton from '../components/shared/EditGroupButton'
import InputTableFloat from '../components/shared/Input/InputTableFloat'
import Loader from '../components/shared/Loader'
import ConfirmModal from '../components/shared/Modal/ConfirmModal'
import Table from '../components/shared/Table/Table'
import TableRow from '../components/shared/Table/TableRow'
import { getDiscountMatrix, updateDiscountMatrix } from '../helpers/requests/couriers'
import useFetch from '../hooks/useFetch'

const DiscountMatrix = () => {
  const [discounts, setDiscounts] = useState({})
  const [editedDiscounts, setEditedDiscounts] = useState({})
  const [editing, setEditing] = useState(false)
  const [isOpenConfirm, setOpenConfirm] = useState(false)
  const { isLoading, error, setError } = useFetch(getDiscountMatrix, setDiscounts)

  const orderByCourier = (discount1, discount2) => {
    const courierName1 = discount1[0]
    const courierName2 = discount2[0]

    if (courierName1 > courierName2) return 1

    if (courierName1 < courierName2) return -1

    return 0
  }

  const discountList = discounts.discounts
    ? Object.entries(discounts.discounts).sort(orderByCourier)
    : []

  const getDiscountValue = (courier, plan, discount) =>
    editedDiscounts[courier] && editedDiscounts[courier][plan] !== undefined
      ? editedDiscounts[courier][plan]
      : discount[plan]

  const handleSubmit = async () => {
    setEditing(false)
    const body = { discounts: editedDiscounts }
    try {
      await updateDiscountMatrix(body)
      setOpenConfirm(false)
      window.location.reload()
    } catch {
      setError(true)
    }
  }

  const handleCancel = () => {
    setEditedDiscounts({})
  }

  const changeDiscount = (courier, plan, value) => {
    setEditedDiscounts({
      ...editedDiscounts,
      [courier]: {
        ...editedDiscounts[courier],
        [plan]: parseFloat(value)
      }
    })
  }

  if (isLoading || error) {
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          {isLoading ? <Loader /> : <div>Ha ocurrido un error. Inténtelo más tarde.</div>}
        </div>
      </div>
    )
  }

  return (
    <div className="m-10">
      <div className="mb-8 text-left text-xl font-semibold">Matriz de Descuento</div>
      <Table headers={['Courier', 'Pyme', 'Mediana', 'Grande', 'Extra Grande']} roundedTop>
        {discountList.map(([courier, discount]) => (
          <TableRow
            key={courier}
            items={[
              courier,
              ...['pyme', 'medium', 'large', 'extralarge'].map((plan) => (
                <InputTableFloat
                  value={getDiscountValue(courier, plan, discount)}
                  onChange={(e) => {
                    changeDiscount(courier, plan, e.target.value)
                  }}
                  disabled={!editing}
                  isEdited={editedDiscounts[courier] && editedDiscounts[courier][plan]}
                />
              ))
            ]}
          />
        ))}
      </Table>
      <EditGroupButton
        editing={editing}
        setEditing={setEditing}
        isEdited={Object.keys(editedDiscounts).length > 0}
        handleCancel={handleCancel}
        handleSubmit={() => setOpenConfirm(true)}
      />
      <ConfirmModal
        handleSubmit={handleSubmit}
        show={isOpenConfirm}
        handleClose={() => setOpenConfirm(false)}
      />
    </div>
  )
}

export default DiscountMatrix
