import apiClient from '../../axiosConfig/apiClient'

import {
  PACKAGE_DELIVERED_URL,
  PACKAGE_IN_TRANSIT_URL,
  PACKAGE_RECEPTION_URL,
  SALES_URL
} from '../../constants/endpointUrls/api'

export const getSale = async (packageId, params) => {
    const response = await apiClient.get(`/sales/${packageId}`, params)

    return response.data
}

export const fetchSale = async (config) => {
  const { params } = config
  const { packageId } = params
  delete params.packageId

  const data = await getSale(packageId, { ...config, params })

  return data
}

export const getSales = async (params) => {
  const response = await apiClient.get(SALES_URL, params)

  return response.data
}

export const changeToInTransit = (packageId, date) => {
  const body = { packages: [{ packageId, date }] }

  return apiClient.put(PACKAGE_IN_TRANSIT_URL, body)
}

export const changeToOnDelivery = (packageId, date) => {
  const body = { packages: [{ packageId, date }] }

  return apiClient.put('/companies/couriers/package/on-delivery', body)
}

export const changeToReadyForCollection = (packageId, date) => {
  const body = { packages: [{ packageId, date }] }

  return apiClient.put(PACKAGE_RECEPTION_URL, body)
}

export const changeToCollected = (packageId, date) => {
  const body = { packages: [{ packageId, date }] }

  return apiClient.put(PACKAGE_DELIVERED_URL, body)
}

export const changeToOnReturn  = (packageId, date) => {
  const body = { packages: [{ packageId, date }] }

  return apiClient.put('/companies/couriers/package/on-return', body)
}

export const changeToReturned  = (packageId, date) => {
  const body = { packages: [{ packageId, date }] }

  return apiClient.put('/companies/couriers/package/returned', body)
}

export const getCourierLogs = async (pinflagId) => {
  const response = await apiClient.get(`/packages/${pinflagId}/courier/logs`)

  return response.data
}
