import React from 'react'
import { NOTIFICATION_SECTIONS } from '../../constants/notificationCenter'

const ViewBar = ({ setCurrentView, currentView }) => {
  const checkCurrentView = (viewToCheck) => viewToCheck === currentView
  const getBarColor = (viewToCheck) => {
    if (checkCurrentView(viewToCheck)) return 'bg-pinflag-dark'
    return 'bg-medium-gray'
  }

  return (
    <div className="mx-2 flex h-6 w-fit">
      {NOTIFICATION_SECTIONS.map((view) => (
        <div key={view.name} className="relative flex flex-col">
          <button
            className={`w-56 pb-1 text-left text-sm text-pinflag-dark ${
              checkCurrentView(view.name) ? 'font-semibold' : 'font-normal'
            }`}
            type="button"
            onClick={() => setCurrentView(view.name)}
          >
            {view.label}
          </button>

          <div
            className={`absolute bottom-[-15%] h-1 w-full rounded-full ${getBarColor(view.name)}`}
          />
        </div>
      ))}
    </div>
  )
}

export default ViewBar
