import React from 'react'

import Table from '../../shared/Table/Table'
import TableRow from '../../shared/Table/TableRow'
/* import Actions from './Actions' */
import { greenCheck } from '../../../constants/icons'

const CompaniesTable = ({ companies, isLoading, error }) => {

  if (!isLoading && !error && companies.length === 0) {
    return (
      <div className='mx-10 border-x border-b bg-white py-8 text-center text-s'>
        No hay empresas
      </div>
    )
  }

  return (
    <Table
      maxHeight='max-h-[66vh]'
      headers={['Empresa', 'Holding', 'Tiene mapa', 'Tiene delivery conditions']}
      isLoading={isLoading}
      error={error}
    >
      {companies?.map((company) => (
        <TableRow
          key={company.apiKey}
          items={[
            company.name,
            company.holdingName,
            <div className='m-auto w-fit px-1'>
              {company.hasMap && <img alt='icon' src={greenCheck} className='mr-1 h-auto w-5' />}
            </div>,
            <div className='m-auto w-fit px-1'>
              {company.hasDeliveryConditions && <img alt='icon' src={greenCheck} className='mr-1 h-auto w-5' />}
            </div>
            /* <div className='relative'>
              <Actions
                up
              />
            </div> */
          ]}
        />
      ))}
    </Table>
  )
}

export default CompaniesTable
