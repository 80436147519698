import { useEffect, useRef, useState } from 'react'

const useClickOutside = () => {
  const [isOpen, setIsOpen] = useState(false)
  const popUpRef = useRef()

  useEffect(() => {
    const closePopUp = (event) => {
      if (!popUpRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', closePopUp)
    return () => document.removeEventListener('mousedown', closePopUp)
  }, [])

  return [ isOpen, setIsOpen, popUpRef ]
}

export default useClickOutside
