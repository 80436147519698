import axios from 'axios'

import { COURIER_URL } from '../constants/endpointUrls/courier'

const courierClient = axios.create({
  baseURL: COURIER_URL,
  headers: { 'x-api-key': process.env.REACT_APP_COURIER_KEY }
})

export default courierClient
