import React from 'react'
import Loader from '../../shared/Loader'
import { greenCheck, errorIcon } from '../../../constants/icons'
import Modal from '../../shared/Modal/Modal'

const CreateBillModal = ({
  isLoading,
  responseMessage,
  handleBillCreation,
  setShouldFetch,
  showModal,
  setShowModal,
  setResponseMessage
}) => (
  <Modal
    show={showModal}
    handleClose={() => {
      setShowModal(false)
      setResponseMessage({})
    }}
  >
    <div className="m-10 text-sm">
      <div className="my-8 text-center text-lg">Crear Factura</div>
      <div className="text-center ">Vas a crear facturas para el mes actual</div>
      <div className="text-center ">¿Deseas continuar?</div>
      {isLoading && <Loader />}
      {!isLoading && responseMessage.data?.message && (
        <div className="mt-2 flex flex-col items-center justify-center">
          <img
            className="h-auto w-16"
            src={responseMessage.status === 200 ? greenCheck : errorIcon}
            alt={
              responseMessage.status === 200 ? 'Facturas creadas' : 'Error al crear las facturas'
            }
          />
          {responseMessage.data.message}
        </div>
      )}
      <div className="mt-8 flex w-full justify-between">
        <button
          type="button"
          className="h-10 w-24 rounded-lg border-2 border-pinflag-dark hover:opacity-75"
          onClick={() => {
            setShowModal(false)
            setResponseMessage({})
          }}
        >
          Cancelar
        </button>
        <button
          type="button"
          className="h-10 w-24 rounded-lg border-2 border-pinflag-dark bg-pinflag-dark text-white hover:opacity-75"
          onClick={() => handleBillCreation().then(() => setShouldFetch(true))}
        >
          Continuar
        </button>
      </div>
    </div>
  </Modal>
)
export default CreateBillModal
