import React from 'react'

const InputTableFloat = ({ value = '', onChange, disabled, isEdited, name = '' }) => (
  <input
    type="number"
    className={`w-16 border border-medium-gray ${isEdited && 'bg-light-pinflag'}`}
    step="0.01"
    min="0"
    max="1"
    value={value}
    onChange={onChange}
    disabled={disabled}
    name={name}
  />
)

export default InputTableFloat
