/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'

const TableRow = ({ items, onClick }) => {

  const handleClick = (event) => {
    if (event.currentTarget !== event.target) return
    onClick()
  }


  return (
    <tr className={`w-full ${onClick ? 'cursor-pointer hover:bg-light-grey' : ''}`}>
      <td className="w-8" onClick={handleClick} />
      {items.map((item, index) => (
        <td onClick={handleClick} key={index} className="whitespace-nowrap p-2 text-left">
          {item || '-'}
        </td>
      ))}
    </tr>
  )
}

export default TableRow
