import { React, useState } from 'react'

import { SALES_DETAIL_BUTTONS_ICONS } from '../../constants/icons'
import Button from '../shared/Button'
import useGetLabels from './useGetLabels'
import ResponseModal from '../shared/Modal/ResponseModal'
import ConfirmModal from '../shared/Modal/ConfirmModal'
import Modal from '../shared/Modal/Modal'
import FileViewer from '../shared/FileViewer'
import TabBar from '../shared/TabBar'
import { saleStatusColor } from '../../helpers/sales'
import {
  PACKAGE_BOUGHT,
  PACKAGE_DELIVERED,
  PACKAGE_PROCESSED,
  PACKAGE_RETURNED,
  PACKAGE_STATUS_TRANSLATE,
  PACKAGE_TOTAL_INCIDENT
} from '../../constants/packageStatus'

import ModifyPackage from './ModifyPackage/ModifyPackage'
import StatusManager from './StatusManager/StatusManager'

const SaleHeader = ({
  order,
  saleId,
  packageStatus,
  evidencePhotos,
  deliveryType,
  saleEditableData,
  stateId,
  city,
  warehouseName
}) => {
  const [responseModalOpen, setResponseModalOpen] = useState(false)
  const [openModifyPackage, setOpenModifyPackage] = useState(false)
  const [openStatusManager, setOpenStatusManager] = useState(false)
  const [openEvidence, setOpenEvidence] = useState(false)
  const [evidencePhotoType, setEvidencePhotoType] = useState(0)

  const evidencePhotoTypes = Object.keys(evidencePhotos)

  const { getLabels, isLoadingLabels, errorMessage, openConfirm, setOpenConfirm } = useGetLabels(
    setResponseModalOpen,
    true
  )

  return (
    <>
      <div className="flex flex-wrap justify-between rounded border bg-white p-6">
        <div className="font-medium">
          Venta: {order}
          <span className={`ml-2 w-fit rounded px-2 py-1 text-s ${saleStatusColor(packageStatus)}`}>
            {PACKAGE_STATUS_TRANSLATE[packageStatus]}
          </span>
        </div>
        <div className="flex flex-wrap gap-x-2">
          {Object.keys(evidencePhotos).length > 0 && (
            <Button
              onClick={() => setOpenEvidence(true)}
              border="border border-ultra-dark-grey"
              textColor="text-ultra-dark-grey"
              small
            >
              <div className="flex gap-1">
                <img
                  src={SALES_DETAIL_BUTTONS_ICONS.evidencePhoto}
                  alt="evidencia"
                  className="h-auto w-4"
                />
                Evidencia entrega
              </div>
            </Button>
          )}
          {[PACKAGE_BOUGHT, PACKAGE_PROCESSED].includes(packageStatus) && (
            <Button
              onClick={() => setOpenModifyPackage(true)}
              border="border border-ultra-dark-grey"
              textColor="text-ultra-dark-grey"
              small
            >
              <div className="flex gap-1">
                <img
                  src={SALES_DETAIL_BUTTONS_ICONS.edit}
                  alt="Editar pedido"
                  className="h-auto w-4"
                />
                Editar info de entrega
              </div>
            </Button>
          )}
          <Button
            onClick={
              deliveryType === 'storePickup' && packageStatus === PACKAGE_BOUGHT
                ? () => setOpenConfirm(true)
                : () => getLabels([saleId])
            }
            border="border border-ultra-dark-grey"
            textColor="text-ultra-dark-grey"
            small
          >
            <div className="flex gap-1">
              <img
                src={SALES_DETAIL_BUTTONS_ICONS.download}
                alt="descargar"
                className="h-auto w-4"
              />
              {!isLoadingLabels ? <>Descargar etiqueta</> : <>Cargando...</>}
            </div>
          </Button>
          {![PACKAGE_DELIVERED, PACKAGE_TOTAL_INCIDENT, PACKAGE_RETURNED].includes(
            packageStatus
          ) && (
            <Button
              onClick={() => setOpenStatusManager(true)}
              border="border border-ultra-dark-grey"
              textColor="text-ultra-dark-grey"
              small
            >
              Cambiar estado
            </Button>
          )}
        </div>
      </div>
      <ResponseModal
        handleClose={() => setResponseModalOpen(false)}
        isModalOpen={responseModalOpen}
        errorMessage={errorMessage}
        error
      />
      <ConfirmModal
        isModalOpen={openConfirm}
        handleClose={() => setOpenConfirm(false)}
        handleConfirm={() => getLabels([saleId])}
        loading={isLoadingLabels}
      >
        La venta seleccionada tiene como destino un punto de retiro propio ¿Desea continuar?
      </ConfirmModal>
      <ModifyPackage
        isOpen={openModifyPackage}
        handleClose={() => setOpenModifyPackage(false)}
        saleData={saleEditableData}
        clickAndCollect={deliveryType !== 'homeDelivery'}
        stateId={stateId}
        city={city}
        warehouseName={warehouseName}
        packageId={saleId}
        packageStatus={packageStatus}
      />
      <Modal
        show={openEvidence}
        title={
          <div className="flex gap-4 overflow-hidden">
            Evidencia de entrega
            <TabBar
              tabs={evidencePhotoTypes}
              tab={evidencePhotoType}
              setTab={setEvidencePhotoType}
            />
          </div>
        }
        handleClose={() => setOpenEvidence(false)}
      >
        <div className="mx-4">
          <FileViewer files={evidencePhotos[evidencePhotoTypes[evidencePhotoType]]} />
        </div>
      </Modal>
      <StatusManager
        status={packageStatus}
        packageId={saleId}
        clickAndCollect={deliveryType !== 'homeDelivery'}
        open={openStatusManager}
        handleClose={() => setOpenStatusManager(false)}
      />
    </>
  )
}
export default SaleHeader
