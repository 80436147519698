import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import Modal from '../../shared/Modal/Modal'
import Button from '../../shared/Button'
import RawLoader from '../../shared/Loader'
import { getSale } from '../../../helpers/requests/sales'
import ROUTES from '../../../constants/routes'
import useFetch from '../../../hooks/useFetch'
import { getHoldings } from '../../../helpers/requests/companies'

const SaleSearcher = ({ open, handleClose }) => {
  const [inputSaleOrder, setInputSaleOrder] = useState('')
  const [holdings, setHoldings] = useState({})
  const [selectedHolding, setSelectedHolding] = useState(null)
  const [selectedCompanyId, setSelectedCompanyId] = useState(null)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const { push } = useHistory()
  useFetch(getHoldings, setHoldings)

  const handleSaleOrder = async () => {
    setLoading(true)
    try {
      const fetchedSale = await getSale(inputSaleOrder, { params: { companyId: selectedCompanyId }})

      push(`${ROUTES.CREATE_REFUND.split(':')[0]}${fetchedSale.packageId}`)
    } catch {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal show={open} handleClose={handleClose}>
      Ingresa la orden de venta para continuar
      <input
        className="mt-8 w-full rounded border p-1"
        value={inputSaleOrder}
        onChange={(e) => setInputSaleOrder(e.target.value)}
      />
      <select
          className="mt-8 w-full rounded border p-1"
          onChange={(event) => {
            setSelectedHolding(
              holdings.find((holding) => holding.id === parseInt(event.target.value, 10))
            )
            setSelectedCompanyId(undefined)
          }}
        >
          <option value="" hidden>
            Holding (opcional)
          </option>
          {Object.values(holdings).map((holding) => (
            <option key={holding.id} value={holding.id}>
              {holding.name}
            </option>
          ))}
        </select>
        <select
          className="mt-8 w-full rounded border p-1"
          onChange={(event) => setSelectedCompanyId(event.target.value)}
          value={selectedCompanyId || ''}
          disabled={!selectedHolding}
        >
          <option value="" hidden>
            Empresa (opcional)
          </option>
          {selectedHolding?.companies?.map((company) => (
            <option key={company.companyId} value={company.companyId}>
              {company.name}
            </option>
          ))}
        </select>
      {error && <div className="text-xs text-red">No se ha podido encontrar la orden de venta ingresada</div>}
      <div className="mt-9 flex justify-end">
        {loading ? (
          <RawLoader />
        ) : (
          <Button color="bg-pinflag" onClick={handleSaleOrder}>
            Continuar
          </Button>
        )}
      </div>
    </Modal>
  )
}

export default SaleSearcher
