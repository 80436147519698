import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Table from '../../shared/Table/Table'
import TableRow from '../../shared/Table/TableRow'
import {
  deleteNotification,
  updateNotifications
} from '../../../helpers/requests/notificationCenter'
import { getFormattedDateWithoutTimezone } from '../../../utils/dates'
import ConfirmModal from '../ConfirmModal'
import Loader from '../../shared/Loader'
import CustomToggle from '../InputFields/CustomToggle'

const GeneralNotifications = ({ holdings, notifications, setShouldFetch }) => {
  const [currentNotification, setCurrentNotification] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [tooltipNotificationId, setTooltipNotificationId] = useState(null)
  const [loading, setLoading] = useState(false)

  const { push } = useHistory()

  const tooltipRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setTooltipNotificationId(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const howManyHoldings = (notification) =>
    notification.Holdings.length === holdings.length
      ? 'Todas'
      : `${notification.Holdings.length}/${holdings.length}`

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const removeNotification = async () => {
    setLoading(true)
    await deleteNotification(currentNotification.id)
    setShouldFetch(true)
    setLoading(false)
    setCurrentNotification({})
    handleCloseModal()
  }

  const handleNotificationToggle = async (notificationId, currentActiveStatus) => {
    setLoading(true)

    const notificationToEdit = notifications.find(
      (notification) => notification.id === notificationId
    )

    await updateNotifications({
      ...notificationToEdit,
      active: !currentActiveStatus
    })
    setShouldFetch(true)

    setLoading(false)
  }

  return (
    <div className=" flex h-screen flex-col bg-light-grey">
      <ConfirmModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleSubmit={() => removeNotification()}
        message="Deseas eliminar la notificación?"
        confirmButtonText="Eliminar"
      />
      <div className="mb-8 text-left text-xl font-semibold">Anuncios Activos</div>
      {loading && <Loader />}
      {!loading && (
        <Table
          headers={[
            'Nombre',
            'Fecha creación',
            'Empresas',
            'Tipo de Notificación',
            'Estado Notificación',
            'Acción'
          ]}
        >
          {notifications?.map((notification) => (
            <TableRow
              key={notification.id}
              onClick={() => {}}
              items={[
                <div className="flex h-9 w-48 items-center text-base font-semibold">
                  {notification.name || '-'}
                </div>,
                <div className="flex h-9 w-48 items-center text-base">
                  {getFormattedDateWithoutTimezone(notification.createdAt) || '-'}
                </div>,
                <div className="flex h-9 w-48 items-center text-base text-medium-gray">
                  {howManyHoldings(notification)}
                </div>,
                <div className="flex h-9 w-48 items-center text-base">
                  {notification.type || '-'}
                </div>,
                <CustomToggle
                  notification={notification}
                  handleNotificationToggle={handleNotificationToggle}
                />,
                <div className="relative w-12">
                  {tooltipNotificationId === notification.id && (
                    <div
                      ref={tooltipRef}
                      className="absolute left-[-150%] top-[-150%] z-10 border-2 bg-white p-2 drop-shadow-lg "
                    >
                      <button
                        type="button"
                        onClick={() => {
                          push(`notificaciones/edit/${notification.id}/general`)
                        }}
                        className="block w-full px-2 py-1 text-left hover:bg-normal-grey"
                      >
                        Editar Anuncio
                      </button>
                      <button
                        type="button"
                        className="block w-full px-2 py-1 text-left hover:bg-red"
                        onClick={() => {
                          setShowModal(true)
                          setCurrentNotification(notification)
                        }}
                      >
                        Eliminar
                      </button>
                    </div>
                  )}
                  <button
                    type="button"
                    onClick={() => setTooltipNotificationId(notification.id)}
                    className="z-0 h-9 w-fit rotate-90 text-base font-semibold"
                  >
                    ...
                  </button>
                </div>
              ]}
            />
          ))}
        </Table>
      )}
    </div>
  )
}

export default GeneralNotifications
