import React from 'react'
import { VIEW_BAR } from '../../constants/billing'

const ViewBar = ({ setCurrentView, currentView }) => {
  const checkCurrentView = (viewToCheck) => viewToCheck === currentView
  const getBarColor = (viewToCheck) => {
    if (checkCurrentView(viewToCheck) && currentView === 'pedidos') return 'bg-orange'
    if (checkCurrentView(viewToCheck)) return 'bg-pinflag-dark'
    return 'bg-medium-gray'
  }
  const getTextPosition = (view) => {
    const width = 'w-40'
    if (view === 'couriers') return `${width} text-left`
    if (view === 'pedidos') return 'w-20 text-right'
    return `${width} text-center`
  }

  return (
    <div className="mx-2 flex h-6 w-fit">
      {VIEW_BAR.map((view) => (
        <div key={view.name} className="relative flex flex-col">
          <button
            className={`pb-1 text-sm text-pinflag-dark ${getTextPosition(view.name)} ${
              checkCurrentView(view.name) ? 'font-semibold' : 'font-normal'
            }`}
            type="button"
            onClick={() => setCurrentView(view.name)}
          >
            {view.label}
          </button>

          <div
            className={`absolute bottom-[-15%] h-1 w-full rounded-full ${getBarColor(view.name)}`}
          />
        </div>
      ))}
    </div>
  )
}

export default ViewBar
