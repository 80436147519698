import { React, useState } from 'react'

import Chip from '../shared/Chip'
import Loader from '../shared/Loader'
import Table from '../shared/Table/Table'
import TableRow from '../shared/Table/TableRow'
import { DAYS, SCHEDULES_STATUS } from '../../constants/pickupSchedules'
import { getPickUpSchedules } from '../../helpers/requests/companies'
import useFetch from '../../hooks/useFetch'
import { capitalizeFirstLetter } from '../../utils/strings'

const PickUpSchedules = () => {
  const [schedules, setSchedules] = useState([])

  const { isLoading, error } = useFetch(getPickUpSchedules, setSchedules)

  if (isLoading || error) {
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          {isLoading ? <Loader /> : <div>Ha ocurrido un error. Inténtelo más tarde.</div>}
        </div>
      </div>
    )
  }

  return (
    <div className="m-10">
      <Table headers={['Holding', 'Courier', 'Horario', 'Día(s)', 'Estado', 'Tipo']}>
        {schedules?.map((schedule) => (
          <TableRow
            key={schedule.id}
            items={[
              'empresa',
              capitalizeFirstLetter(schedule.courierName),
              schedule.schedule?.time,
              DAYS[schedule.schedule?.day] || '-',
              <Chip
                status={SCHEDULES_STATUS[schedule.status].name}
                color={SCHEDULES_STATUS[schedule.status].color}
              />,
              schedule.schedule?.weekly ? 'Recurrente' : 'Excepcional'
            ]}
          />
        ))}
      </Table>
    </div>
  )
}

export default PickUpSchedules
