/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'

import Loader from '../Loader'
import LoadingError from '../LoadingError'

const Table = ({
  headers,
  children,
  doubleHeaders,
  headerColor,
  maxHeight,
  sortValues = {},
  sortData,
  backgroundColor,
  textHeaderColor,
  roundedTop,
  isLoading,
  error
}) => {
  const [order, setOrder] = useState('asc')
  const [sortedField, setSortedField] = useState('')

  const handleSort = (header) => {
    let newOrder
    if (sortedField === header) {
      newOrder = order === 'asc' ? 'desc' : 'asc'
    } else {
      newOrder = 'asc'
    }
    setSortedField(header)
    sortData(sortValues[header], newOrder)
    setOrder(newOrder)
  }

  if (isLoading) {
    return (
      <div
        className={`mx-10 rounded-b border ${
          roundedTop ? 'rounded-t' : ''
        } bg-white py-8 text-center text-s`}
      >
        <Loader />
      </div>
    )
  }

  if (error) {
    return (
      <div
        className={`mx-10 rounded-b border ${
          roundedTop ? 'rounded-t' : ''
        } bg-white py-8 text-center text-s`}
      >
        <LoadingError />
      </div>
    )
  }

  return (
    <div
      className={`mx-10 overflow-x-auto rounded-b border ${maxHeight || ''} ${
        roundedTop ? 'rounded-t' : ''
      }`}
    >
      <table className="w-full text-left text-s font-light text-medium-dark-grey">
        <thead
          className={`${textHeaderColor || 'text-ultra-dark-grey'} ${
            headerColor || 'bg-light-grey'
          }`}
        >
          {doubleHeaders && (
            <tr>
              {doubleHeaders.map((doubleHeader) => (
                <th key={doubleHeader.text} rowSpan="1" colSpan={doubleHeader.colSpan}>
                  {doubleHeader.text}
                </th>
              ))}
            </tr>
          )}
          <tr>
            <th className="w-8" />
            {headers.map((header, index) => (
              <th
                key={index}
                scope="col"
                className={`whitespace-nowrap py-3 font-medium ${header.color || ''}`}
              >
                <div className="relative flex gap-1">
                  {header.text || header}
                  {sortValues[header] && (
                    <button
                      className="flex flex-col"
                      type="button"
                      onClick={() => handleSort(header)}
                    >
                      <svg
                        className={`absolute top-0 h-4 w-4 ${
                          sortedField === header && order === 'asc'
                            ? 'fill-ultra-dark-grey'
                            : 'fill-dark-grey'
                        }`}
                      >
                        <path d="M4.90002 9.79999L8.40002 6.29999L11.9 9.79999H4.90002Z" />
                      </svg>
                      <svg
                        className={`absolute top-0.5 h-4 w-4 ${
                          sortedField === header && order === 'desc'
                            ? 'fill-ultra-dark-grey'
                            : 'fill-dark-grey'
                        }`}
                      >
                        <path d="M8.40002 13.7L4.90002 10.2H11.9L8.40002 13.7Z" />
                      </svg>
                    </button>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody
          className={`divide-y border-y text-left ${backgroundColor || 'bg-white'} overflow-auto`}
        >
          {children}
        </tbody>
      </table>
    </div>
  )
}

export default Table
