import React, { useCallback, useContext, useState } from 'react'

import FunnelChart from '../../Charts/FunnelChart/FunnelChart'
import { PINMAP_TEXTS } from '../../../../constants/stats'
import { parsePinmapProStats } from '../../../../helpers/processStats/pinmapProStats'
import useFetch from '../../../../hooks/useFetch'
import { getPinmapStats } from '../../../../helpers/requests/stats'
import Loader from '../../../shared/Loader'
import DashboardContext from '../../DashboardContext'

const PinmapProStats = ({ currentFilters }) => {
  const [stats, setStats] = useState({})
	const { setUpdateDate } = useContext(DashboardContext)

  const setStatsData = useCallback((statsData) => {
    setStats(statsData.checkoutStats.summary)
		setUpdateDate(statsData.checkoutStats.updatedAt)
  }, [setUpdateDate])

  const { isLoading, error } = useFetch(getPinmapStats, setStatsData, currentFilters)

  if (isLoading || error) {
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          {isLoading ? <Loader /> : <div>Ha ocurrido un error. Inténtelo más tarde.</div>}
        </div>
      </div>
    )
  }

    return(
    <div className="my-4 overflow-y-auto">
        <FunnelChart data={parsePinmapProStats(stats)} color="#DC94FF" texts={PINMAP_TEXTS} />
    </div>
    )
}
export default PinmapProStats
