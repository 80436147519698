import { checkEmail } from '../../utils/strings'

export const customerValidations = {
  lastname: { required: 'El campo \'Apellido\' es requerido' },
  email: {
    validate: {
      isEmail: (value) => checkEmail(value) || 'El campo \'Email\' debe tener un correo válido'
    },
    required: 'El campo \'Email\' es requerido'
  },
  phone: {
    required: 'El campo \'Teléfono\' es requerido',
    pattern: {
      value: /^\d+$/,
      message: 'Ingrese un teléfono válido'
    },
    minLength: {
      value: 9,
      message: 'El campo \'Teléfono\' debe tener 9 dígitos'
    },
    maxLength: {
      value: 9,
      message: 'El campo \'Teléfono\' debe tener 9 dígitos'
    }
  },
  ssn: {
    required: 'El campo \'RUT\' es requerido',
    pattern: {
      value: /^([0-9]{7,9}-[\dkK])/,
      message: 'Ingrese el rut sin puntos y con guión'
    }
  }
}

export const shippingValidations = {
  cityId: { required: 'Debe seleccionar una comuna' },
  address: { required: 'El campo \'Dirección\' es requerido' }
}
