import React, { useState } from 'react'

import useFetch from '../hooks/useFetch'
import CompaniesTable from '../components/Companies/CompaniesTable/CompaniesTable'
import Button from '../components/shared/Button'
import RegisterCompanyModal from '../components/Companies/CompaniesTable/RegisterCompanyModal'
import { getCompaniesData } from '../helpers/requests/companies'

const ManageCompanies = () => {
  const [showRegisterModal, setShowRegisterModal] = useState(false)
  const [companiesData, setCompaniesData] = useState([])

  const { isLoading, error } = useFetch(getCompaniesData, setCompaniesData)

  return (
    <div className='flex h-screen flex-col bg-white'>
      <RegisterCompanyModal
        show={showRegisterModal}
        handleClose={() => setShowRegisterModal(false)}
      />
      <div className="pt-10 pl-10 text-left text-xl font-semibold">Empresas</div>
      <div className='flex justify-end p-10'>
        <Button color='bg-pinflag' onClick={() => setShowRegisterModal(true)}>
          Crear Nueva Empresa
        </Button>
      </div>
      <CompaniesTable
        companies={companiesData}
        isLoading={isLoading}
        error={error}
      />
    </div>
  )
}

export default ManageCompanies
