import React from 'react'

import { backArrow } from '../../constants/icons'

const HeaderTitle = ({ title, subtitle, icon, goBack }) => (
  <div className="flex flex-wrap pt-8 pb-4 pl-10 pr-24">
    <button onClick={goBack} type="button" className="flex">
      <img className="my-auto mr-4 h-4 w-4" src={backArrow} alt="Volver" />
    </button>
    {icon && <img className="mr-4 h-6 w-6" src={icon} alt={title} />}
    <span className="mr-2 text-base font-medium text-ultra-dark-grey">{title}</span>
    {subtitle && (
      <>
        <span className="mr-2 text-xs leading-loose text-ultra-dark-grey">&gt;</span>
        <span className="text-base text-ultra-dark-grey">{subtitle}</span>
      </>
    )}
  </div>
)

export default HeaderTitle
