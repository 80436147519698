import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import Button from '../../shared/Button'
import { createRefund } from '../../../helpers/requests/refunds'
import ResponseModal from '../../shared/Modal/ResponseModal'
import FilePicker from '../../shared/FilePicker'
import Loader from '../../shared/Loader'

const RefundForm = ({ packageId, setStep, refundedProducts, setRefundId }) => {
  const [error, setError] = useState(false)
  const [paymentProof, setPaymentProof] = useState(null)
  const [evidenceFiles, setEvidenceFiles] = useState([])
  const [loading, setLoading] = useState(false)

  const handleEvidenceFiles = async (objectFiles) => {
    const newFiles = [...objectFiles]
    setEvidenceFiles([...evidenceFiles, ...newFiles])
  }

  const removeEvidenceFile = (index) => {
    const newFiles = [...evidenceFiles]
    newFiles.splice(index, 1)
    setEvidenceFiles(newFiles)
  }

  const addProductsToFormData = (formData) => {
    const refundProducts = refundedProducts.map((product) => ({
      sku: product.sku,
      cost: product.price,
      quantity: product.quantity,
      name: product.productName
    }))

    formData.append('refundProducts', JSON.stringify(refundProducts))
  }

  const addFilesToFormData = (formData) => {
    formData.append('files', paymentProof)
    for (const evidenceFile of evidenceFiles) {
      formData.append('files', evidenceFile)
    }
  }

  const makeFormData = (data) => {
    const formData = new FormData()
    addProductsToFormData(formData)
    addFilesToFormData(formData)
    formData.append('packageId', packageId)
    formData.append('companyId', '1')
    for (const formItem of Object.entries(data)) {
      if (formItem[0] === 'bankAccountData') {
        formData.append('bankAccountData', JSON.stringify(formItem[1]))
      } else {
        formData.append(formItem[0], formItem[1])
      }
    }
    return formData
  }

  const onSubmit = async (data) => {
    const body = makeFormData(data)
    setLoading(true)
    try {
      const response = await createRefund(body)
      setError(false)
      setRefundId(response.id)
      setStep(3)
    } catch {
      setError(true)
    }
    setLoading(false)
  }

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onBlur' })

  const fieldClassName =
    'mb-2 w-full px-2 py-1 rounded border text-medium-dark-grey font-light text-s'

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col text-s">
        Detalle
        <input
          {...register('refundReason', { required: true })}
          className={`${fieldClassName} ${errors.refundReason ? 'border-red' : ''}`}
        />
        Monto a solicitar
        <input
          {...register('claimedRefund', { required: true })}
          className={`${fieldClassName} ${errors.claimedRefund ? 'border-red' : ''}`}
        />
        <div className="my-2">Datos bancarios</div>
        <div className="mb-2 grid grid-cols-2 gap-3">
          <div className="flex flex-col">
            Banco
            <input
              {...register('bankAccountData.bank', { required: true })}
              className={`${fieldClassName} ${errors.bankAccountData?.bank ? 'border-red' : ''}`}
            />
            Tipo de cuenta
            <input
              {...register('bankAccountData.typeAccount', { required: true })}
              className={`${fieldClassName} ${
                errors.bankAccountData?.typeAccount ? 'border-red' : ''
              }`}
            />
          </div>
          <div className="flex flex-col">
            Rut
            <input
              {...register('bankAccountData.rut', { required: true })}
              className={`${fieldClassName} ${errors.bankAccountData?.rut ? 'border-red' : ''}`}
            />
            Nro. de cuenta
            <input
              {...register('bankAccountData.accountNumber', { required: true })}
              className={`${fieldClassName} ${
                errors.bankAccountData?.accountNumber ? 'border-red' : ''
              }`}
            />
          </div>
        </div>
        <div className="mb-5 flex gap-4">
          <div>
            Adjunta comprobante de pago:
            <FilePicker
              editing
              handleFile={(fileContent) => setPaymentProof(fileContent[0])}
              file={paymentProof}
              isLoaded={!!paymentProof}
              download
            />
          </div>
          <div>
            Adjunta evidencia (opcional):
            <FilePicker
              editing
              files={evidenceFiles}
              handleFile={handleEvidenceFiles}
              removeFile={removeEvidenceFile}
              multiple
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-2">
        <Button color="bg-dark-grey" onClick={() => setStep(1)}>
          Volver
        </Button>
        {paymentProof && !loading && (
          <Button color="bg-pinflag" submit>
            Solicitar Reembolso
          </Button>
        )}
        {loading && <Loader />}
      </div>
      <ResponseModal isModalOpen={error} handleClose={() => setError(false)} error />
    </form>
  )
}

export default RefundForm
