import React, { useMemo, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import HeaderTitle from '../../components/shared/HeaderTitle'
import ROUTES from '../../constants/routes'
import useFetch from '../../hooks/useFetch'
import { fetchSale } from '../../helpers/requests/sales'
import { identifierCreator } from '../../helpers/sales'
import Button from '../../components/shared/Button'
import Loader from '../../components/shared/Loader'
import LoadingError from '../../components/shared/LoadingError'
import SelectProducts from '../../components/Refunds/CreateRefund/SelectProducts'
import RefundForm from '../../components/Refunds/CreateRefund/RefundForm'
import Summary from '../../components/Refunds/CreateRefund/Summary'

const CreateRefund = () => {
  const { packageId } = useParams()
  const [sale, setSale] = useState({})
  const [step, setStep] = useState(1)
  const [selectedProducts, setSelectedProducts] = useState({})
  const [refundId, setRefundId] = useState('')

  const { push } = useHistory()

  const params = useMemo(() => ({params: { packageId, origin: 'admin' }}), [packageId])

  const { isLoading, error } = useFetch(fetchSale, setSale, params)

  return (
    <div className="min-h-screen w-full bg-light-grey">
      <HeaderTitle title="Paquetes" subtitle="Detalle" goBack={() => push(ROUTES.REFUNDS)} />
      <div className="mx-10 pb-4">
        <div className="mb-4 flex flex-wrap justify-between rounded border bg-white p-6">
          <div>
            <div className="font-medium">Solicitar indemnización</div>
            <div className="text-s">
              Proporciona toda la información que consideres necesaria para poder resolver tu caso.
            </div>
          </div>
          <div>{step} de 3</div>
        </div>
        {isLoading && (
          <div className="mt-10">
            <Loader />
          </div>
        )}
        {error && <LoadingError />}
        {!isLoading && !error && step === 1 && (
          <div className="rounded border bg-white p-6">
            <div className="flex justify-between text-s">
              <div>
                <div className="font-medium">Escoge el producto</div>
                Selecciona la cantidad de productos para el cual deseas crear una solicitud.
              </div>
              <div className="flex gap-4">
                <div>
                  <div className="font-medium">O. Venta</div>
                  {identifierCreator(sale.companyName)}-{sale.saleOrder}
                </div>
                <div>
                  <div className="font-medium">O. Transporte</div>
                  {sale.trackingNumber}
                </div>
              </div>
            </div>
            <SelectProducts
              products={sale.products}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
            />
            <div className="flex justify-end">
              <Button color="bg-pinflag" onClick={() => setStep(2)}>
                Continuar
              </Button>
            </div>
          </div>
        )}
        {!isLoading && !error && [2, 3].includes(step) && (
          <div className="flex gap-4">
            <div className="rounded border bg-white p-6">
              <div className="text-sm font-medium">Productos a indemnizar</div>
              {Object.values(selectedProducts).map((product) => (
                <div className="my-4 flex w-72 gap-3 rounded border p-2 text-xs">
                  {product.photoUrl.length > 0 && product.photoUrl[0].URLIMAGEN ? (
                    <img
                      src={product.photoUrl[0].URLIMAGEN}
                      alt={product.productName}
                      className="h-auto max-h-[5rem] w-auto max-w-[5rem]"
                    />
                  ) : (
                    <div className="h-20 w-20 bg-semi-light-grey" />
                  )}
                  <div>
                    <div className="font-medium">{product.productName}</div>
                    <div>SKU: {product.sku}</div>
                    <div>Precio: {product.price}</div>
                    <div>Cantidad: {product.quantity}</div>
                  </div>
                </div>
              ))}
            </div>
            <div className="w-full rounded border bg-white p-6">
              {step === 2 && (
                <RefundForm
                  packageId={sale.packageId}
                  setStep={setStep}
                  setRefundId={setRefundId}
                  refundedProducts={Object.values(selectedProducts)}
                />
              )}
              {step === 3 && <Summary refundId={refundId} />}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CreateRefund
