import { React, useCallback, useMemo, useState } from 'react'
import { NumberParam, useQueryParam } from 'use-query-params'

import CheckFilters from '../components/Sales/CheckFilters/CheckFilters'
import StateFilter from '../components/Sales/StateFilter/StateFilter'
import Loader from '../components/shared/Loader'
import Pagination from '../components/shared/Pagination'
import Table from '../components/shared/Table/Table'
import TableRow from '../components/shared/Table/TableRow'
import { shortDate } from '../utils/dates'
import useFetch from '../hooks/useFetch'
import { getSales } from '../helpers/requests/sales'
import SearchBar from '../components/shared/Input/Searchbar'
import { isSubstring } from '../utils/strings'
import { DELIVERY_TYPES, PACKAGE_TYPES } from '../constants/sales'
import TabBar from '../components/shared/TabBar'

const Sales = () => {
  const [sales, setSales] = useState([])
  const [filterStatus, setFilterStatus] = useState(new Set([1]))
  const [isFiltered, setIsFiltered] = useState(false)
  const [filteredSales, setFilteredSales] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchBarInput, setSearchBarInput] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const [packageType = 0, setPackageType] = useQueryParam('packageType', NumberParam)

  const type = PACKAGE_TYPES[packageType]

  const params = useMemo(
    () => ({ params: { status: [...filterStatus].join(','), packageType: type } }),
    [filterStatus, type]
  )

  const setSalesData = useCallback((data) => {
    setSales(data)
    setSearchResult(data)
  }, [])

  const { isLoading, error } = useFetch(getSales, setSalesData, params)

  const searchBarFilter = (salesList, searchTerm) => {
    if (searchTerm !== '') {
      const searchedSales = salesList.filter(
        (sale) =>
          isSubstring(sale.codbultogenvisible, searchTerm) ||
          isSubstring(sale.emailpersona, searchTerm) ||
          isSubstring(sale.codbultocliente, searchTerm) ||
          isSubstring(sale.courierName, searchTerm)
      )
      setSearchResult(searchedSales)
    } else {
      setSearchResult(salesList)
    }
  }

  const searchHandler = (searchTerm) => {
    setSearchBarInput(searchTerm)
    const salesList = isFiltered ? filteredSales : sales
    searchBarFilter(salesList, searchTerm, true)
  }

  const handleFilterChange = (salesList) => {
    setFilteredSales(salesList)
    searchBarFilter(salesList, searchBarInput, false)
  }

  const currentSales = searchResult.slice(pageSize * (page - 1), pageSize * page)

  if (error) {
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          <div>Ha ocurrido un error. Inténtelo más tarde.</div>
        </div>
      </div>
    )
  }

  return (
    <div className="m-10">
      <div className="mb-8 text-left text-xl font-semibold">Ventas</div>
      <div className="flex">
        <div className="my-auto">
          <TabBar tabs={PACKAGE_TYPES} tab={packageType} setTab={setPackageType} />
        </div>
        <SearchBar
          searchbarInput={searchBarInput}
          searchKeyword={searchHandler}
          setSearchbarInput={(search) => {
            setSearchBarInput(search)
            setPage(1)
          }}
        />
      </div>
      <div className="lg:flex">
        <StateFilter filterStates={filterStatus} setFilterStates={setFilterStatus} />
        <CheckFilters
          sales={sales}
          isFiltered={isFiltered}
          setIsFiltered={setIsFiltered}
          handleFilterChange={handleFilterChange}
          dataLoaded={isLoading}
        />
      </div>
      <div className="max-h-1/2-screen overflow-y-auto">
        {isLoading ? (
          <Loader />
        ) : (
          <Table
            headers={[
              'Empresa',
              'O. venta',
              'Fecha de compra',
              'Mail',
              'Courier',
              'Tipo de envío',
              'ID Pinflag',
              'Incidentes'
            ]}
          >
            {currentSales?.map((sale) => (
              <TableRow
                key={sale.idbulto}
                items={[
                  sale.companyName,
                  sale.codbultocliente,
                  shortDate(sale.fechacompra),
                  sale.emailpersona,
                  sale.courierName,
                  DELIVERY_TYPES[sale.deliveryType],
                  sale.codbultogenvisible,
                  sale.incident ? 'sí' : 'no'
                ]}
              />
            ))}
          </Table>
        )}
      </div>
      <Pagination
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        itemsCount={searchResult.length}
      />
    </div>
  )
}

export default Sales
