import { React, useEffect, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { getSale } from '../helpers/requests/sales'
import Loader from '../components/shared/Loader'
import SaleDetail from '../components/Sale/SaleDetail'
import { getHoldings } from '../helpers/requests/companies'
import useFetch from '../hooks/useFetch'
import Button from '../components/shared/Button'
import { ERROR_MESSAGES, defaultMessageError } from '../constants/messages'

const Sale = () => {
  const [searchInput, setSearchInput] = useState('')
  const [saleId, setSaleId] = useQueryParam('saleId', StringParam)
  const [sale, setSale] = useState({})
  const [holdings, setHoldings] = useState({})
  const [selectedHolding, setSelectedHolding] = useState(null)
  const [selectedCompanyId, setSelectedCompanyId] = useQueryParam('companyId', StringParam)

  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useFetch(getHoldings, setHoldings)

  const searchSale = async () => {
    if (searchInput === '') return

    setSaleId(searchInput)
  }

  useEffect(() => {
    const fetchSale = async () => {
      setLoading(true)
      try {
        const response = await getSale(saleId, {params: {
          showNotActive: true,
          companyId: selectedCompanyId,
          origin: 'admin'
        }})

        setSale(response)
      } catch (fetchError) {
        setError(true)
        setErrorMessage(ERROR_MESSAGES[fetchError.response?.status] || defaultMessageError)
      }
      setLoading(false)
    }
    if (saleId) {
      fetchSale()
    }
  }, [saleId, selectedCompanyId])

  return (
    <div className="mx-10 py-6">
      <div className="flex gap-4 rounded border bg-white p-4 text-start text-sm">
        <div className="mt-auto pb-1 font-medium">Búsqueda:</div>
        <input
          className="rounded border py-1 pl-4"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder="ID venta"
        />
        <select
          className="rounded border pl-2"
          onChange={(event) => {
            setSelectedHolding(
              holdings.find((holding) => holding.id === parseInt(event.target.value, 10))
            )
            setSelectedCompanyId(undefined)
          }}
        >
          <option value="" hidden>
            Holding (opcional)
          </option>
          {Object.values(holdings).map((holding) => (
            <option key={holding.id} value={holding.id}>
              {holding.name}
            </option>
          ))}
        </select>
        <select
          className="rounded border pl-2"
          onChange={(event) => setSelectedCompanyId(event.target.value)}
          value={selectedCompanyId || ''}
          disabled={!selectedHolding}
        >
          <option value="" hidden>
            Empresa (opcional)
          </option>
          {selectedHolding?.companies?.map((company) => (
            <option key={company.companyId} value={company.companyId}>
              {company.name}
            </option>
          ))}
        </select>
        <Button border="border border-pinflag" textColor="text-pinflag" onClick={searchSale} small>
          Buscar
        </Button>
      </div>
      {isLoading && (
        <div className="mt-4 rounded border bg-white py-10">
          <Loader />
        </div>
      )}
      {error && !isLoading && (
        <div className="mt-4 rounded border bg-white py-10 text-center text-sm">{errorMessage}</div>
      )}
      {!isLoading && !error && Object.keys(sale).length > 0 && <SaleDetail sale={sale} />}
      {!isLoading && !error && Object.keys(sale).length === 0 && (
        <div className="mt-4 rounded border bg-white py-10 text-center text-sm">
          Ingrese un ID de venta{' '}
          <span className="text-dark-grey">(pinflag ID, package ID u O. de venta)</span>
        </div>
      )}
    </div>
  )
}

export default Sale
