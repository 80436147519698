import React from 'react'

import Loader from '../Loader'

import Modal from './Modal'

const ConfirmModal = ({ show, handleSubmit, handleClose, isLoading, message = '¿Desea guardar cambios?' }) => (
  <Modal show={show} handleClose={handleClose}>
    <div className="m-8">
      {message}
      {isLoading ? (
        <div className="mt-7">
          <Loader />
        </div>
      ) : (
        <div
          className="m-auto mt-7 w-fit rounded-2xl bg-green p-3 text-white"
          onClick={handleSubmit}
          role="button"
          tabIndex="0"
        >
          Confirmar
        </div>
      )}
    </div>
  </Modal>
)

export default ConfirmModal
