import apiClient from '../../axiosConfig/apiClient'
import { CHANGE_TICKET_STATUS, CLOSE_TICKET, SEND_MESSAGE_URL, TICKETS_URL } from '../../constants/endpointUrls/api'

export const getTickets = async () => {
  const response = await apiClient.get(TICKETS_URL)

  return response.data
}

export const getTicket = async (id) => {
  const response = await apiClient.get(`${TICKETS_URL}/${id}`)

  return response.data
}

export const changeTicketStatus = (ticketId, statusSelected, body) =>
  apiClient.put(CHANGE_TICKET_STATUS(ticketId, statusSelected), body)

export const closeTicket = (ticketId, body) =>
  apiClient.put(CLOSE_TICKET(ticketId), body)

export const sendTicketMessage = async (ticketId, message) => {
  const body = {
    message,
    sender: 'pinflag'
  }
  const response = await apiClient.post(SEND_MESSAGE_URL(ticketId), body)

  return response.data
}

export const addImageToTicket = async (ticketId, formData) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }

  const response = await apiClient.post(`${TICKETS_URL}/${ticketId}/image`, formData, config)

  return response.data
}
