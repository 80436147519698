import { React } from 'react'

const Button = ({
  color,
  onClick,
  inactive,
  small,
  submit,
  children,
  form,
  textColor = 'text-white',
  border = ''
}) => (
  <button
    className={`${
      inactive
        ? `cursor-default ${
            border ? 'border border-medium-gray text-medium-gray' : 'bg-medium-gray'
          }`
        : `${color} hover:opacity-70`
    } w-fit ${border} ${
      small ? 'rounded py-1.5 px-3 text-xs' : 'rounded-md py-2 px-6 text-sm'
    } ${textColor}`}
    onClick={inactive ? undefined : onClick}
    type={submit ? 'submit' : 'button'}
    form={form}
  >
    {children}
  </button>
)

export default Button
