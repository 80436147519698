export const handleChangeHoldingBankData = (event, setHoldingBankDataForm) => {
  const { name, value } = event.target
  setHoldingBankDataForm((prevData) => ({ ...prevData, [name]: value }))
}

export const handleChangeBankData = (event, setBankDataState) => {
  const { name, value, type, checked } = event.target
  const newValue = type === 'checkbox' ? checked : value
  setBankDataState((prevData) => ({ ...prevData, [name]: newValue }))
}

export const handleChangeContacts = (
  event,
  contactType,
  index,
  setFinancesContact,
  setLogisticsContacts
) => {
  const { name, value } = event.target

  if (contactType === 'finances') {
    setFinancesContact((prevContact) => ({ ...prevContact, [name]: value }))
  } else if (contactType === 'logistics') {
    setLogisticsContacts((prevContacts) => {
      const updatedContacts = [...prevContacts]
      updatedContacts[index] = { ...updatedContacts[index], [name]: value }
      return updatedContacts
    })
  }
}

export const handleDCChange = (event, setDistributionCenterData) => {
  const { name, value } = event.target
  setDistributionCenterData((prevData) => ({ ...prevData, [name]: value }))
}

export const initializeContactState = (contact, setLogisticsContacts, setFinancesContact) => {
  const logisticsArray = contact.filter((c) => c.type === 'logistics')
  setLogisticsContacts(logisticsArray)
  setFinancesContact(
    contact.find((c) => c.type === 'finances') || { phone: '', email: '', type: 'finances' }
  )
}

export const handleHoldingBankDataChange = (event, setHoldingBankDataForm) => {
  handleChangeHoldingBankData(event, setHoldingBankDataForm)
}

export const handleBankDataChange = (event, setBankDataState) => {
  handleChangeBankData(event, setBankDataState)
}

export const handleContactsChange = (
  event,
  contactType,
  index,
  setFinancesContact,
  setLogisticsContacts
) => {
  handleChangeContacts(event, contactType, index, setFinancesContact, setLogisticsContacts)
}

export const handleDistributionCenterChange = (event, setDistributiionCenter) => {
  handleDCChange(event, setDistributiionCenter)
}
