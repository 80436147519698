import React, { useState } from 'react'

import ViewBar from '../../components/Billing/ViewBar'
import { parseJsonOrders } from '../../helpers/OrdersBilling'
import { savePackages } from '../../helpers/requests/billing'
import ModalHandler from '../../components/Billing/ModalHandler'
import CourierBillsSection from '../../components/Billing/CourierBills/CourierBillsSection'
import HoldingBillsSection from '../../components/Billing/HoldingBills/HoldingsBillsSection'
import ParcelsBillSection from '../../components/Billing/Parcels/ParcelsBillSection'
import { exportIcon } from '../../constants/icons'

const Billing = () => {
  const [currentView, setCurrentView] = useState('couriers')
  const [shouldFetch, setShouldFetch] = useState(true)
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [editedPackages, setEditedPackages] = useState([])
  const [updatePackagesFunction, setUpdatePackagesFunction] = useState(null)

  const setModalData = (data, type) => {
    setShowModal(true)
    setModalType(type)
    setModalMessage(data)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setModalType('')
  }

  const saveData = async (columns, fileData, courier) => {
    setLoading(true)
    if (!fileData) {
      setLoading(false)
      return
    }
    try {
      const parsedData = parseJsonOrders(courier, fileData, columns)
      const savedPackagesResponse = await savePackages({ sales: parsedData, courier })
      setModalData(savedPackagesResponse, 'response')
    } catch (e) {
      setModalData({ status: 500, data: { body: e.message } }, 'response')
    }
    setShouldFetch(true)
    setLoading(false)
  }

  const saveEditedPackagesElement = () => (
    <div className="sticky top-[50%] z-10 grid h-20 w-fit grid-flow-col divide-x divide-ultra-dark-grey rounded-lg bg-white shadow-md shadow-dark-grey">
      <div className="flex flex-col items-center justify-center p-4">
        <div className="flex h-8 w-8 items-center justify-center rounded-lg bg-ultra-dark-grey text-white">
          {editedPackages.length}
        </div>
        <div className="text-sm font-light">Pedidos</div>
      </div>
      <div className="flex items-center justify-center gap-1 p-4">
        <img src={exportIcon} className="h-auto w-6" alt="Guardar" />
        <button
          type="button"
          onClick={() => setModalData('¿Modificar pedidos?', 'confirm')}
          className="text-sm"
        >
          Guardar
          <br />
          Modificacion
        </button>
      </div>
      <button
        type="button"
        onClick={() => setEditedPackages([])}
        className="flex items-center justify-center p-4"
      >
        X
      </button>
    </div>
  )

  return (
    <div className=" flex h-screen flex-col bg-light-grey">
      <div className="relative m-10">
        <div className="mb-8 text-left text-xl font-semibold">Facturas</div>
        <ModalHandler
          showModal={showModal}
          modalType={modalType}
          modalMessage={modalMessage}
          loading={loading}
          saveData={saveData}
          handleCloseModal={handleCloseModal}
          handleSubmit={() => {
            updatePackagesFunction({ editedPackages }).then(() => setShouldFetch(true))
            handleCloseModal()
            setEditedPackages([])
          }}
        />
        <div className="mr-10 flex items-center justify-between">
          <ViewBar setCurrentView={setCurrentView} currentView={currentView} />
          <button
            type="button"
            className="flex h-14 items-center justify-center gap-2 rounded-lg bg-pinflag-dark px-4 text-base font-normal text-white hover:opacity-75"
            onClick={() => setModalData(null, 'upload')}
          >
            <img
              className="h-auto w-6"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB1UlEQVR4nO3cS04CQRhF4ZrgWhQ32UMSBTGuRl2GuhCBmSbXVKyBEjQ8ir7Vf59vBdX3CI2TSgkAAAAAAAAAAGCLpGtJd5JeJW10fh0Rvoe/kPQg6bOH0beNO0IZ/1leXRqr8pffgi6N9Dvf8bXzl3FFkLRUe2ZpLCS9qU1dGgNJa7VrlqJzLzwAm/I/0ULSlABe+cfKvaQJnwCvp2oRzA8yZEsCeH1IuuIT4DUngNcLAbxWBDAjgBkBzAhgRgAzApgRwIwAZuECpGDnJcCZEcCMAGYEMCOAGQHMCGBGADMCmBHAjABmQwgwO/mQxz9bd+6Hq3FIRYygHsbPahxU0SKop/GzGodVpAjqcfysxoEVJYJ6Hj+rcWhFiCDD+FmNg2voEWQaP6txeA05gozjRwhwUgT3+FECHBWhhfEjBTgoQivjRwuwV4SWxo8Y4N8IrY0fNcDOCC2OHznArwitjh89gMrwzY4/hgDNI4AZAcwIECDAyv0QA/ZeI0C+jAjGqwryTVA4zk2NANPGrq0c0nU1lycHKBHyNVw4zKLK+CXApFzDhf08Vr037keEZfloYbeP8s6sO/6Od8I8v+Ebv1O0L+uyxW2VK8oAAAAAAAAAAECK5gv0tmN4KKeYfAAAAABJRU5ErkJggg=="
              alt="Upload bill"
            />
            Cargar factura
          </button>
        </div>
        {currentView === 'clientes' && (
          <HoldingBillsSection setShouldFetch={setShouldFetch} shouldFetch={shouldFetch} />
        )}
        {currentView === 'couriers' && (
          <CourierBillsSection setShouldFetch={setShouldFetch} shouldFetch={shouldFetch} />
        )}
        {currentView === 'pedidos' && (
          <ParcelsBillSection
            editedPackages={editedPackages}
            setEditedPackages={setEditedPackages}
            setUpdatePackagesFunction={setUpdatePackagesFunction}
            setShouldFetch={setShouldFetch}
            shouldFetch={shouldFetch}
          />
        )}
        {editedPackages.length > 0 && saveEditedPackagesElement()}
      </div>
    </div>
  )
}

export default Billing
