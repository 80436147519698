import React from 'react'
import { BarChart, Bar, YAxis, XAxis, LabelList, Tooltip } from 'recharts'

import CustomToolTip from '../CustomToolTip'
import CustomLabel from './CustomLabel'

const VerticalBarChart = ({ data, maxValue }) => {
  if (data?.length === 0 || maxValue=== 0) {
    return (
      <div className="my-2 flex">
      <div className="mx-auto">No hay datos</div>
    </div>
    )
  }

  return (
  <div className="h-32 overflow-y-auto overflow-x-hidden">
    <BarChart data={data} layout="vertical" barSize={5} width={290} height={data.length * 30} margin={{top: 5}}>
      <XAxis type="number" domain={[0, maxValue === 0 ? 1 : maxValue]} hide />
      <YAxis
        type="category"
        dataKey="percentage"
        axisLine={false}
        tickLine={false}
        tick={{ fontSize: 12 }}
        orientation="right"
      />
      <Tooltip content={<CustomToolTip />} cursor={false} />
      <Bar dataKey="value" fill="#77A6FF" radius={[5, 5, 5, 5]} background={{ fill: '#EBECED' }} >
        <LabelList dataKey="name" position="insideBottomLeft" fill="#394860" fontSize={13} offset={8} content={<CustomLabel/>}/>
      </Bar>
    </BarChart>
  </div>
)}

export default VerticalBarChart
