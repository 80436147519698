import React, { useState } from 'react'

import { approveCourierCompany } from '../../../helpers/requests/couriers'
import Loader from '../../shared/Loader'

const CourierPointSelection = ({
  setCurrentStep,
  holdingId,
  setSuccessMesssage,
  setErrorsList,
  setShowResponseModal
}) => {
  const [courierOptions, setCourierOptions] = useState({
    41: { name: 'Blue Express', isChecked: false},
    35: { name: 'Chilexpress', isChecked: false}
  })
  const [isLoading, setIsLoading] = useState(false)

  const handleCourierOption = (courierId) => {
    const { isChecked } = courierOptions[courierId]
    setCourierOptions((prevState) => ({
      ...prevState,
      [courierId]: { ...prevState[courierId], isChecked: !isChecked }
    }))
  }

  const assignCourierPoints = async () => {
    const responses = await Promise.allSettled(Object.keys(courierOptions).map(courierId => {
      if(!courierOptions[courierId].isChecked) return false
      return approveCourierCompany(courierId, { holdingId })
    }))
    const errorResponses = Object.keys(courierOptions)
      .map((courierId, index) => ({ ...responses[index], courierId }))
      .filter(response => response.status === 'rejected')
    if (!errorResponses.length) return setErrorsList([])
    return setErrorsList(errorResponses
      .map(response => {
        const { reason, courierId } = response
        return `Error: ${reason.message} - ${JSON.stringify({ courierId, holdingId })}}`
      })
    )
  }

  const handleCourierPointSelection = async () => {
    setIsLoading(true)
    await assignCourierPoints()
    setSuccessMesssage('La red de puntos fue asignada')
    setShowResponseModal(true)
    setIsLoading(false)
    setCurrentStep(3)
  }

  return (
    <div className='flex w-full flex-col justify-between p-3'>
      <div className='p-5'>
        <h1 className='my-2'>Red de puntos</h1>
        {Object.keys(courierOptions).map((courierId) => (
          <div key={courierId} className='flex items-center'>
            <input
              type='checkbox'
              className='m-5 h-5 w-5'
              checked={courierOptions[courierId].isChecked}
              onChange={() => handleCourierOption(courierId)}
            />
            <h1>{courierOptions[courierId].name}</h1>
          </div>
        ))}
      </div>

      {isLoading && <Loader />}

      <div className='my-8 flex justify-end'>
        <button
          className='mx-5 h-10 w-1/5 rounded-lg bg-medium-gray px-5 text-white hover:bg-pinflag-200'
          type='button'
          onClick={() => setCurrentStep(1)}
        >
          Volver
        </button>
        <button
          className='mx-5 h-10 w-1/5 rounded-lg bg-pinflag px-5 text-white hover:bg-pinflag-200'
          type='button'
          onClick={handleCourierPointSelection}
        >
          Continuar
        </button>
      </div>
    </div>
  )
}

export default CourierPointSelection
