import React from 'react'

import VariationTag from './VariationTag'

const Total = ({ total, variation, positive, money }) => (
  <div className="ml-4 flex h-2/3">
    <div className="my-auto w-full">
      <div className="mb-4 text-left text-3xl font-semibold">{money ? `$${total} CLP` : `${total}`}</div>
      <VariationTag variation={variation} positive={positive}/>
    </div>
  </div>
)

export default Total
