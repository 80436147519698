import React, { useState } from 'react'

import { getFormattedDateWithoutTimezone, substractDate } from '../../../utils/dates'
import Filters from '../Filters/Filters'

// import TicketsStats from './Groups/Operational/TicketsStats'
import IncidentsStats from './Groups/Operational/IncidentsStats'

const OperationalView = () => {
  // const [ticketsCurrentFilters, setTicketsCurrentFilters] = useState(
  //   `?startDate=${getFormattedDateWithoutTimezone(substractDate(new Date(), 30))}`
  // )
  const [incidentsCurrentFilters, setIncidentsCurrentFilters] = useState(
    `?startDate=${getFormattedDateWithoutTimezone(substractDate(new Date(), 30))}`
  )

  return (
    <div>
      {/* <div className="mt-4 text-left text-xl font-semibold">Solicitudes</div>
      <Filters setFilters={setTicketsCurrentFilters} filterCourier filterState />
      <TicketsStats currentFilters={ticketsCurrentFilters}/> */}
      <div className="mt-4 text-left text-xl font-semibold">Incidentes</div>
      <Filters
        setFilters={setIncidentsCurrentFilters}
        filterCourier
        filterState
        filterService
        filterDelivery
      />
      <IncidentsStats currentFilters={incidentsCurrentFilters}/>
    </div>
  )
}

export default OperationalView
