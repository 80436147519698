import { React } from 'react'

const ProgressBar = ({ steps, currentStep }) => (
  <div className='w-full rounded-lg bg-gray-200'>
    <div
      className='h-2 rounded-lg bg-pinflag'
      style={{ width: `${(currentStep + 1) * (100/steps)}%` }}
    />
  </div>
)

export default ProgressBar
