import React from 'react'

import { COURIERS } from '../../constants/couriers'
import { TYPE_OPTIONS } from '../../constants/tickets'

const TicketsFilters = ({ selectedType, setSelectedType, selectedCourier, setSelectedCourier }) => (
    <div className="flex gap-6 text-sm">
        <select
          className="rounded border p-2"
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
        >
          <option value="">Motivo</option>
          {Object.entries(TYPE_OPTIONS).map(([value, label]) => (
            <option key={value} value={value}>{label}</option>
          ))}
        </select>
        <select
          className="rounded border p-2"
          value={selectedCourier}
          onChange={(e) => setSelectedCourier(e.target.value)}
        >
          <option value="">Courier</option>
          {COURIERS.map(courier => (
            <option key={courier} value={courier}>
              {courier.charAt(0).toUpperCase() + courier.slice(1)}
            </option>
          ))}
          <option value="Otros Couriers">Otros Couriers</option>
          <option value="Sin Courier">Sin Courier</option>
        </select>
    </div>
  )

export default TicketsFilters
