import React from 'react'

const HoldingSummary = ({holding}) => (
    <div className="flex h-full w-1/2 rounded-3xl border bg-light-grey p-6">
      <div className='w-1/3'>Logo {holding.logo || ''}</div>
      <div className="relative flex flex-col justify-around">
        <span className='text-sm'><b>Facturar a:</b> {holding.name}</span>
        <span className='text-sm'><b>Rut:</b> {holding.ssn || 'sin asignar'}</span>
        <span className='text-sm'><b>Giro:</b> {holding.bankDraft || 'sin asignar'}</span>
        <span className='text-sm'><b>Dirección:</b> {holding.address || 'sin asignar'}</span>
      </div>
    </div>
  )

export default HoldingSummary
