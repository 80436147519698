export const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

export const checkEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/
  return email.match(emailFormat)
}

export const truncateString = (string, maxLength) => {
  if (string.length > maxLength) return `${string.slice(0, maxLength)}...`
  return string
}

export const isSubstring = (string, subString) => (
  string
    ?.trim()
    .toLowerCase()
    .includes(subString.trim().toLowerCase())
)
