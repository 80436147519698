import { useEffect, useState } from 'react'

const useFetch = (promise, setData, params, parser) => {
  const [isLoading, setisLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setisLoading(true)
      try {
        let data = await promise(params)
        if (parser) data = parser(data)
        setData(data)
      } catch {
        setError(true)
      } finally {
        setisLoading(false)
      }
    }
    fetchData()
    return () => {
      setisLoading(true)
      setError(false)
    }
  }, [setData, parser, promise, params])

  return { isLoading, setisLoading, error, setError }
}

export default useFetch
