import { React, useState } from 'react'

import Loader from '../components/shared/Loader'

import LoginForm from '../components/Login/LoginForm'

const Login = () => {
  const [failedLogin, setFailedLogin] = useState(false)
  const [loading, setLoading] = useState(false)

  return (
    <div className="flex h-screen w-full flex-col justify-center text-center font-lexend">
      <h1 className="text-2xl font-bold text-pinflag-dark">Pinflag Financiero</h1>
      <div className="mx-4 my-10">
        {loading ? (
          <Loader />
      ) : (
          <LoginForm
            failedLogin={failedLogin}
            setFailedLogin={setFailedLogin}
            setLoading={setLoading}
          />
      )}
      </div>
    </div>
  )
}

export default Login
