import React from 'react'
import { useHistory } from 'react-router-dom'

import { backArrow } from '../../../constants/icons'
import HoldingList from '../InputFields/HoldingList'


const InputNameAndHoldings = ({
  formValues,
  holdings,
  selectedHoldings,
  handleChange,
  handleHoldingSelect,
  handleSelectAllHoldings,
  title,
  subtitle,
  children,
  usedFor = 'general'
}) => {
  const { push } = useHistory()
  const handleGoBack = () => {
    push('/notificaciones')
  }
  return (
    <div className="relative h-fit ">
      <button
        type="button"
        className="mx-10 mt-4 mb-6 flex items-center justify-center rounded-lg px-2 py-1 hover:bg-medium-gray"
        onClick={() => handleGoBack()}
      >
        <img src={backArrow} className="mr-5 h-auto w-5" alt="volver" />
        <div className="text-left text-xl font-semibold">{title}</div>
      </button>
      <div className="mx-20 text-left text-xl font-semibold">{subtitle}</div>
      <div className="mx-20 my-10 mb-20 h-fit rounded-[2rem]">
        <div className="flex w-4/5 flex-col gap-8">
          {usedFor === 'general' && (
            <>
              <div className="flex flex-col">
                Nombre
                <input
                  id="folio"
                  type="text"
                  name="body"
                  className="h-10 w-1/2 rounded-full border-2 border-dark-grey bg-white px-2"
                  value={formValues?.name || ''}
                  onChange={(e) => handleChange('', 'name', e)}
                  required
                />
              </div>
              <HoldingList
                holdings={holdings}
                holdingSelection={selectedHoldings}
                handleHoldingSelect={handleHoldingSelect}
                handleSelectAll={handleSelectAllHoldings}
              />
            </>
          )}
          {children}
        </div>
      </div>
    </div>
  )
}

export default InputNameAndHoldings
