import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Table from '../../shared/Table/Table'
import TableRow from '../../shared/Table/TableRow'
import { NOTIFICATION_TYPES } from '../../../constants/notificationCenter'
import CustomToggle from '../InputFields/CustomToggle'

const NotificationsTable = ({
  notifications,
  handleChange,
  handleNotificationToggle,
  statusSelector,
  newNotification,
  setCurrentView,
  setShowModal,
  setCurrentNotification
}) => {
  const [tooltipNotificationId, setTooltipNotificationId] = useState(null)
  const getDaysAfterExpiration = (daysToSend) => (daysToSend > 31 ? daysToSend - 31 : 0)
  const tooltipRef = useRef(null)
  const { push } = useHistory()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setTooltipNotificationId(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <Table
      headers={[
        'Días Factura',
        'Estado',
        'Días Vencimiento',
        'Tipo',
        'Estado Notificación',
        'Acción'
      ]}
    >
      {notifications?.map((notification) => (
        <TableRow
          key={notification.id}
          onClick={() => {}}
          items={[
            <div className="flex h-9 w-48 items-center text-base font-semibold">
              {notification.daysToSend || '-'}
            </div>,
            <div className="flex h-9 w-48 items-center text-base">{notification.name || '-'}</div>,
            <div className="flex h-9 w-48 items-center text-base font-semibold">
              {getDaysAfterExpiration(notification.daysToSend)}
            </div>,
            <div className="flex h-9 w-48 items-center text-base">{notification.type || '-'}</div>,
            <CustomToggle
              notification={notification}
              handleNotificationToggle={handleNotificationToggle}
            />,
            <div className="relative w-12">
              {tooltipNotificationId === notification.id && (
                <div
                  ref={tooltipRef}
                  className="absolute left-[-150%] top-[-150%] z-10 border-2 bg-white p-2 drop-shadow-lg "
                >
                  <button
                    type="button"
                    onClick={() => {
                      push(`notificaciones/edit/${notification.id}/payment`)
                    }}
                    className="block w-full px-2 py-1 text-left hover:bg-normal-grey"
                  >
                    Editar
                  </button>
                  <button
                    type="button"
                    onClick={() => setCurrentView('Logs')}
                    className="block w-full px-2 py-1 text-left hover:bg-normal-grey"
                  >
                    Ver Detalles
                  </button>
                  <button
                    type="button"
                    className="block w-full px-2 py-1 text-left hover:bg-red"
                    onClick={() => {
                      setShowModal(true)
                      setCurrentNotification(notification)
                    }}
                  >
                    Eliminar
                  </button>
                </div>
              )}
              <button
                type="button"
                onClick={() => {
                  setTooltipNotificationId(notification.id)
                  setCurrentNotification(notification)
                }}
                className="z-0 h-9 w-fit rotate-90 text-base font-semibold"
              >
                ...
              </button>
            </div>
          ]}
        />
      ))}
      {newNotification.daysToSend && (
        <TableRow
          onClick={() => {}}
          items={[
            <input
              type="number"
              name="daysToSend"
              min={1}
              className="flex h-9 w-48 items-center rounded-full border-2 text-base font-semibold"
              onChange={(e) => handleChange('daysToSend', e.target.value)}
              required
            />,
            <div className="flex h-9 w-48 items-center text-base">{newNotification.name}</div>,
            <div className="flex h-9 w-48 items-center text-base font-semibold">
              {getDaysAfterExpiration(newNotification.daysToSend || 0)}
            </div>,
            <div className="flex justify-center gap-32">
              {statusSelector('notifType', handleChange, NOTIFICATION_TYPES, 'mail')}
            </div>,
            <CustomToggle
              notification={{ active: true, id: 'new' }}
              handleNotificationToggle={() => {}}
            />,
            <div className="flex h-9 items-center text-base" />
          ]}
        />
      )}
    </Table>
  )
}

export default NotificationsTable
