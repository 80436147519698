import React, { useState } from 'react'

import { assignCourierService } from '../../../helpers/requests/couriers'
import Loader from '../../shared/Loader'

import StateAssignment from './StateAssignment'

const CourierServiceAssignment = ({
  setCurrentStep,
  courierServices,
  holdingId,
  setSuccessMesssage,
  setErrorsList,
  setShowResponseModal
}) => {
  const [selectedCourierServices, setSelectedCourierServices] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const parseErrorResponses = (responses) => {
    const errorResponses = Object.keys(selectedCourierServices)
      .map((stateId, index) => (
        {
          ...responses[index],
          stateId: Number(stateId)
        }
      ))
      .filter(response => response.status === 'rejected')
    if (!errorResponses.length) return setErrorsList([])
    return setErrorsList(errorResponses
      .map(response => {
        const { reason, stateId } = response
        return `Error: ${reason.message} - ${JSON.stringify({ stateId, holdingId })}`
      })
    )
  }

  const assignCourierServiceToHolding = async () => {
    const responses = await Promise.all(
      Object.keys(selectedCourierServices).map(stateId => {
        const courierServicesId = Object.values(selectedCourierServices[stateId])
          .filter(service => service.isSelected)
          .map(service => service.courierServiceId)
        if (!courierServicesId.length) return false
        const data = {
          holdingId,
          stateId: Number(stateId),
          courierServices: courierServicesId
        }
        return assignCourierService(data)
      })
    )
    parseErrorResponses(responses)
  }

  const handleAssigment = async () => {
    setIsLoading(true)
    await assignCourierServiceToHolding()
    setSuccessMesssage('El proceso de asignación se completó')
    setShowResponseModal(true)
    setIsLoading(false)
    setCurrentStep(2)
  }

  return (
    <div className='flex w-full flex-col justify-between p-3'>
      <div className='flex flex-wrap justify-around p-3'>
        {
          Object.keys(courierServices).map(key => (
            <StateAssignment
              key={key}
              courierServices={courierServices[key]}
              stateName={key}
              setter={setSelectedCourierServices}
            />
          ))
        }
      </div>

      {isLoading && <Loader />}

      <div className='flex justify-end py-8'>
        <button
          className='mx-5 h-10 w-1/5 rounded-lg bg-medium-gray px-5 text-white hover:bg-pinflag-200'
          type='button'
          onClick={() => setCurrentStep(0)}
        >
          Volver
        </button>
        <button
          className='mx-5 h-10 w-1/5 rounded-lg bg-pinflag px-5 text-white hover:bg-pinflag-200'
          type='button'
          onClick={handleAssigment}
        >
          Continuar
        </button>
      </div>
    </div>
  )
}

export default CourierServiceAssignment
