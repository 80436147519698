import React from 'react'

const ShippingSummary = ({bill}) => (
    <div className="flex h-full w-[45%] flex-col rounded-3xl border bg-light-grey p-6">
      <span className='mb-4 text-sm font-semibold'>Resumen Transporte</span>
      <div className='flex h-full justify-start'>
        <div className='flex flex-col justify-around'>
          <span className='text-sm font-semibold'>Pagado por cliente:</span>
          <span className='text-sm font-semibold'>Suma de transporte:</span>
        </div>
        <div className='ml-8 flex flex-col justify-around'>
          <span className='text-sm'>{bill?.netValue || 0}</span>
          <span className='text-sm'>{bill?.pinflagCharge || 0}</span>
        </div>
      </div>
    </div>
  )

export default ShippingSummary
