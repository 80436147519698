import { React, useEffect, useState } from 'react'

import useSession from '../../hooks/useSession'
import { loginUser } from '../../helpers/requests/session'

import PasswordInput from './PasswordInput'

const LoginForm = ({ failedLogin, setFailedLogin, setLoading }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { login } = useSession()

  useEffect(() => {
    setEmail('')
    setPassword('')
    return () => {
      setEmail('')
      setPassword('')
    }
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    try {
      const companyData = await loginUser(email, password)
      login(companyData)
    } catch {
      setFailedLogin(true)
      setEmail('')
      setPassword('')
      setLoading(false)
    }
  }

  return (
    <form className="m-auto flex max-w-lg flex-col space-y-6 font-light"  onSubmit={handleSubmit}>
      {failedLogin && <h4>Credenciales incorrectas, intenta nuevamente</h4>}
      <input
        type="text"
        className="rounded-lg border-b px-4 py-3 focus:border-pinflag focus:outline-none"
        placeholder="Correo electrónico"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />
      <PasswordInput password={password} setPassword={setPassword} />
      <input
        type="submit"
        className="cursor-pointer rounded-full bg-pinflag-dark py-4 text-white"
        value="Ingresar"
      />
    </form>
  )
}

export default LoginForm
