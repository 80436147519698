import React from 'react'

import { getTimeFromFormattedDate, shortDate } from '../../../utils/dates'
import {
  REFUND_STATUSES,
  REGULAR_STATUSES,
  TIMELINE_STATUS_COLOR,
  TIMELINE_STATUS_ICONS,
  TIMELINE_TEXT_COLOR
} from '../../../constants/refunds'

const TimeLine = ({ history, currentStatus }) => {
  const refundStatuses =
    currentStatus === 'rejected'
      ? REFUND_STATUSES.filter((status) => history.find((log) => log.status === status.value))
      : REGULAR_STATUSES

  const statusesValues = refundStatuses.map((status) => status.value)

  const currentStatusIndex = statusesValues.indexOf(currentStatus)

  const sortedHistory = history.sort(
    (a, b) => statusesValues.indexOf(a.status) - statusesValues.indexOf(b.status)
  )

  const getTimes = (statusValue) => {
    const statusLog = sortedHistory.find(
      (log) => statusesValues.indexOf(log.status) >= statusesValues.indexOf(statusValue)
    )

    if (statusLog) {
      return [shortDate(statusLog.createdAt), getTimeFromFormattedDate(statusLog.createdAt)]
    }

    return null
  }

  return (
    <div className="flex justify-between">
      {refundStatuses.map((status, index) => (
        <div key={status.value} className="relative grow basis-0 px-1 text-center">
          {index === 0 ? (
            <span> </span>
          ) : (
            <span
              className={`absolute right-[50%] top-3 z-0 block h-[1px] w-full border ${
                statusesValues.indexOf(status.value) <= currentStatusIndex
                  ? 'border-medium-dark-grey'
                  : 'border-normal-grey'
              }`}
            >
              {' '}
            </span>
          )}
          {status.value === currentStatus ? (
            <span
              className={`relative z-10 mx-auto block h-7 w-7 rounded-full border p-1.5 
              ${TIMELINE_STATUS_COLOR[status.value]}`}
            >
              <div className="max-h-[1.375rem] max-w-[1.375rem]">
                <img
                  className="m-auto h-auto w-3"
                  src={TIMELINE_STATUS_ICONS[status.value]}
                  alt={status.value}
                />
              </div>
            </span>
          ) : (
            <span
              className={`relative top-2 z-10 mx-auto block h-2.5 w-2.5 rounded-full ${
                statusesValues.indexOf(status.value) <= currentStatusIndex
                  ? 'bg-medium-dark-grey'
                  : 'bg-normal-grey'
              }`}
            >
              {' '}
            </span>
          )}
          <div
            className={`text-s ${
              status.value === currentStatus ? TIMELINE_TEXT_COLOR[status.value] : 'mt-4'
            }`}
          >
            {status.name}
          </div>
          {getTimes(status.value)?.map((timeLog) => (
            <div key={timeLog} className="text-xs">
              {timeLog}
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default TimeLine
