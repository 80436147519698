import React from 'react'
import { Legend, Pie, PieChart, Tooltip } from 'recharts'

import PieToolTip from './PieToolTip'

const PieLegend = (value) => <span className="text-sm text-default-font">{value}</span>

const DonutPieChart = ({ data, total }) => {
  if (total === 0) {
    return (
      <div className="my-2 flex">
        <div className="mx-auto">No hay datos</div>
      </div>
    )
  }

  return (
    <PieChart width={320} height={150}>
      <Legend
        iconType="circle"
        layout="vertical"
        verticalAlign="top"
        align="right"
        iconSize={6}
        formatter={PieLegend}
      />
      <Tooltip content={<PieToolTip total={total} />} cursor={false} />
      <Pie
        data={data}
        cx="40%"
        cy="40%"
        innerRadius={40}
        outerRadius={60}
        fill="#8884d8"
        dataKey="value"
      />
    </PieChart>
  )
}

export default DonutPieChart
