import React from 'react'

const SearchBar = ({ searchbarInput, placeholder, searchKeyword }) => {
  const handleChange = (event) => {
    searchKeyword(event.target.value)
  }

  return (
    <div className="relative block h-fit">
      <span className="absolute inset-y-0 left-0 flex items-center pl-4">
        <svg className="h-4 w-4 fill-dark-grey focus:fill-pinflag" viewBox="0 0 20 20">
          <path
            fillRule="evenodd"
            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            clipRule="evenodd"
          />
        </svg>
      </span>
      <span className="sr-only">Search</span>
      <input
        type="text"
        className="block
            w-full
            rounded-xl
            border
            border-normal-grey
            bg-white
            py-1.5
            pl-10
            pr-3
            text-sm
            font-light
            text-dark-grey
            shadow-sm
            placeholder:text-dark-grey
            focus:border-dark-grey
            focus:outline-none"
        placeholder={placeholder}
        value={searchbarInput}
        onChange={handleChange}
        autoComplete="off"
      />
    </div>
  )
}
SearchBar.defaultProps = {
  placeholder: 'Buscar...'
}

export default SearchBar
