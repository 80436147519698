import React, { useState } from 'react'

import { COURIER_PLAN_NAMES } from '../../constants/couriers'
import { PINFLAG_PLAN_NAMES, WITHOUT_PLAN } from '../../constants/general'
import Select from '../shared/Input/Select'
import Table from '../shared/Table/Table'
import TableRow from '../shared/Table/TableRow'
import { capitalizeFirstLetter } from '../../utils/strings'

const PricesDetailTable = ({ courierPrices, discount: discounts }) => {
  const [courierPlan, setCourierPlan] = useState(COURIER_PLAN_NAMES[0])
  const [pinflagPlan, setPinflagPlan] = useState(PINFLAG_PLAN_NAMES[0])

  const weightValues = Array.from(Array(20).keys(), (number) => ({
    text: number + 1,
    color: 'text-pinflag'
  }))

  const priceValue = (ratePrice, charges) => {
    const charge = pinflagPlan === WITHOUT_PLAN ? 0 : parseFloat(charges[pinflagPlan])
    const discount = courierPlan === WITHOUT_PLAN ? 0 : parseFloat(discounts[courierPlan])
    return (ratePrice * (1 + charge) * (1 - discount)).toFixed()
  }
  const sortPrices = (prices) => {
    prices.sort((priceA, priceB) => priceA.weight - priceB.weight)
    return prices
  }

  return (
    <div className="mt-8 rounded bg-white p-6">
      <div className="mb-10 flex flex-wrap justify-evenly">
        <div className="w-1/4 min-w-[10rem]">
          <Select options={COURIER_PLAN_NAMES} label="Plan Courier" setter={setCourierPlan} />
        </div>
        <div className="w-1/4 min-w-[10rem]">
          <Select options={PINFLAG_PLAN_NAMES} label="Plan Pinflag" setter={setPinflagPlan} />
        </div>
      </div>
      <div className="overflow-x-auto">
        <Table
          headerColor="bg-white"
          textHeaderColor="text-default-font"
          headers={['Comuna', 'Tipo Comuna', ...weightValues]}
          doubleHeaders={[
            { text: '', colSpan: '2' },
            { text: 'Kilos', colSpan: '20' }
          ]}
        >
          {Object.entries(courierPrices).map(([cityName, cityValues]) => (
            <TableRow
              key={cityName}
              items={[
                capitalizeFirstLetter(cityName),
                cityValues.city.type,
                ...sortPrices(cityValues.weights).map((price) =>
                  priceValue(price.price, cityValues.charges)
                )
              ]}
            />
          ))}
        </Table>
      </div>
    </div>
  )
}

export default PricesDetailTable
