const STATES = [
  {
    name: 'Tarapacá',
    id: 1
  },
  {
    name: 'Antofagasta',
    id: 2
  },
  {
    name: 'Atacama',
    id: 3
  },
  {
    name: 'Coquimbo',
    id: 4
  },
  {
    name: 'Valparaíso',
    id: 5
  },
  {
    name: 'Libertador General Bernardo OHiggins',
    id: 6
  },
  {
    name: 'Maule',
    id: 7
  },
  {
    name: 'Bío-bío',
    id: 8
  },
  {
    name: 'La Araucanía',
    id: 9
  },
  {
    name: 'Los Lagos',
    id: 10
  },
  {
    name: 'Aysén del General Carlos Ibáñez del Campo',
    id: 11
  },
  {
    name: 'Magallanes y de la Antártica Chilena',
    id: 12
  },
  {
    name: 'Metropolitana',
    id: 13
  },
  {
    name: 'Los Ríos',
    id: 14
  },
  {
    name: 'Arica y Parinacota',
    id: 15
  },
  {
    name: 'Ñuble',
    id: 16
  }
]

export default STATES
