import React, { useState } from 'react'

import {
  changeToCollected,
  changeToInTransit,
  changeToOnDelivery,
  changeToOnReturn,
  changeToReadyForCollection,
  changeToReturned
} from '../../../helpers/requests/sales'
import Modal from '../../shared/Modal/Modal'
import {
  PACKAGE_BOUGHT,
  PACKAGE_DELIVERED,
  PACKAGE_IN_TRANSIT,
  PACKAGE_ON_DELIVERY,
  PACKAGE_ON_RETURN,
  PACKAGE_PARTIAL_DELIVERY,
  PACKAGE_PROCESSED,
  PACKAGE_RECEIVED,
  PACKAGE_RETURNED,
  PACKAGE_TOTAL_INCIDENT
} from '../../../constants/packageStatus'
import StatusOption from './StatusOption'
import Button from '../../shared/Button'
import Loader from '../../shared/Loader'
import { toLocalISOString } from '../../../utils/dates'

const StatusManager = ({ status, packageId, clickAndCollect, open, handleClose }) => {
  const [changeType, setChangeType] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [date, setDate] = useState(toLocalISOString(new Date()))

  const promises = {
    [PACKAGE_IN_TRANSIT]: changeToInTransit,
    [PACKAGE_ON_DELIVERY]: changeToOnDelivery,
    [PACKAGE_RECEIVED]: changeToReadyForCollection,
    [PACKAGE_DELIVERED]: changeToCollected,
    [PACKAGE_ON_RETURN]: changeToOnReturn,
    [PACKAGE_RETURNED]: changeToReturned
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      await promises[changeType](packageId, date)
    } catch {
      setError(true)
    }
    window.location.reload()
  }

  return (
    <Modal title="Cambiar de estado" show={open} handleClose={handleClose}>
      {[
        PACKAGE_DELIVERED,
        PACKAGE_PARTIAL_DELIVERY,
        PACKAGE_RETURNED,
        PACKAGE_TOTAL_INCIDENT
      ].includes(status) ? (
        <div className="p-4 font-bold">Este paquete no puede cambiar de estado.</div>
      ) : (
        <div className="m-4 text-sm">
          Seleccione el nuevo estado del pedido:
          <div className="my-2 mr-4 flex flex-col gap-1">
            {[PACKAGE_BOUGHT, PACKAGE_PROCESSED].includes(status) && (
              <StatusOption
                changeType={changeType}
                setChangeType={setChangeType}
                newStatus={PACKAGE_IN_TRANSIT}
              />
            )}
            {[PACKAGE_BOUGHT, PACKAGE_PROCESSED, PACKAGE_IN_TRANSIT].includes(status) && (
              <StatusOption
                changeType={changeType}
                setChangeType={setChangeType}
                newStatus={PACKAGE_ON_DELIVERY}
              />
            )}
            {[PACKAGE_BOUGHT, PACKAGE_PROCESSED, PACKAGE_IN_TRANSIT, PACKAGE_ON_DELIVERY].includes(
              status
            ) &&
              clickAndCollect && (
                <StatusOption
                  changeType={changeType}
                  setChangeType={setChangeType}
                  newStatus={PACKAGE_RECEIVED}
                />
              )}
            <StatusOption
              changeType={changeType}
              setChangeType={setChangeType}
              newStatus={PACKAGE_DELIVERED}
            />
            {status !== PACKAGE_ON_RETURN && (
              <StatusOption
                changeType={changeType}
                setChangeType={setChangeType}
                newStatus={PACKAGE_ON_RETURN}
              />
            )}
            <StatusOption
              changeType={changeType}
              setChangeType={setChangeType}
              newStatus={PACKAGE_RETURNED}
            />
          </div>
          <div className="mt-4 mb-2">Fecha (opcional):</div>
          <input
            type="datetime-local"
            className="rounded border p-2 text-xs"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
          <div className="mt-4 flex justify-end">
            {isLoading ? (
              <Loader />
            ) : (
              <Button
                border="border border-pinflag"
                textColor="text-pinflag"
                onClick={handleSubmit}
                inactive={changeType === ''}
                small
              >
                Guardar
              </Button>
            )}
          </div>
          {error && <div>Error al cambiar el estado del paquete. Intente nuevamente.</div>}
        </div>
      )}
    </Modal>
  )
}

export default StatusManager
