import React, { useState } from 'react'

import { changeTicketStatus, closeTicket } from '../../../helpers/requests/tickets'
import Button from '../../shared/Button'
import { TICKETS_STATUS } from '../../../constants/tickets'
import Select from '../../shared/Input/Select'
import Loader from '../../shared/Loader'

const ChangeStatus = ({ ticketId, status }) => {
  const [statusSelected, setStatusSelected] = useState(status)
  const [closingMessage, setClosingMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorChangeStatus, setErrorChangeStatus] = useState('')

  const statusTickets = TICKETS_STATUS.filter((statusItem) => statusItem.name !== 'Todos').map(
    (statusItem) => statusItem.name
  )

  const changeStatus = async () => {
    let body = {}
    let promise
    if (statusSelected !== 'Resuelto') {
      promise = changeTicketStatus(ticketId, statusSelected, body)
    } else {
      body = { closingMessage }
      promise = closeTicket(ticketId, body)
    }
    setLoading(true)
    try {
      await promise
      window.location.reload()
    } catch (err) {
      if (err.response) setErrorChangeStatus(err.response.data.message)
    }
    setLoading(false)
  }

  return (
    <div className="m-2 flex flex-col p-4">
      <div>
        <Select
          options={statusTickets}
          label="Selecciona el estado pasar la solicitud"
          setter={setStatusSelected}
        />
      </div>
      {statusSelected === 'Resuelto' && (
        <div className="mt-6 w-full self-center text-start">
          <p className="text-sm">Mensaje de cierre:</p>
          <textarea
            className="mt-2 h-3/6 w-full rounded-sm border border-gray-300 p-2 text-sm text-gray-900 focus:border-blue-300 focus:ring-blue-300"
            onChange={(event) => setClosingMessage(event.target.value)}
            maxLength={2500}
          />
        </div>
      )}
      {errorChangeStatus && <div className="self-center text-sm text-red">{errorChangeStatus}</div>}
      <div className="mt-5 self-end">
        {loading ? (
          <Loader />
        ) : (
          <Button color="bg-pinflag" onClick={changeStatus}>
            Cambiar estado
          </Button>
        )}
      </div>
    </div>
  )
}

export default ChangeStatus
