import React from 'react'

import { getTimeFromDate, getFormattedDate } from '../../../utils/dates'

const TimeLine = ({ statusLogs }) => (
  <div className="relative top-[-2.5rem] ml-10 flex h-20 w-max">
    {statusLogs && (
      <div className="relative flex h-full flex-col">
        {statusLogs.map((status, index) => (
          <div key={status.id} className="relative flex min-h-full flex-col px-1.5 text-left">
            {index !== 0 ? (
              <span className="relative top-0 left-2 z-0 h-full w-0 border border-medium-dark-grey">
                {' '}
              </span>
            ) : (
              <span className="relative top-0 left-2 z-0 h-full w-0"> </span>
            )}
            <span
              className={`z-10 block h-4 w-4 rounded-full ${
                index < statusLogs.length - 1
                  ? 'border-4 border-medium-dark-grey bg-medium-dark-grey'
                  : 'border-2 border-green bg-green'
              }`}
            >
              {' '}
            </span>
            <div className="absolute left-10 top-14 whitespace-nowrap">
              <span className="text-sm font-medium text-medium-dark-grey">{status.status}</span>
              <div className="block text-xs">
                <div>{getFormattedDate(status.updatedAt)}</div>
                <div>{getTimeFromDate(status.updatedAt)}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    )}
  </div>
)

export default TimeLine
