export const SCHEDULES_STATUS = {
  created: {
    name: 'Creado',
    color: '#FFBF00'
  },
  rejected: {
    name: 'Rechazado',
    color: '#E97451'
  },
  accepted: {
    name: 'Aceptado',
    color: '#AFE1AF'
  }
}

export const DAYS = {
  Monday: 'Lunes',
  Tuesday: 'Martes',
  Wednesday: 'Miércoles',
  Thursday: 'Jueves',
  Friday: 'Viernes',
  Saturday: 'Sábado',
  Sunday: 'Domingo'
}
