import React, { useMemo, useState } from 'react'
import { NumberParam, useQueryParam } from 'use-query-params'

import TabBar from '../components/shared/TabBar'
import PackageView from '../components/Dashboard/GroupStats/PackageView'
import SLAAndCourierView from '../components/Dashboard/GroupStats/SLAAndCourierView'
import OperationalView from '../components/Dashboard/GroupStats/OperationalView'
import PinmapProView from '../components/Dashboard/GroupStats/PinmapProView'
import DashboardContext from '../components/Dashboard/DashboardContext'
import { formatDate } from '../utils/dates'

const Dashboard = () => {
  const [tab=0, setTab] = useQueryParam('tab', NumberParam)
  const [updateDate, setUpdateDate] = useState(null)

  const dashboardState = useMemo(() => ({ updateDate, setUpdateDate }), [updateDate])

  const tabs = ['Resumen', 'Operacional', 'SLA y Couriers', 'Pinmap Pro']
  const dashboards = {
    0: <PackageView />,
    1: <OperationalView />,
    2: <SLAAndCourierView />,
    3: <PinmapProView />
  }

  return (
    <DashboardContext.Provider value={dashboardState}>
      <div className="m-6 border bg-white p-6">
      <div className="mb-8 text-left text-lg font-semibold">Dashboard</div>
      <div className="flex justify-between">
          <TabBar tabs={tabs} tab={tab} setTab={setTab} />
          {updateDate && <div className="mr-12 text-s">Actualizado: {formatDate(updateDate)} hrs.</div>}
        </div>
      {dashboards[tab]}
    </div>
    </DashboardContext.Provider>
  )
}

export default Dashboard
