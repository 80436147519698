import React from 'react'

const Products = ({ products, shippingPaid, totalProductsPrice }) => (
  <div>
    {products.map((product) => (
      <div className="my-4 flex gap-1 rounded border p-2 text-s">
        {product.photoUrl.length > 0 && product.photoUrl[0].URLIMAGEN ? (
          <img
            src={product.photoUrl[0].URLIMAGEN}
            alt={product.productName}
            className="h-auto max-h-[6rem] w-auto max-w-[6rem]"
          />
        ) : (
          <div className="h-auto max-h-[6rem] w-auto max-w-[6rem] bg-light-grey" />
        )}
        <div>
          <div>{product.productName}</div>
          <div>SKU: {product.sku}</div>
          <div>Cantidad: {product.quantity}</div>
          <div>Precio: {product.price}</div>
        </div>
      </div>
    ))}
    <div className="mt-4 flex justify-between text-xs">
      <div className="text-medium-dark-grey">Cantidad total de productos</div>
      <div className="text-medium-dark-grey">
        {products.reduce((acc, product) => acc + product.quantity, 0)}
      </div>
    </div>
    <hr className="my-3" />
    <div className="mt-2 flex justify-between text-xs">
      <div className="text-medium-dark-grey">Envío pagado por cliente</div>
      <div className="text-medium-dark-grey">{shippingPaid}</div>
    </div>
    <div className="mt-2 flex justify-between text-xs">
      <div className="text-medium-dark-grey">Compra</div>
      <div className="text-medium-dark-grey">{totalProductsPrice}</div>
    </div>
    <hr className="my-3" />
    <div className="mt-2 flex justify-between text-xs">
      <div className="text-medium-dark-grey">Total</div>
      <div className="text-medium-dark-grey">{totalProductsPrice + shippingPaid}</div>
    </div>
  </div>
)

export default Products
