import { useState } from 'react'

const useFormQuoting = (
  initialForm,
  validateForm,
  request,
  setErrorAPI = () => {}
) => {
  const [form, setForm] = useState(initialForm)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState({})

  const saveChange = (name, value) => {
    setForm({
      ...form,
      [name]: value
    })
  }

  const handleChange = (event) => {
    let { value } = event.target
    const { name } = event.target
    if (typeof form[name] === 'number') value = parseInt(value, 10)
    saveChange(name, value)
  }

  const handleBlur = (event) => {
    const error = validateForm(form)
    setErrors((prevError) => ({
      ...prevError,
      [event.target.name]: error[event.target.name]
    }))
  }

  const setErrorsForm = () => {
    setErrors(validateForm(form))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setErrors(validateForm(form))
    if (Object.keys(errors).length === 0) {
      setLoading(true)
      try {
        const data = await request(form)
        setLoading(false)
        setErrorAPI(false)
        setResponse(data)
      } catch (errorResponse) {
        if (errorResponse.response) setResponse(errorResponse.response.data)
          setLoading(false)
          setErrorAPI(true)
      }
    }
  }

  return {
    form,
    errors,
    loading,
    response,
    handleChange,
    handleBlur,
    handleSubmit,
    setErrorsForm,
    setForm
  }
}

export default useFormQuoting
