import React, { useEffect, useState } from 'react'
import { searchIcon } from '../../../constants/icons'
import Table from '../../shared/Table/Table'
import TableRow from '../../shared/Table/TableRow'
import InputForm from '../../shared/Input/InputForm'


const HoldingList = ({ holdings, holdingSelection, handleHoldingSelect, handleSelectAll }) => {
  const [isEveryHoldingSelected, setIsEveryHoldingSelected] = useState(false)
  const [holdingsData, setHoldingsData] = useState([{ name: '' }])
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    setHoldingsData([...holdings])
  }, [holdings])

  const filteredHoldings = holdingsData?.filter((holding) =>
    holding.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const handleSelection = (value) => {
    handleHoldingSelect(value)
  }

  const handleSelectAllHoldings = () => {
    setIsEveryHoldingSelected(!isEveryHoldingSelected)
    handleSelectAll(!isEveryHoldingSelected, filteredHoldings)
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  return (
    <div
      className="relative overflow-x-hidden overflow-y-scroll"
      style={{
        msOverflowStyle: 'none',
        scrollbarWidth: 'none'
      }}
    >
      <div className="mb-8 flex w-full items-center justify-center gap-2">
        <label className="relative w-full">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <img className="h-4 w-4" alt="icon-input" src={searchIcon} />
          </span>
          <input
            id="holding_search_bar"
            className="h-10 w-1/2 rounded-full border-2 border-dark-grey bg-white px-10"
            placeholder="Buscar Holding"
            type="text"
            name="search"
            value={searchTerm || ''}
            onChange={handleSearchChange}
          />
        </label>
      </div>
      <Table headers={['Holdings', 'Estado']} maxHeight="h-[20rem] mx-0">
        {filteredHoldings.length > 0 ? (
          filteredHoldings.map((company) => (
            <TableRow
              key={company.id}
              onClick={() => {}}
              items={[
                <div className="flex items-center text-xs">{company.name || '-'}</div>,
                <InputForm
                  id={`selected_${company.id}`}
                  styles="w-4"
                  name="active"
                  type="checkbox"
                  checked={holdingSelection.includes(company.id)}
                  onChange={() => handleSelection(company)}
                />
              ]}
            />
          ))
        ) : (
          <TableRow items={[<div className="flex items-center text-xs">No holdings found</div>]} />
        )}
      </Table>
      <div className="my-2 flex justify-end gap-2">
        Seleccionar todos
        <input
          id="select_all_holdings_checkbox"
          onChange={() => handleSelectAllHoldings()}
          type="checkbox"
        />
      </div>
    </div>
  )
}

export default HoldingList
