import React from 'react'

const StateFilterButton = ({ state, active, onClick }) => (
  <div
    className={`m-2 flex flex-col rounded-xl bg-white p-2 font-semibold ${
      active ? 'text-pinflag drop-shadow' : 'text-dark-grey'
    }`}
    onClick={onClick}
    role="button"
    tabIndex="0"
  >
    <img
      className="m-auto w-16 p-4"
      src={active ? state.activeIcon : state.inactiveIcon}
      alt={state.name}
    />
    <div>{state.name}</div>
  </div>
)

export default StateFilterButton
