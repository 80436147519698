import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router'

import { SERVICES_TYPES } from '../../constants/couriers'
import { DEFAULT_COURIER } from '../../constants/general'
import { getActiveCouriers } from '../../helpers/couriers'
import { getCourierPricesDetail, getCouriersList } from '../../helpers/requests/couriers'
import Loader from '../../components/shared/Loader'
import useFetch from '../../hooks/useFetch'
import Button from '../../components/shared/Button'
import Select from '../../components/shared/Input/Select'
import REGIONS from '../../constants/zones'
import PricesDetailTable from '../../components/CourierPrices/PricesDetailTable'
import BreadCrumbHeader from '../../components/shared/BreadCrumbHeader'
import ROUTES from '../../constants/routes'
import { defaultMessageError } from '../../constants/messages'

const PricesDetail = () => {
  const [courierService, setCourierService] = useState(SERVICES_TYPES[0])
  const [courier, setCourier] = useState(DEFAULT_COURIER)
  const [stateId, setStateId] = useState(1)

  const [activeCouriers, setActiveCouriers] = useState([])

  const [courierPrices, setCourierPrices] = useState({})
  const [loadingPrices, setLoadingPrices] = useState(false)
  const [submitError, setSubmitError] = useState(false)

  const { push } = useHistory()

  const setCouriers = useCallback((couriers) => {
    setActiveCouriers(couriers)
    setCourier(couriers[0].name)
  }, [])

  const { isLoading, error } = useFetch(getCouriersList, setCouriers, '', getActiveCouriers)

  const generateTable = async () => {
    setLoadingPrices(true)
    try {
      const data = await getCourierPricesDetail(courierService, courier, stateId)
      setCourierPrices(data)
      if (Object.keys(data).length === 0) {
        setSubmitError('El courier seleccionado no tiene precios en la región seleccionada.')
      } else {
        setSubmitError('')
      }
    } catch {
      setSubmitError(defaultMessageError)
    } finally {
      setLoadingPrices(false)
    }
  }

  if (isLoading || error) {
    return (
      <div className="flex h-screen bg-background">
        <div className="m-auto">
          {isLoading ? <Loader /> : <div>Ha ocurrido un error. Inténtelo más tarde.</div>}
        </div>
      </div>
    )
  }

  return (
    <div className="min-h-screen bg-background p-8">
      <BreadCrumbHeader
        goBack={() => push(ROUTES.COURIER_PRICES)}
        title="Tarifas Courier"
        subtitle="Tabla detalle"
      />
      <div className="my-4 flex flex-col rounded-md bg-white p-6">
        <div className="flex flex-wrap justify-evenly">
          <div className="w-1/4 min-w-[10rem]">
            <Select options={SERVICES_TYPES} label="Tipo de Servicio" setter={setCourierService} />
          </div>
          <div className="w-1/4 min-w-[10rem]">
            <Select
              options={activeCouriers}
              label="Courier"
              setter={(value) => setCourier(value)}
              withName
            />
          </div>
          <div className="w-1/4 min-w-[10rem]">
            <Select options={REGIONS} label="Región" setter={(value) => setStateId(value)} withId />
          </div>
        </div>
        <div className="mx-auto mt-6">
          {loadingPrices ? (
            <Loader />
          ) : (
            <Button
              border="border border-pinflag"
              textColor="text-pinflag"
              onClick={generateTable}
              small
            >
              Ver tabla
            </Button>
          )}
        </div>
      </div>
      {Object.keys(courierPrices).length > 0 && (
        <PricesDetailTable
          courierPrices={courierPrices.cities}
          discount={courierPrices.discounts}
        />
      )}
      {submitError}
    </div>
  )
}

export default PricesDetail
