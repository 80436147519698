import React, { useState, createElement } from 'react'

import TabBar from '../components/shared/TabBar'
import PickUpSchedules from '../components/Approbations/PickUpSchedules'

const Approbations = () => {
  const [tab, setTab] = useState(0)
  const tabs = ['Retiros']

  const approbations = {
    0: PickUpSchedules
  }

  return (
    <div className="p-8">
      <div className="mb-8 text-left text-xl font-semibold">Permisos</div>
      <TabBar tabs={tabs} tab={tab} setTab={setTab} />
      {createElement(approbations[tab])}
    </div>
  )
}

export default Approbations
