import React from 'react'
import {
  BtnBold,
  BtnClearFormatting,
  BtnItalic,
  BtnRedo,
  BtnStrikeThrough,
  BtnUnderline,
  BtnUndo,
  Editor,
  EditorProvider,
  Toolbar
} from 'react-simple-wysiwyg'

const CustomEditor = ({ value, onChange }) => (
  <EditorProvider>
    <Editor
      containerProps={{
        style: {
          width: '50%',
          minHeight: '18rem',
          'overflowY': 'auto',
          'textOverflow': 'clip',
          'overflowX': 'hidden',
          'borderRadius': '0.75rem'
        }
      }}
      value={value}
      onChange={onChange}
      name='content'
    >
      <Toolbar>
        <BtnUndo />
        <BtnRedo />
        <BtnBold />
        <BtnItalic />
        <BtnStrikeThrough />
        <BtnUnderline />
        <BtnClearFormatting />
      </Toolbar>
    </Editor>
  </EditorProvider>
)

export default CustomEditor
