import React from 'react'

const InputTable = ({ value, onChange, disabled, isEdited, name = '' }) => (
  <input
    type="number"
    className={`w-16 border border-medium-gray ${isEdited && 'bg-light-pinflag'}`}
    value={value}
    onChange={onChange}
    disabled={disabled}
    name={name}
  />
)

export default InputTable
