import React, { useState } from 'react'

import { getFormattedDateWithoutTimezone, substractDate } from '../../../utils/dates'
import Filters from '../Filters/Filters'

import PackageStats from './Groups/PackageStats'

const PackageView = () => {
  const [currentFilters, setCurrentFilters] = useState(
    `?startDate=${getFormattedDateWithoutTimezone(substractDate(new Date(), 30))}`
  )

  return (
    <div>
      <div className="mt-4 text-left font-semibold">Pedidos</div>
      <Filters
        setFilters={setCurrentFilters}
        filterCourier
        filterState
        filterService
        filterDelivery
      />
      <PackageStats currentFilters={currentFilters} />
    </div>
  )
}

export default PackageView
