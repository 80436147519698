import React from 'react'

import NotificationPreview from '../GeneralNotifications/NotificationPreview'
import { BANNER_CONFIG, BANNER_TYPES } from '../../../constants/notificationCenter'
import CustomEditor from '../InputFields/CustomEditor'

const NotificationContent = ({
  notification,
  formValues,
  handleChange,
  handleUpdateNotification,
  setShowModal,
  handleCancelButton
}) => (
  <>
    {notification.type === 'mail' && (
      <div className="flex flex-col">
        Asunto
        <input
          id="about"
          type="text"
          name="about"
          className="h-10 w-1/2 rounded-full border-2 border-dark-grey bg-white px-2"
          value={formValues?.content?.about || ''}
          onChange={(e) => handleChange('about', 'about', e)}
          required
        />
      </div>
    )}
    {notification.type === 'banner' && notification.function === 'payments' && (
      <select
        className="h-12 w-1/3 cursor-pointer rounded-xl border-2 border-dark-grey bg-white px-2.5 text-sm text-gray-900 focus:border-blue-300 focus:ring-blue-300"
        onChange={async (e) => {
          handleChange('duration', '', e)
          Object.entries(BANNER_CONFIG[e.target.value]).forEach(([key, value]) => {
            handleChange(key, '', { target: { value } })
          })
        }}
        value={formValues?.content?.duration || ''}
        id="banner-type-select"
      >
        <option value="" disabled>Tipo de Banner</option>
        {BANNER_TYPES?.filter((type) => type.name !== 'notification').map((option) => (
          <option key={option.name} value={option.name}>
            {option.name}
          </option>
        ))}
      </select>
    )}

    <div className="flex gap-3">
      <CustomEditor
        value={formValues?.content?.body || ''}
        onChange={(e) => {
          handleChange('body', 'content', e)
        }}
      />
      <NotificationPreview currentView={notification.type} notification={formValues} />
    </div>
    <div className="flex justify-end gap-8">
      {!!formValues?.content?.body && (
        <>
          <button
            type="button"
            className="h-12 w-24 rounded-xl border-2 border-pinflag-dark font-light hover:opacity-75"
            onClick={() => handleCancelButton()}
          >
            Cancelar
          </button>
          <button
            type="button"
            className="h-12 w-24 rounded-xl bg-pinflag-dark font-light text-white hover:opacity-75"
            onClick={() => {
              handleUpdateNotification()
              setShowModal(true)
            }}
          >
            Guardar
          </button>
        </>
      )}
    </div>
  </>
)

export default NotificationContent
