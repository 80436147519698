import React from 'react'

import Button from './Button'

const EditGroupButton = ({ editing, setEditing, isEdited, handleCancel, handleSubmit }) => (
  <div className="my-8 flex flex-row-reverse space-x-4 space-x-reverse">
    {editing ? (
      <>
        <Button
          color="bg-red"
          onClick={
            isEdited
              ? () => {
                  handleCancel()
                  setEditing(false)
                }
              : () => setEditing(false)
          }
          text="Cancelar"
        />
        <Button color="bg-pinflag" onClick={handleSubmit} inactive={!isEdited}>Guardar</Button>
      </>
    ) : (
      <Button color="bg-pinflag" onClick={() => setEditing(true)}>Editar</Button>
    )}
  </div>
)

export default EditGroupButton
