import React from 'react'

import Loader from './Loader'

const ShowQuotingResults = ({ response, loading }) => {
  const { courier, services, details } = response

  if (loading) {
    return (
      <div className="grid content-center">
        <div className="mb-36">
          <Loader />
        </div>
      </div>
    )
  }

  return (
    <div className="grid content-center">
      <div className="mb-36">
        <p className="m-2 text-left">
          <span className="font-bold">Courier:</span> {courier}
        </p>
        {details && details.fee && (
          <p className="m-2 text-left">
            <span className="font-bold">Pricing Ecommerce: </span>
            {details.fee.fixed !== null ? (
              <>${details.fee.fixed}</>
            ) : (
              <>{details.fee.percentage * 100}%</>
            )}
          </p>
        )}
        {details && details.base_price !== undefined && (
          <p className="m-2 text-left">
            <span className="font-bold">Precio base:</span> ${details.base_price}
          </p>
        )}
        {details && details.courier_discount !== undefined && (
          <p className="m-2 text-left">
            <span className="font-bold">Descuento courier:</span> {details.courier_discount * 100}%
          </p>
        )}
        {details && details.pinflag_charge !== undefined && (
          <p className="m-2 text-left">
            <span className="font-bold">Cargo Pinflag:</span> {details.pinflag_charge * 100}%
          </p>
        )}
        {details && details.click_and_collect_discount !== undefined && (
          <p className="m-2 text-left">
            <span className="font-bold">Descuento C&C:</span>{' '}
            {details.click_and_collect_discount * 100}%
          </p>
        )}
        {details && details.rule_price !== undefined && (
          <p className="m-2 text-left">
            <span className="font-bold">Regla de precio:</span> ${details.rule_price}
          </p>
        )}
        {details && details.not_rounded_price !== undefined && (
          <p className="m-2 text-left">
            <span className="font-bold">Precio sin redondear:</span> ${details.not_rounded_price}
          </p>
        )}
        {services?.map((service) => (
          <div className="my-2 border p-2">
            <p className="mx-2 my-1 text-left">
              <span className="font-bold">Tipo de servicio:</span> {service?.serviceType}
            </p>
            <p className="mx-2 my-1 text-left">
              <span className="font-bold">Precio final:</span> ${service?.price}
            </p>
            <p className="mx-2 my-1 text-left">
              <span className="font-bold">Tiempo de delivery:</span> {service?.deliveryTime}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ShowQuotingResults
