import * as XLSX from 'xlsx'

export const downloadFileUrl = (fileUrl, fileName = '') => {
  if (!fileUrl) return
  const link = document.createElement('a')
  link.href = fileUrl
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}

export const readFile = (fileData) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onload = () => resolve(reader.result)
  reader.onerror = () => reject(new Error('Failed to read file'))
  reader.readAsBinaryString(fileData)
})

export const parseWorkbook = (data) => XLSX.read(data, {
  type: 'binary',
  cellDates: true,
  cellNF: false,
  cellText: false
})

const saveAsExcel = (buffer, fileName) => {
  const data = new Blob([buffer], { type: 'application/octet-stream' })
  const url = window.URL.createObjectURL(data)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const createExcel = (data, sheetName, name) =>{
  const workbook = XLSX.utils.book_new()
  const worksheet = XLSX.utils.json_to_sheet(data)
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName)
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
  saveAsExcel(excelBuffer, `${name}.xlsx`)
}

export const normalizeColumnName = (name) => name.trim().toLowerCase().replace(/\s+/g, '')

export const normalizeJsonData = (jsonData) => jsonData.map(item => {
    const normalizedItem = {}
    Object.keys(item).forEach(key => {
      normalizedItem[normalizeColumnName(key)] = item[key]
    })
    return normalizedItem
  })
