import axios from 'axios'

import { OPS_URL } from '../constants/endpointUrls/api'

const opsClient = axios.create({
    baseURL: OPS_URL
})

opsClient.interceptors.request.use((config) => {
    const newConfig = config
    if (localStorage.getItem('token')) {
        newConfig.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    }
    return newConfig
})

export default opsClient
