import React, { useMemo, useState } from 'react'

export const AuthContext = React.createContext()

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(localStorage.getItem('ID') !== null)

  const authState = useMemo(() => ({ auth, setAuth }), [auth])

  return <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
}

export default AuthProvider
