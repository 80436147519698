import React from 'react'

const CheckColumn = ({ name, checkList, handleChange, checkState }) => (
  <div className="m-4 flex flex-col">
    <span className="text-left  text-pinflag-dark">{name}</span>
    <div className="mt-4">
      {checkList?.map((checkItem, index) => (
        <div className="flex text-sm" key={checkItem}>
          <label htmlFor={checkItem} className="mt-1 flex w-44 items-start">
            <input
              className="my-auto mr-2"
              type="checkbox"
              name={checkItem}
              id={checkItem}
              value={checkItem}
              onChange={() => handleChange(index)}
              checked={checkState[index]}
            />
            {checkItem}
          </label>
        </div>
      ))}
    </div>
  </div>
)

export default CheckColumn
