import React from 'react'
import parse from 'html-react-parser'

const NotificationPreview = ({ currentView, notification }) => {
  const getBannerStyle = (banner) =>
    `${banner?.content?.color === 'bg-normal-pinflag' ? 'bg-pinflag' : banner?.content?.color} ${
      banner?.content?.textColor
    }`
  const renderContent = () => {
    switch (currentView) {
      case 'banner':
        return (
          <div
            className={`relative flex w-full items-center rounded-lg border-b-2 
              border-light-pinflag ${getBannerStyle(notification)} 
              py-4 text-center font-bold shadow-md`}
          >
            <div className="ml-8 mr-12">{parse(notification?.content?.body || '')}</div>
            {notification?.content?.duration !== 'permanente' && (
              <div className="absolute top-[50%] right-0 mr-8 translate-y-[-50%] hover:text-white">
                X
              </div>
            )}
          </div>
        )
      case 'popup':
        return (
          <div className="w-11/12 rounded-xl bg-white p-4 drop-shadow-lg">
            <div className="mb-2 flex items-center justify-end">
              <div className="text-base">x</div>
            </div>
            <div className="text-sm">{parse(notification?.content?.body || '')}</div>
          </div>
        )
      case 'mail':
        return (
          <div className="w-11/12 rounded-xl bg-white p-4 drop-shadow-lg">
            <div className="mb-5 text-lg font-semibold">
              {parse(notification?.content?.about || '')}
            </div>
            <div className="text-sm">{parse(notification?.content?.body || '')}</div>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div className="flex w-1/2 items-center justify-center rounded-xl bg-medium-gray">
      <div className="flex w-full select-none justify-center">{renderContent()}</div>
    </div>
  )
}

export default NotificationPreview
