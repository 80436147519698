import React from 'react'

const Card = ({title, secondText, children}) => (
  <div className="w-fit min-w-[15%] rounded-xl border bg-white p-2">
    <div className="mx-4 mb-4 flex justify-between">
      {title && <div className="mb-2 text-left">{title}</div>}
      {secondText && <div className="mb-2 ml-2 text-right text-sm font-light text-dark-grey">{secondText}</div>}
    </div>
    {children}
  </div>
)

export default Card
