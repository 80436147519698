import React from 'react'

const Modal = ({ show, title, handleClose, children, backgroundColor }) => {
  const clickOutside = (e) => {
    if (e.currentTarget !== e.target) return
    handleClose()
  }

  if (!show) return <div className="hidden" />

  return (
    <div
      className="fixed top-0 left-0 z-30 h-full w-full cursor-default bg-transparent-black"
      onClick={(event) => clickOutside(event)}
      role="button"
      tabIndex="0"
    >
      <div
        className={`fixed inset-0 m-auto 
        ${backgroundColor || 'bg-white'} 
        h-fit max-h-[90vh] min-h-[9rem] w-fit min-w-[12rem] max-w-[90vw]
       overflow-auto rounded-xl p-4`}
      >
        <div className="flex justify-between">
          <div className="text-lg text-medium-dark-grey">{title}</div>
          <div className="text-xl" onClick={handleClose} role="button" tabIndex="0">
            x
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}

export default Modal
