import React, { useState } from 'react'

import { BILLING_ICONS, uploadIcon } from '../../constants/icons'
import { downloadFileUrl } from '../../utils/files'

const FilePicker = ({
  editing,
  handleFile,
  darkVersion,
  isLoaded,
  imageUrl,
  multiple,
  file,
  files,
  download,
  removeFile,
  fileType = undefined
}) => {
  const [dragActive, setDragActive] = useState(false)

  const handleDrag = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragActive(true)
    } else if (event.type === 'dragleave') {
      setDragActive(false)
    }
  }

  const handleDrop = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setDragActive(false)
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      handleFile(event.dataTransfer.files)
    }
  }

  const downloadFile = () => {
    const blob = new Blob([file], { type: fileType })
    const url = window.URL.createObjectURL(blob)
    downloadFileUrl(url, file?.name)
  }

  if (!editing) {
    return (
      <div className="flex justify-center rounded-2xl bg-white py-4">
        {imageUrl && imageUrl !== '' ? (
          <img src={imageUrl} className="w-44" alt="upload" />
        ) : (
          <>No existe una imagen actualmente</>
        )}
      </div>
    )
  }

  return (
    <>
      <form
        onDragEnter={handleDrag}
        className="relative flex w-72 rounded border border-dashed"
      >
        <label
          className={`mx-auto flex cursor-pointer items-center justify-center rounded py-4 ${
            darkVersion ? 'bg-light-grey' : 'bg-white'
          } w-full `}
          htmlFor="image"
        >
          <input
            className="hidden"
            type="file"
            name="image"
            id="image"
            onChange={(event) => handleFile(event.target.files)}
            accept={fileType}
            multiple={multiple}
          />
          {isLoaded ? (
            <>
              <img src={BILLING_ICONS.paid} className="h-14 w-14 py-3 pr-6" alt="success" />
              <div className="text-ultra-dark-grey">
                <div className="text-sm font-normal">{file?.name}</div>
                <div className="text-xs font-light">
                  Archivo cargado exitosamente
                  {download && (
                    <button
                      type="button"
                      onClick={downloadFile}
                      className="ml-1 block text-pinflag"
                    >
                      • Descargar
                    </button>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="flex flex-col gap-2">
              <div className="mx-auto rounded-full bg-light-pinflag">
                <img
                src={uploadIcon}
                className="h-auto w-10 p-2.5"
                alt="upload"
              />
              </div>
              <div className="mx-auto text-xs font-normal text-ultra-dark-grey">
                {dragActive ? (
                  <div>Suelta el archivo aquí</div>
                ) : (
                  <>
                    Arrastra o
                    <span className="font-semibold"> sube un archivo</span>
                  </>
                )}
              </div>
            </div>
          )}
        </label>
        {dragActive && (
          <div
            className="absolute inset-0 h-full w-full"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
        {isLoaded && removeFile && (
          <button
            type="button"
            onClick={removeFile}
            className="mt-4 mb-auto ml-4 text-xl font-light text-dark-grey"
          >
            x
          </button>
        )}
      </form>
      {files &&
        files.length > 0 &&
        files.map((fileItem, index) => (
          <div key={fileItem.name} className="mt-2 flex w-80 rounded border px-3">
            <img src={BILLING_ICONS.paid} className="h-14 w-14 py-3 pr-4" alt="success" />
            <div className="py-2 text-ultra-dark-grey">
              <div className="w-52 overflow-hidden text-ellipsis text-sm font-normal">
                {fileItem?.name}
              </div>
              <div className="text-xs font-light">
                {(fileItem?.size || 0 / 1000000).toFixed(2)} MB
                {download && (
                  <button type="button" onClick={downloadFile} className="ml-1 text-pinflag">
                    • Descargar
                  </button>
                )}
              </div>
            </div>
            <button
              type="button"
              onClick={() => removeFile(index)}
              className="mt-0 mb-auto ml-4 text-lg font-light text-dark-grey"
            >
              x
            </button>
          </div>
        ))}
    </>
  )
}

export default FilePicker
