import React from 'react'

const FormButton = ({ submit = false, isEditing, colSpan = 'col-span-1', onClick, text, disabled, styles }) => (

    <button className={` rounded-sm bg-pinflag-dark font-bold text-white ${colSpan} ${styles}
            ${isEditing ?
            ' opacity-70'
            :
            ' hover:opacity-70'}`}
        type={submit ? 'submit' : 'button'}
        onClick={onClick}
        disabled={disabled}
    >
        {text}
    </button>

)

export default FormButton
