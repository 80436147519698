import React from 'react'
import { BarChart, Bar, Cell, YAxis } from 'recharts'

import VariationTag from '../../TextStats/VariationTag'
import { STATS_ICONS } from '../../../../constants/icons'
import ToolTip from '../../../shared/ToolTip'

import FunnelBar from './FunnelBar'

const FunnelChart = ({ data, color, texts }) => (
  <div className="flex gap-4">
    {data.map((section, index) => (
      <div>
      <div className="overflow-hidden rounded-lg border" key={section.title}>
        <div className="border-b py-2 text-xs">
          <span className="mx-1 rounded-full border px-2 py-0.5">{index + 1}</span>
          {section.title}
        </div>
        <div className="h-20 w-48 p-2 text-xs">
          <div className="pb-2">{texts[index](section.checkoutCount)}</div>
          {section.variation &&
            <ToolTip
              hoverElement={
              <VariationTag variation={section.variation} positive={section.variation > 0}/>
              }
              backgroundColor="bg-ultra-dark-grey"
              textColor="text-white"
              right
            >
              <div className="w-32">Variación porcentual respecto al período anterior</div>
            </ToolTip>
          }
          </div>
        <BarChart
          data={[section]}
          width={200}
          height={100}
          barGap={0}
          margin={{ top: 0, bottom: 0 }}
          barCategoryGap={0}
        >
          <YAxis dataKey="value" domain={[0, data[0].value]} hide />
          <Bar dataKey="value" shape={<FunnelBar />}>
            <Cell fill={color} />
          </Bar>
        </BarChart>
        <div className="pb-2 text-center text-white" style={{ backgroundColor: color}}>
          <ToolTip
            hoverElement={`${section.percentage || 0} %`}
            backgroundColor="bg-ultra-dark-grey"
            textColor="text-white"
            right
            up
            >
            <div className="w-32">Porcentaje de personas que completaron la etapa</div>
            </ToolTip>
        </div>
    </div>
    {section.averageSeconds !== undefined && section.averageSeconds !== null && (
      <div className="mt-2 flex justify-center gap-2 text-xs">
      <img src={STATS_ICONS.time} alt="tiempo" className="my-auto h-4 w-4" />
      <div>
        <div>Promedio: {section.averageSeconds} s</div>
        <div>Desviación: {section.averageSecondsDeviation} s</div>
      </div>
    </div>
    )}
    </div>
  ))}
  </div>
)

export default FunnelChart
