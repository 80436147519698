import { React, useState } from 'react'

const ToggleSwitch = ({ className, handleCheck}) => {
  const [check, setCheck] = useState(false)
  const handleChange = () => {
    setCheck(!check)
    handleCheck(!check)
  }
  return (
    <div className={className}>
        <label className='relative inline-flex cursor-pointer items-center'>
          <input type='checkbox' value='' className='peer sr-only' onChange={() => handleChange()} checked={check}/>
          <div className='peer h-5 w-10 rounded-full bg-gray-200 after:absolute after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[""] peer-checked:bg-pinflag peer-checked:after:translate-x-full peer-focus:outline-none rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800' />
        </label>

    </div>
  )
}

export default ToggleSwitch
