import React from 'react'

import Button from '../../shared/Button'
import { editIcon2 } from '../../../constants/icons'

const FormBox = ({ icon, title, editing, setEditing, children }) => (
  <div className="w-[28rem] max-w-[70vw] rounded-lg bg-light-grey p-4">
    <div className="flex justify-between">
      <div className="flex gap-1 text-sm">
        <img src={icon} alt={title} className="m-auto h-5 w-5" />
        <div className="m-auto">{title}</div>
      </div>
      <Button color="bg-dark-pinflag" onClick={() => setEditing(!editing)} small>
        {editing ? (
          'Cancelar'
        ) : (
          <div className="flex gap-1">
            <img src={editIcon2} className="h-auto w-3" alt="Cambiar" />
            Cambiar
          </div>
        )}
      </Button>
    </div>
    <div className="mt-4 text-sm">{children}</div>
  </div>
)

export default FormBox
