import React, { useState } from 'react'

import useFetch from '../../hooks/useFetch'
import { getHoldings } from '../../helpers/requests/companies'
import ResponseModal from '../../components/shared/Modal/ResponseModal'
import InputNameAndHoldings from '../../components/NotificationCenter/ContentEdition/NotificationNameAndHoldings'
import NotificationContent from '../../components/NotificationCenter/ContentEdition/NotificationContent'
import { capitalizeFirstLetter } from '../../utils/strings'
import { createNotification } from '../../helpers/requests/notificationCenter'
import { BANNER_CONFIG, POPUP_CONFIG } from '../../constants/notificationCenter'
import { backArrow } from '../../constants/icons'

const CreateNotification = () => {
  const [formValues, setFormValues] = useState({})
  const [holdings, setHoldings] = useState([])
  const [selectedHoldings, setSelectedHoldings] = useState([])
  const [showModal, setShowModal] = useState(false)

  const notificationsConfig = {
    Banner: BANNER_CONFIG.notification,
    PopUp: POPUP_CONFIG.notification
  }

  useFetch(getHoldings, setHoldings, '')

  const handleChange = (type, field, event) => {
    const { value } = event.target

    setFormValues((prevValues) => {
      if (field === 'name') {
        return { ...prevValues, [field]: value }
      }
      return {
        ...prevValues,
        content: {
          ...prevValues.content,
          [type]: value
        }
      }
    })
  }

  const handleSelectAllHoldings = (isActive, filteredHoldings) => {
    if (isActive) {
      const holdingIds = filteredHoldings.map((holding) => holding.id)
      setSelectedHoldings(holdingIds)
    } else setSelectedHoldings([])
  }
  const handleHoldingSelect = (company) => {
    setSelectedHoldings((prevSelected) =>
      prevSelected.includes(company.id)
        ? prevSelected.filter((id) => id !== company.id)
        : [...prevSelected, company.id]
    )
  }

  const handleCreateNotification = async () => {
    await createNotification({ notificationData: { ...formValues, holdingIds: selectedHoldings } })
  }

  const getNotificationConfig = (type) => {
    if (notificationsConfig[type]) return notificationsConfig[type]
    return {}
  }

  const handleGoBack = () => {
    setFormValues((prev) => {
      const { type: _, ...rest } = prev
      return rest
    })
  }

  return (
    <div className="relative h-fit ">
      <ResponseModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        successMessage="La notificación fue guardardada con éxito"
      />
      {!formValues.type && (
        <InputNameAndHoldings
          formValues={formValues}
          holdings={holdings}
          selectedHoldings={selectedHoldings}
          handleChange={handleChange}
          handleHoldingSelect={handleHoldingSelect}
          handleSelectAllHoldings={handleSelectAllHoldings}
          title="Nuevo Anuncio"
          subtitle=""
          usedFor="general"
        >
          <div className="text-sm">Elige el tipo de Anuncio/Notificación</div>
          <div className="flex justify-center gap-32">
            {['PopUp', 'Banner', 'Mail'].map((type) => (
              <button
                key={type}
                type="button"
                className="h-12 w-24 rounded-xl border-2 border-pinflag-dark bg-white font-light shadow-black hover:shadow-lg"
                onClick={() =>
                  setFormValues((prev) => ({
                    ...prev,
                    type: type.toLocaleLowerCase(),
                    content: getNotificationConfig(type)
                  }))
                }
              >
                {type}
              </button>
            ))}
          </div>
        </InputNameAndHoldings>
      )}
      {formValues.type && (
        <div className="mx-10 my-20">
          <button
            type="button"
            className="my-8 flex items-center justify-center rounded-lg px-2 py-1 hover:bg-medium-gray"
            src={backArrow}
            alt="Volver"
            onClick={() => handleGoBack()}
          >
            <img src={backArrow} alt="Volver" className="mr-5 h-auto w-5" />
            <div className="text-lg font-semibold">Nuevo anuncio</div>
          </button>
          <div className="my-8 text-lg font-semibold">{`Notificación - ${capitalizeFirstLetter(
            formValues.type
          )}`}</div>
          <NotificationContent
            notification={formValues}
            formValues={formValues}
            handleChange={handleChange}
            handleUpdateNotification={handleCreateNotification}
            setShowModal={setShowModal}
            handleCancelButton={() => setFormValues((prev) => ({ name: prev.name }))}
          />
        </div>
      )}
    </div>
  )
}

export default CreateNotification
