import React, { useState, useEffect } from 'react'

import { SERVICES_TYPES_LIST } from '../../../constants/couriers'
import Select from '../../shared/Input/Select'
import ToggleSwitch from '../../shared/ToggleSwitch'

const StateAssignment = ({ courierServices, stateName, setter }) => {
  const [checkedServices, setCheckedServices] = useState(
    SERVICES_TYPES_LIST.reduce(
      (accumulatedObj, value) => (
        { ...accumulatedObj, [value.id]: {isSelected: false, courierServiceId: undefined} }
      ), {})
  )
  const [isOpen, setIsOpen] = useState(false)
  const handleServiceType = (selectedServiceType, isChecked) => {
    setCheckedServices(prevState => {
      const newCheckedServices = prevState
      newCheckedServices[selectedServiceType.id].isSelected = isChecked
      return newCheckedServices
    })
  }

  const handleCourierService = (selectedCourier, serviceType) => {
    const courierServiceSelected = courierServices.find(
      courierService => courierService.courier.displayName === selectedCourier
    )
    const courierServiceId = courierServiceSelected.services.find(
      service => service.serviceType === serviceType.id
    ).id
    setCheckedServices(prevState => {
      const newCheckedServices = prevState
      newCheckedServices[serviceType.id].courierServiceId = courierServiceId
      return newCheckedServices
    })
  }

  useEffect(() => {
    setter(prevState => ({
      ...prevState,
      [courierServices[0].stateId]: checkedServices
    }))

  }, [checkedServices, courierServices, setter])

  return (
    <div className={`mr-0 mb-2 flex w-[30%] flex-col ${isOpen ? 'rounded-md border' : 'rounded-t-lg border-t'}`}>
      <button
        type='button'
        className='max-h-[100px] w-full overflow-hidden text-ellipsis whitespace-nowrap border p-4 text-start'
        onClick={() => setIsOpen(!isOpen)}
      >
        {stateName}
      </button>
      <div className={`py-2 px-4 ${isOpen ? 'block': 'hidden'}`}>
        {
          SERVICES_TYPES_LIST.map(serviceType => {
            const options = courierServices
              .filter(courier => courier.services
                .some(service => service.serviceType === serviceType.id)
              )
              .map(courierService => courierService.courier.displayName)
            return (
              <div key={serviceType} className='flex items-center justify-between pt-2'>
                <ToggleSwitch
                  handleCheck={(isChecked) => handleServiceType(serviceType, isChecked)}
                />
                <div className='flex w-3/4 flex-col'>
                  <span className='text-sm font-thin'>{serviceType.name}</span>
                  <Select
                    options={options}
                    setter={(selectedCourier) => handleCourierService(selectedCourier, serviceType)}
                    defaultValue={options[0]}
                  />
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default StateAssignment
