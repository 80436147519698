import React from 'react'

const CustomToolTip = ({ payload }) => {
  if (payload.length > 0) {
    return (
      <div className="rounded-lg border-2 border-light-grey bg-white p-2 text-xs outline-none">
        {payload[0].value}
      </div>
    )
  }
  return null
}

export default CustomToolTip
