import React, { useState } from 'react'

import Modal from '../../shared/Modal/Modal'
import { TICKET_ICONS } from '../../../constants/icons'
import { formatDate } from '../../../utils/dates'
import { sendMessageRefund } from '../../../helpers/requests/refunds'

const RefundChat = ({ refundId, openChat, handleClose, chat }) => {
  const [history, setHistory] = useState(chat)
  const [messageTosend, setMessageToSend] = useState('')

  const sendMessage = async () => {
    const body = {
      message: messageTosend
    }
    const response = await sendMessageRefund(refundId, body)
    setHistory(history.concat(response))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    await sendMessage()
    setMessageToSend('')
  }

  return (
    <Modal show={openChat} handleClose={handleClose} title="Chat">
      <div className="mt-4 flex max-h-[75vh] min-h-[30vh] w-[35rem] max-w-full flex-col justify-between gap-2 rounded-lg bg-light-grey p-2">
        <div className="overflow-y-auto">
          {history.length === 0 && (
            <div className="my-4 text-center text-normal-grey">Aun no hay mensajes</div>
          )}
          {history.map((message) => (
            <div
              key={message.createdAt}
              className={`${
                message.sender === 'company' ? 'ml-auto mr-2 text-end' : 'mr-auto ml-2'
              } mt-2 w-fit max-w-[65%]`}
            >
              <div
                className={`w-fit overflow-hidden break-words rounded-b-2xl py-2 
                px-4 text-sm ${
                  message.sender === 'company'
                    ? 'mr-0 ml-auto rounded-tl-2xl bg-pinflag text-white'
                    : 'ml-0 mr-auto rounded-tr-2xl bg-white'
                }`}
              >
                {message.message}
              </div>
              <div className="text-xs text-dark-grey">{formatDate(message.createdAt)}</div>
            </div>
          ))}
        </div>
        <form className="relative m-2" onSubmit={handleSubmit}>
          <textarea
            className="h-fit w-full resize-none break-words rounded-2xl p-3 pr-6 text-sm shadow-lg"
            placeholder="Escribe un mensaje"
            value={messageTosend}
            onChange={(event) => setMessageToSend(event.target.value)}
            maxLength="500"
          />
          <button type="submit" className="absolute bottom-4 right-2">
            <img src={TICKET_ICONS.send} alt="enviar" className="h-6 py-1" />
          </button>
        </form>
      </div>
    </Modal>
  )
}

export default RefundChat
