import apiClient from '../../axiosConfig/apiClient'

import { LOGIN_SUPER_URL } from '../../constants/endpointUrls/api'

export const loginUser = async (email, password) => {
  const body = {
    email,
    password
  }

  const response = await apiClient.post(LOGIN_SUPER_URL, body)

  return response.data
}
