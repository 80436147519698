import React from 'react'

import { searchIcon } from '../../../constants/icons'

const SearchBar = ({ searchbarInput, placeholder, searchKeyword }) => {
  const handleChange = (event) => {
    searchKeyword(event.target.value)
  }

  return (
    <div className="relative mx-auto mb-4 w-fit">
      <input
        className="rounded-lg py-2 pl-8 pr-4"
        type="text"
        placeholder={placeholder}
        value={searchbarInput}
        onChange={handleChange}
      />
      <img className="absolute top-3 left-2 w-4" alt="icon-input" src={searchIcon} />
    </div>
  )
}

SearchBar.defaultProps = {
  placeholder: 'Buscar...'
}

export default SearchBar
