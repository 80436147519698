import React from 'react'

import { useParams } from 'react-router-dom'
import Loader from '../components/shared/Loader'
import HoldingDetails from '../components/Details/HoldingDetails/HoldingDetails'

const Details = () => {
  const holding = {}

  const { id: holdingId } = useParams()

  const isLoadingHoldingData = false

  const renderViews = () =>
    !isLoadingHoldingData ? <HoldingDetails holding={{ holdingId, ...holding }} /> : <Loader />

  return (
    <div className="relative flex h-screen flex-col bg-light-grey">
      <div className="absolute top-0 left-24 rounded-b-lg bg-pinflag-dark p-2 font-medium text-white">
        {holding?.bankData?.name || 'Name Not Found'}
      </div>
      <div className=" mx-10 my-20">
        {renderViews()}
      </div>
    </div>
  )
}

export default Details
