import React, { useState, useEffect } from 'react'

import Button from '../../shared/Button'
import { TICKET_ICONS, uploadIcon } from '../../../constants/icons'
import { getFormattedDate } from '../../../utils/dates'
import Modal from '../../shared/Modal/Modal'
import FileViewer from '../../shared/FileViewer'
import UploadFile from './UploadFile'
import ChangeStatus from './ChangeStatus'
import { STATUS_COLOR } from '../../../constants/tickets'

import Chat from './Chat'

const Header = ({ ticket }) => {
  const [openChat, setOpenChat] = useState(false)
  const [openFiles, setOpenFiles] = useState(false)
  const [openChangeStatus, setOpenChangeStatus] = useState(false)
  const [openUploader, setOpenUploader] = useState(false)
  const [filesResponse, setFilesResponse] = useState(ticket.TicketFiles)

  const [selectedFilter, setSelectedFilter] = useState([])

  const [filteredFiles, setFilteredFiles] = useState([])

  useEffect(() => {
    setSelectedFilter(filesResponse.filter(item => item.uploadedBy === 'ecommerce').length === 0 ? 'pinflag' : 'ecommerce')
    setFilteredFiles(
      filesResponse.filter(item => item.uploadedBy === 'ecommerce').length === 0 ?
      filesResponse.filter(item => item.uploadedBy === 'pinflag') :
      filesResponse.filter(item => item.uploadedBy === 'ecommerce')
    )
  }, [filesResponse])

  return (
    <div className="flex flex-wrap justify-between gap-8 rounded-lg bg-white py-4 px-6">
      <div>
        <div
          className={`mb-1 py-1 px-4 text-sm font-medium 
          ${STATUS_COLOR[ticket.status]} w-fit rounded`}
        >
          {ticket.status}
        </div>
        <div className="font-medium">Solicitud #{ticket.id}</div>
      </div>
      <div className="flex gap-8 text-sm">
        {ticket.package && Object.keys(ticket.package).length > 0 && (
          <>
            <div>
              <div className="font-medium">O. de venta</div>
              <div className="text-medium-dark-grey">{ticket.package.orderId}</div>
            </div>
            <div>
              <div className="font-medium">Nombre</div>
              <div className="text-medium-dark-grey">{ticket.package.customerName}</div>
            </div>
            <div>
              <div className="font-medium">Email</div>
              <div className="text-medium-dark-grey">{ticket.package.customerEmail}</div>
            </div>
          </>
        )}
        <div>
          <div className="font-medium">Fecha de creación</div>
          <div className="text-medium-dark-grey">{getFormattedDate(ticket.createdAt)}</div>
        </div>
      </div>
      <div className="my-auto flex gap-2">
        <Button color="bg-light-pinflag" small onClick={() => setOpenChangeStatus(true)}>
          <img src={TICKET_ICONS.switch} alt="archivos" className="h-6 py-1" />
        </Button>
        <Button color="bg-light-pinflag" small onClick={() => setOpenUploader(true)}>
          <img src={uploadIcon} alt="archivos" className="h-6 py-1" />
        </Button>
        {filesResponse.length > 0 && (
          <Button color="bg-light-pinflag" small onClick={() => setOpenFiles(true)}>
            <img src={TICKET_ICONS.files} alt="archivos" className="h-6 py-1" />
          </Button>
        )}
        <Button color="bg-pinflag" small onClick={() => setOpenChat(true)}>
          <img src={TICKET_ICONS.chat} alt="chat" className="h-6 py-1" />
        </Button>
      </div>
      <Modal title="Chat" show={openChat} handleClose={() => setOpenChat(false)}>
        <Chat chatHistory={ticket.TicketChats} ticketId={ticket.id} />
      </Modal>
      <Modal title="Subir archivo" show={openUploader} handleClose={() => setOpenUploader(false)}>
        <UploadFile ticketId={ticket.id} setter={setFilesResponse}/>
      </Modal>
      {(filesResponse.length > 0 && filteredFiles.length > 0) && (
        <Modal title="Archivos adjuntos" show={openFiles} handleClose={() => setOpenFiles(false)}>
          <FileViewer
            files={filesResponse}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            filteredFiles={filteredFiles}
            setFilteredFiles={setFilteredFiles}
            urlGetter={(object) => object?.fileUrl}
            filters={['ecommerce', 'pinflag']}
          />
        </Modal>
      )}
      <Modal
        title="Cambiar estado"
        show={openChangeStatus}
        handleClose={() => setOpenChangeStatus(false)}
      >
        <ChangeStatus ticketId={ticket.id} status={ticket.status} />
      </Modal>
    </div>
  )
}

export default Header
