import React from 'react'

import useClickOutside from '../../../hooks/useClickOutside'

const ActionsPopUp = ({ children, closeOnClick, right, up }) => {
  const [isOpen, setIsOpen, popUpRef] = useClickOutside()

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div ref={popUpRef} className='m-auto ml-4'>
        <button onClick={handleClick} type='button' className='relative w-fit rotate-90 text-xl'>
          ...
        </button>
        {isOpen && (
          <div
            className={`absolute flex flex-col items-center justify-center ${up ? 'bottom-0' : 'top-0'} z-30
             w-max rounded-lg bg-white py-2 px-3 text-xs shadow-xl ${right ? 'right-[-6rem]' : 'right-20'}`}
            onClick={closeOnClick ? handleClick : undefined}
            role='button'
            tabIndex='0'
          >
            {children}
          </div>
        )}
    </div>
  )
}

export default ActionsPopUp
