import React, { useState } from 'react'

import { chevron } from '../../../constants/icons'
import useClickOutside from '../../../hooks/useClickOutside'

const SelectMultiple = ({ options, label, setter }) => {
  const [isOpen, setIsOpen, popUpRef] = useClickOutside()
  const [checkState, setCheckState] = useState(new Array(options.length).fill(false))

  const handleChange = (position, option) => {
    if (checkState[position]) {
      setter((previousOptions) =>
        previousOptions.filter((previosOption) => previosOption !== option)
      )
    } else {
      setter((previousOptions) => [...previousOptions, option])
    }
    const updatedCheckedState = checkState.map((item, index) => (index === position ? !item : item))
    setCheckState(updatedCheckedState)
  }

  return (
    <div className="relative z-10" ref={popUpRef}>
      <div className="mb-2 block text-left text-sm font-medium text-gray-900">{label}</div>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex w-full justify-between rounded-lg border border-gray-300 bg-white p-2.5 text-left text-sm text-gray-900 focus:border-blue-300 focus:ring-blue-300"
        role="button"
        tabIndex="0"
      >
        {checkState.every((check) => !check)
          ? 'Seleccionar'
          : `${checkState.filter((check) => check).length} seleccionados`}
        <img src={chevron} alt="seleccionar" className="my-auto h-auto w-2" />
      </div>
      {isOpen && (
        <div className="absolute left-0 flex w-fit min-w-full flex-col rounded-xl border-2 border-light-grey bg-white p-4">
          {options.map((option, index) => (
            <div className="flex text-sm" key={option.name}>
              <label htmlFor={option.name} className="flex items-start whitespace-nowrap">
                <input
                  className="my-auto mr-2"
                  type="checkbox"
                  name={option.name}
                  value={option.name}
                  onChange={() => handleChange(index, option.name)}
                  checked={checkState[index]}
                />
                {option.displayName}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default SelectMultiple
