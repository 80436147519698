import { React } from 'react'
import { useHistory } from 'react-router-dom'

import Button from '../../shared/Button'
import { BILLING_ICONS } from '../../../constants/icons'
import ROUTES from '../../../constants/routes'

const Summary = ({ refundId }) => {
  const { push } = useHistory()

  return (
    <div className="h-full rounded-lg bg-white p-12">
      <img src={BILLING_ICONS.paid} className="mb-4 w-12" alt="success" />
      <div className="text-lg font-medium">¡Tu solicitud de indemnización #{refundId} fue creada con éxito!</div>
      <div className="py-4 text-sm text-medium-dark-grey">
        Estamos aquí para ayudarte. Pronto recibirás noticias en tu correo electrónico. Si deseas
        revisar tu solicitud, puedes hacerlo en la sección de &rsquo;Indemnizaciones&rsquo;
      </div>
      <div className="mt-6 flex justify-end gap-4">
        <Button
          border="border border-pinflag"
          textColor="text-pinflag"
          onClick={() => push(ROUTES.REFUNDS)}
        >
          Volver a inicio
        </Button>
        <Button color="bg-pinflag" onClick={() => push(`${ROUTES.REFUNDS}/${refundId}`)}>
          Ver detalle
        </Button>
      </div>
    </div>
  )
}
export default Summary
