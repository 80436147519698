import React, { useState } from 'react'
import { useHistory } from 'react-router'

import PricesTable from '../../components/CourierPrices/PricesTable'
import Button from '../../components/shared/Button'
import Select from '../../components/shared/Input/Select'
import SelectMultiple from '../../components/shared/Input/SelectMultiple'
import Loader from '../../components/shared/Loader'
import { SERVICES_TYPES } from '../../constants/couriers'
import { getActiveCouriers } from '../../helpers/couriers'
import { getCourierPrices, getCouriersList } from '../../helpers/requests/couriers'
import useFetch from '../../hooks/useFetch'
import ROUTES from '../../constants/routes'

const CourierPrices = () => {
  const [courierService, setCourierService] = useState(SERVICES_TYPES[0])
  const [couriers, setCouriers] = useState([])
  const [weight, setWeight] = useState(1)

  const [activeCouriers, setActiveCouriers] = useState([])
  const weights = Array.from(Array(20).keys(), (number) => number + 1)

  const [headerCouriers, setHeaderCouriers] = useState([])
  const [courierPrices, setCourierPrices] = useState({})
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const { isLoading, error } = useFetch(getCouriersList, setActiveCouriers, '', getActiveCouriers)

  const { push } = useHistory()

  const generateTable = async () => {
    setLoadingSubmit(true)
    try {
      const courierPricesData = await getCourierPrices(courierService, couriers.join(','), weight)
      setHeaderCouriers(activeCouriers.filter((courier) => couriers.includes(courier.name)))
      setCourierPrices(courierPricesData)
    } finally {
      setLoadingSubmit(false)
    }
  }

  if (isLoading || error) {
    return (
      <div className="flex h-screen bg-background">
        <div className="m-auto">
          {isLoading ? <Loader /> : <div>Ha ocurrido un error. Inténtelo más tarde.</div>}
        </div>
      </div>
    )
  }

  return (
    <div className="min-h-screen bg-background p-8">
      <div className="mb-10 text-left text-xl font-semibold">Tarifas Courier</div>
      <div className="mb-8 flex justify-between">
        <div className="text-left">
          <div className="mb-1 font-semibold">Generar Tabla</div>
          <div className="text-dark-grey">
            Completa los filtros para generar la tabla de tarifas del courier. Todos los campos son
            obligatorios.
          </div>
        </div>
        <div className="my-auto">
          <Button color="bg-pinflag" onClick={() => push(ROUTES.COURIER_PRICES_DETAIL)}>
            Ver detalle
          </Button>
        </div>
      </div>
      <div className="flex flex-col rounded-md bg-white p-6">
        <div className="flex flex-wrap justify-evenly">
          <div className="w-1/4 min-w-[10rem]">
            <Select options={SERVICES_TYPES} label="Tipo de Servicio" setter={setCourierService} />
          </div>
          <div className="w-1/4 min-w-[10rem]">
            <SelectMultiple
              options={activeCouriers}
              label="Courier"
              setter={setCouriers}
              withName
            />
          </div>
          <div className="w-1/4 min-w-[10rem]">
            <Select options={weights} label="Kilos" setter={setWeight} numeric />
          </div>
        </div>
        <div className="mx-auto mt-6">
          {loadingSubmit ? (
            <Loader />
          ) : (
            <Button
              border="border border-pinflag"
              textColor="text-pinflag"
              onClick={generateTable}
              inactive={couriers.length === 0}
              small
            >
              Generar tabla
            </Button>
          )}
        </div>
      </div>
      {Object.keys(courierPrices).length > 0 && (
        <PricesTable couriers={headerCouriers} courierPrices={courierPrices.states} />
      )}
    </div>
  )
}

export default CourierPrices
