import React from 'react'

const TabBar = ({ tabs, tab, setTab }) => (
  <div className="flex w-fit border-b-2 bg-transparent">
    {
      tabs.map((tabName, index) => (
        <div key={tabName} className="text-sm hover:opacity-70">
            <span
              className={`mx-4 ${tab === index && 'font-semibold text-pinflag' }`}
              onClick={() => setTab(index)}
              role="button"
              tabIndex="0">
                { tabName }
            </span>
            {tab === index && <div className="relative top-0.5 mt-1 h-[0.15rem] rounded-lg bg-pinflag" />}
        </div>
      ))
    }
  </div>
)

export default TabBar
