import React, { forwardRef } from 'react'

const InputForm = forwardRef(({
  id,
  value,
  onChange,
  disabled,
  type,
  isEdited,
  name = '',
  label = '',
  colSpan = '',
  styles = 'w-5/6',
  checked,
  properties
}, ref) => (
  <label htmlFor={id} className={`flex flex-col ${colSpan}`}>
    {label}
    <input
      id={id}
      type={type}
      className={`my-2 ${styles} border border-medium-gray  ${
        isEdited ? ' opacity-70' : ' hover:opacity-70'
      }`}
      ref={ref}
      value={value}
      onChange={onChange}
      disabled={disabled}
      name={name}
      checked={checked}
      {...properties}
    />
  </label>
))

export default InputForm
