import React, { useState } from 'react'

import { sendTicketMessage } from '../../../helpers/requests/tickets'
import { formatDate } from '../../../utils/dates'
import { TICKET_ICONS } from '../../../constants/icons'

const Chat = ({ chatHistory, ticketId }) => {
  const [history, setHistory] = useState(chatHistory)
  const [messageTosend, setMessageToSend] = useState('')

  const sendMessage = async () =>  {
    const responseData = await sendTicketMessage(ticketId, messageTosend)
    setHistory(history.concat(responseData))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    sendMessage()
    setMessageToSend('')
  }

  return (
    <div className="mt-4 flex max-h-[75vh] min-h-[30vh] w-[35rem] max-w-full flex-col justify-between gap-2 rounded-lg bg-light-grey p-2">
      <div className="overflow-y-auto">
        {history.length === 0 && (
          <div className="my-4 text-center text-medium-gray">Aun no hay mensajes</div>
        )}
        {history.map((message) => (
          <div
            key={message.createdAt}
            className={`${
              message.sender === 'ecommerce' ? 'mr-auto ml-2' : 'ml-auto mr-2 text-end'
            } mt-2 w-fit max-w-[65%]`}
          >
            <div
              className={`w-fit overflow-hidden break-words rounded-b-2xl py-2
              px-4 text-sm ${
                message.sender === 'ecommerce'
                  ? 'ml-0 mr-auto rounded-tr-2xl bg-white'
                  : 'mr-0 ml-auto rounded-tl-2xl bg-pinflag text-white'
              }`}
            >
              {message.message}
            </div>
            <div className="text-xs text-dark-grey">{formatDate(message.createdAt)}</div>
          </div>
        ))}
      </div>
      <form className="relative m-2" onSubmit={handleSubmit}>
        <textarea
          className="h-fit w-full resize-none break-words rounded-2xl p-3 pr-6 text-sm shadow-lg"
          placeholder="Escribe un mensaje"
          value={messageTosend}
          onChange={(event) => setMessageToSend(event.target.value)}
          maxLength="500"
        />
        <button type="submit" className="absolute bottom-4 right-2">
          <img src={TICKET_ICONS.send} alt="enviar" className="h-6 py-1" />
        </button>
      </form>
    </div>
  )
}

export default Chat
