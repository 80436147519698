import React, { useState, useEffect } from 'react'
import { getTicket } from '../../../helpers/requests/tickets'
import ROUTES from '../../../constants/routes'
import { getDaysDifference, formatDate } from '../../../utils/dates'
import { MAX_DAYS_DIFF } from '../../../constants/tickets'
import { errorIcon, bellIcon } from '../../../constants/icons'

const Notifications = ({ tickets }) => {
  const [filteredTickets, setFilteredTickets] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const filterValidTickets = (ticketsList, maxDaysDiff) =>
    ticketsList.filter(
      (ticket) =>
        getDaysDifference(new Date(), ticket.updatedAt) < maxDaysDiff && ticket.status !== 'Resuelto'
    )

  const fetchTicketsWithChats = async (validTickets) =>
    Promise.all(
      validTickets.map(async (ticket) => {
        try {
          const ticketDetails = await getTicket(ticket.id)
          const isLastMessageEcommerce =
            ticketDetails.status === 'Creado' ||
            ticketDetails.TicketChats[ticketDetails.TicketChats.length - 1]?.sender === 'ecommerce'
          return isLastMessageEcommerce ? ticketDetails : null
        } catch (err) {
          return null
        }
      })
    )

  const filterResolvedTickets = (ticketsWithChats) =>
    ticketsWithChats.filter((ticket) => ticket !== null)

  const sortTicketsByDate = (ticketsList) =>
    ticketsList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

  useEffect(() => {
    const fetchTicketDetails = async () => {
      setIsLoading(true)
      setError(null)

      try {
        const validTickets = filterValidTickets(tickets, MAX_DAYS_DIFF)
        const ticketsWithChats = await fetchTicketsWithChats(validTickets)
        const resolvedTickets = filterResolvedTickets(ticketsWithChats)
        const sortedTickets = sortTicketsByDate(resolvedTickets)
        setFilteredTickets(sortedTickets)
      } catch (err) {
        setError('Error cargando tickets')
      } finally {
        setIsLoading(false)
      }
    }

    if (tickets.length > 0) {
      fetchTicketDetails()
    }
  }, [tickets])

  const createStyle =
    'mx-2 inline-flex items-center justify-center rounded border border-[#9747FF] bg-[#F7F0FF] px-2.5 py-0.5 text-xs font-semibold text-[#9747FF]'

  const newMsgStyle =
    'mx-2 inline-flex items-center justify-center rounded border border-red bg-red/10 px-2.5 py-0.5 text-xs font-semibold text-red'

  return (
    <>
      <div className="flex justify-end">
        <button
          type="button"
          onClick={() => setIsModalOpen(true)}
          className="mx-12 flex rounded-full bg-white p-3 text-white shadow-lg hover:bg-white/80 focus:outline-none"
        >
          <img
            src={bellIcon}
            alt="Error Icon"
            className="h-7 w-7"
            style={{ filter: 'brightness(0) saturate(100%) hue-rotate(0deg)' }}
          />
        </button>
      </div>

      {isModalOpen && (
        <div
          className="fixed inset-0 z-40 bg-black/50"
          onClick={() => setIsModalOpen(false)}
          aria-hidden="true"
        />
      )}

      <div
        className={`fixed top-0 right-0 z-50 h-full w-96 bg-white shadow-lg
          transition-transform duration-300 ease-in-out ${
            isModalOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
      >
        <div className="flex items-center justify-between border-b p-4">
          <h2 className="text-xl font-semibold">Notificaciones</h2>
          <button
            type="button"
            onClick={() => setIsModalOpen(false)}
            className="text-gray-600 hover:text-gray-800 focus:outline-none"
          >
            <img
              src={errorIcon}
              alt="Error Icon"
              className="h-7 w-7"
            />
          </button>
        </div>
        <div className="h-full overflow-y-auto p-4">
          {isLoading && <p className="text-gray-500">Cargando tickets...</p>}
          {error && <p>{error}</p>}
          {!isLoading && !error && filteredTickets.length === 0 && (
            <p className="text-gray-500">No hay notificaciones.</p>
          )}
          <ul className="space-y-4">
            {filteredTickets.map((ticket) => (
              <li
                key={ticket.id}
                className="cursor-pointer rounded-lg bg-gray-100 p-4 transition-colors hover:bg-gray-200"
              >
                <a
                  href={`${ROUTES.TICKETS}/${ticket.id}`}
                  onClick={() => setIsModalOpen(false)}
                  className="block h-full w-full text-left"
                >
                  <p className="flex items-center	text-lg font-semibold">
                    Ticket #{ticket.id}
                    <span className={ticket.status === 'Creado' ? createStyle : newMsgStyle}>
                      {ticket.status === 'Creado' ? 'Nuevo ticket' : 'Nuevo mensaje'}
                    </span>
                  </p>
                  <p className="text-sm text-gray-600">
                    {ticket.holdingName} - {ticket.type.split('/')[1].trim()}
                  </p>
                  <p>{formatDate(ticket.updatedAt)}</p>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  )
}

export default Notifications
