import React, { useState } from 'react'

import { SHOW_EYE } from '../../constants/icons'

const PasswordInput = ({password, setPassword}) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <div className="relative">
      <input
        type={showPassword ? 'text' : 'password'}
        className="w-full rounded-lg border-b px-4 py-3 focus:border-pinflag focus:outline-none"
        placeholder="Contraseña"
        value={password}
        onChange={(event) => setPassword(event.target.value)}
      />
      <button
        type="button"
        onClick={() => setShowPassword(!showPassword)}
        className="absolute right-0 top-2 w-10"
      >
        <img src={showPassword ? SHOW_EYE.hide : SHOW_EYE.show} alt="mostrar contraseña" />
      </button>
    </div>
  )
}

export default PasswordInput
