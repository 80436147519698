import { React, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Loader from '../components/shared/Loader'
import Table from '../components/shared/Table/Table'
import useFetch from '../hooks/useFetch'
import { PINFLAG_PLAN_NAMES } from '../constants/general'
import TableRow from '../components/shared/Table/TableRow'
import EditGroupButton from '../components/shared/EditGroupButton'
import ConfirmModal from '../components/shared/Modal/ConfirmModal'
import InputTableFloat from '../components/shared/Input/InputTableFloat'
import { getDefaultCouriers } from '../helpers/couriers'
import { changeHoldingAgreement, getHoldingAgreements } from '../helpers/requests/companies'
import { getCouriersList } from '../helpers/requests/couriers'
import ROUTES from '../constants/routes'

const RawCompanyConditions = () => {
  const { push } = useHistory()
  const [conditions, setConditions] = useState([])
  const [editedConditions, setEditedConditions] = useState({})
  const [editing, setEditing] = useState(false)
  const [isOpenConfirm, setOpenConfirm] = useState(false)
  const [defaultCouriers, setDefaultCouriers] = useState([])
  const { isLoading, error, setError } = useFetch(getHoldingAgreements, setConditions)
  const { isLoading: isLoadingCouriers, error: errorCouriers } = useFetch(
    getCouriersList,
    setDefaultCouriers,
    '',
    getDefaultCouriers
  )

  const handleSubmit = async () => {
    setEditing(false)
    try {
      await Promise.all(
        Object.entries(editedConditions).map(([holdingId, condition]) =>
          changeHoldingAgreement(holdingId, condition)
        )
      )
      setOpenConfirm(false)
      window.location.reload()
    } catch (err) {
      setError(true)
    }
  }

  const isEdited = (condition, parameter) =>
    !!(
      editedConditions[condition.holdingId] &&
      editedConditions[condition.holdingId][parameter] !== undefined
    )

  const getFormValue = (condition, parameter) =>
    isEdited(condition, parameter)
      ? editedConditions[condition.holdingId][parameter]
      : condition[parameter]

  const handleChange = (e, holdingId) => {
    const { name, value } = e.target
    setEditedConditions({
      ...editedConditions,
      [holdingId]: {
        ...editedConditions[holdingId],
        [name]: value
      }
    })
  }

  const handleCheckboxChange = (e, holdingId) => {
    const { name, checked } = e.target
    setEditedConditions({
      ...editedConditions,
      [holdingId]: {
        ...editedConditions[holdingId],
        [name]: checked
      }
    })
  }

  const handleCancel = () => {
    setEditedConditions({})
  }

  if (isLoading || isLoadingCouriers || error || errorCouriers) {
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          {isLoading || isLoadingCouriers ? (
            <Loader />
          ) : (
            <div>Ha ocurrido un error. Inténtelo más tarde.</div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="m-10">
      <div className="mb-8 text-left text-xl font-semibold">Condiciones de Empresas</div>
      <Table
        headers={[
          'Empresa (Razón Social)',
          'Courier por defecto',
          'Tamaño',
          'Tarifa',
          'Descuento C&C',
          'Solo C&C',
          'Usa peso por defecto',
          'Usa multivende',
          'Usa multietiqueta',
          'Detalle'
        ]}
        roundedTop
      >
        {conditions?.map((condition) => (
          <TableRow
            key={condition.holdingId}
            items={[
              <div className="flex items-center">
                {condition.name}
              </div>
              ,
              <select
                name="defaultCourier"
                className={`${isEdited(condition, 'defaultCourier') && 'bg-light-pinflag'}`}
                value={getFormValue(condition, 'defaultCourier')}
                onChange={(e) => handleChange(e, condition.holdingId)}
                disabled={!editing}
              >
                {defaultCouriers?.map((courier) => (
                  <option key={courier.name}>{courier.name}</option>
                ))}
              </select>,
              <select
                name="pinflagPlan"
                className={`${isEdited(condition, 'pinflagPlan') && 'bg-light-pinflag'}`}
                value={getFormValue(condition, 'pinflagPlan')}
                onChange={(e) => handleChange(e, condition.holdingId)}
                disabled={!editing}
              >
                {PINFLAG_PLAN_NAMES.map((plan) => (
                  <option key={plan}>{plan}</option>
                ))}
              </select>,
              <InputTableFloat
                value={getFormValue(condition, 'salesCharge')}
                onChange={(e) => handleChange(e, condition.holdingId)}
                disabled={!editing}
                name="salesCharge"
                isEdited={isEdited(condition, 'salesCharge')}
              />,
              <InputTableFloat
                value={getFormValue(condition, 'clickAndCollectDiscount')}
                onChange={(e) => handleChange(e, condition.holdingId)}
                disabled={!editing}
                name="clickAndCollectDiscount"
                isEdited={isEdited(condition, 'clickAndCollectDiscount')}
              />,
              <div
                className={`m-auto w-fit px-1 ${isEdited(condition, 'onlyClickAndCollect') && 'bg-light-pinflag'
                  }`}
              >
                <input
                  type="checkbox"
                  name="onlyClickAndCollect"
                  checked={getFormValue(condition, 'onlyClickAndCollect')}
                  onChange={(e) => handleCheckboxChange(e, condition.holdingId)}
                  disabled={!editing}
                />
              </div>,
              <div
                className={`m-auto w-fit px-1 ${isEdited(condition, 'useDefaultWeight') && 'bg-light-pinflag'
                  }`}
              >
                <input
                  type="checkbox"
                  name="useDefaultWeight"
                  checked={getFormValue(condition, 'useDefaultWeight')}
                  onChange={(e) => handleCheckboxChange(e, condition.holdingId)}
                  disabled={!editing}
                />
              </div>,
              <div
                className={`m-auto w-fit px-1 ${isEdited(condition, 'useMultivende') && 'bg-light-pinflag'
                  }`}
              >
                <input
                  type="checkbox"
                  name="useMultivende"
                  checked={getFormValue(condition, 'useMultivende')}
                  onChange={(e) => handleCheckboxChange(e, condition.holdingId)}
                  disabled={!editing}
                />
              </div>,
              <div
                className={`m-auto w-fit px-1 ${isEdited(condition, 'useMultilabel') && 'bg-light-pinflag'
                  }`}
              >
                <input
                  type="checkbox"
                  name="useMultilabel"
                  checked={getFormValue(condition, 'useMultilabel')}
                  onChange={(e) => handleCheckboxChange(e, condition.holdingId)}
                  disabled={!editing}
                />
              </div>,
              <div
                className='m-auto w-fit px-1 font-bold'
                role='button'
                tabIndex='0'
                onClick={() => push(`details/${condition.holdingId}`)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' || event.key === ' ') {
                    event.preventDefault()
                    push(`details/${condition.holdingId}`)
                  }
                }}
                onTouchStart={() => push(`${ROUTES.DETAILS}/${condition.holdingId}`)}
              >
                ir
              </div>
            ]}
          />
        ))}
      </Table>
      <EditGroupButton
        editing={editing}
        setEditing={setEditing}
        isEdited={Object.keys(editedConditions).length > 0}
        handleCancel={handleCancel}
        handleSubmit={() => setOpenConfirm(true)}
      />
      <ConfirmModal
        handleSubmit={handleSubmit}
        show={isOpenConfirm}
        handleClose={() => setOpenConfirm(false)}
      />
    </div>
  )
}

export default RawCompanyConditions
