import React from 'react'

import Modal from './Modal'
import { BILLING_ICONS, errorIcon } from '../../../constants/icons'

const ResponseModal = ({ show, handleClose, error, successMessage='Cambios guardados exitosamente' }) => (
  <Modal show={show} handleClose={handleClose}>
    <div className="m-8">
      {error
        ? <>
         <img src={errorIcon} className="mx-auto h-16 w-16" alt="error" />
          <div className="py-4 px-1">Ha ocurrido un error. Inténtelo de nuevo más tarde.</div>
        </>
        : <>
            <img src={BILLING_ICONS.paid} className="mx-auto h-16 w-16" alt="success" />
            <div className="py-4 px-1">{successMessage}</div>
          </>}
    </div>
  </Modal>
)

export default ResponseModal
