import { useContext } from 'react'

import { AuthContext } from '../contexts/AuthContext'

const useSession = () => {
  const { setAuth } = useContext(AuthContext)

  const login = (data) => {
    localStorage.setItem('token', data.token)
    localStorage.setItem('ID', data.id)
    setAuth(true)
  }
  const logout = () => {
    setAuth(false)
    localStorage.removeItem('ID')
    localStorage.removeItem('token')
  }

  return { login, logout }
}

export default useSession
