import React from 'react'

const FormSection = ({ sectionTitle, children }) => (
    <div className='grid grid-cols-2'>
        <div className='col-span-2 mb-2 text-xl font-medium text-ultra-dark-grey'>{sectionTitle}</div>
        {children}
    </div>
)

export default FormSection
