import React, { useState } from 'react'

import { getFormattedDateWithoutTimezone, substractDate } from '../../../utils/dates'
import Filters from '../Filters/Filters'

import PinmapProStats from './Groups/PinmapProStats'

const PinmapProView = () => {
  const [currentFilters, setCurrentFilters] = useState(
    `?startDate=${getFormattedDateWithoutTimezone(substractDate(new Date(), 30))}`
  )

  return (
    <div>
      <Filters setFilters={setCurrentFilters} filterDevice filterCompany/>
      <PinmapProStats currentFilters={currentFilters}/>
    </div>
  )
}

export default PinmapProView
