import apiClient from '../../axiosConfig/apiClient'
import { SIGNUP_COMPANY_URL } from '../../constants/endpointUrls/api'

export const signUp = async (
  data,
  setError,
  setCompanyData,
  setErrorList,
  setErrorMessage
) => {
  try {
    const response = await apiClient.post(SIGNUP_COMPANY_URL, data)
    setCompanyData(response.data.models)
    if (response.data.errors) setErrorList(response.data.errors)
    return response.data
  } catch (error) {
    if (error.response && error.response.data.errors) {
      setErrorList(error.response.data.errors)
    }
    if (error.response && error.response.data.message) {
      setErrorMessage(error.response.data.message)
    }
    else setErrorMessage(error.message)
    setError(true)
    return null
  }
}
