import React, { useEffect, useRef } from 'react'
import { formatToCLP } from '../../../utils/currency'

const EditParcel = ({
  billData,
  setCurrentParcel,
  setEditedPackages,
  editedPackages,
  currentParcel,
  cities,
	setOpenOptions
}) => {
  const columnSelector = (name, setter, options) => (
    <div className="grid w-full grid-cols-3 items-center gap-2 rounded-xl border-2 px-2">
      <div className="font-bold">Comuna:</div>
      <select
        className="cursor-pointer bg-white p-2.5 text-sm text-gray-900 focus:border-blue-300 focus:ring-blue-300"
        onChange={(e) => setter(Number(e.target.value), 'cityId')}
        value={currentParcel.cityId || ''}
        id={`${name}-select`}
      >
        {options?.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  )

  const handleParcelChanges = (value, key) => {
    const editedParcel = {
      ...currentParcel,
      review: 'modified',
      [key]: value
    }
    const parcelExists = editedPackages?.some((parcel) => parcel.id === currentParcel.id)
    if (!parcelExists) {
      setEditedPackages((prev) => [...prev, {...editedParcel}])
    } else {
      setEditedPackages((prev) =>
        prev.map((parcel) => (parcel.id === currentParcel.id ? editedParcel : parcel))
      )
    }
    setCurrentParcel((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  const parcelData = (bill) => (
    <div className="w-fit">
      <div className="flex h-10 w-1/3 items-center rounded-t-lg border-[1px] bg-white px-6 font-bold">
        Datos
      </div>
      <div className="flex flex-col rounded-r-lg  border-[1px] bg-white px-3">
        <div className="grid grid-cols-1 grid-rows-3 divide-y">
          <div className="flex h-16 items-center gap-14 px-3">
            <div className="w-36">
              <span className="pr-2 font-bold">Courier:</span>
              <span>{bill.courier}</span>
            </div>
            <div className="w-36">
              <span className="pr-2 font-bold">Cliente:</span>
              <span>{bill.companyName}</span>
            </div>
          </div>
          <div className="flex h-16 items-center gap-14 px-3">
            <div className="w-36">
              <span className="pr-2 font-bold">OV:</span>
              <span>{bill.orderId}</span>
            </div>
            <div className="w-36">
              <span className="pr-2 font-bold">OT:</span>
              <span>{bill.trackingNumber}</span>
            </div>
          </div>
          <div className="flex h-16 items-center">
            {columnSelector('Comuna', handleParcelChanges, cities, 'Providencia')}
          </div>
        </div>
      </div>
    </div>
  )

  const packageInfo = (bill) => (
    <div className="w-fit">
      <div className="flex h-10 w-1/3 items-center rounded-t-lg border-[1px] bg-white px-6 font-bold">
        Calcular
      </div>
      <div className="flex flex-col rounded-r-lg  border-[1px] bg-white px-3">
        <div className="grid grid-cols-1 grid-rows-3 divide-y">
          <div className="flex h-16 items-center gap-14 px-3">
            {[
              { name: 'Peso Declarado:', value: 'declaredWeight' },
              { name: 'Precio Base:', value: 'basePrice' },
              { name: 'Extras:', value: 'extras' }
            ].map((section) => (
              <div className="w-36" key={section.name}>
                <span className="pr-2 font-bold">{section.name}</span>
                {section.value !== 'extras' ? (
                  <span>
                    {section.value.includes('basePrice')
                      ? formatToCLP(bill[section.value])
                      : bill[section.value]}
                  </span>
                ) : (
                  <input
                    type="number"
                    name={section.value}
                    value={currentParcel[section.value]}
                    onChange={(e) => handleParcelChanges(Number(e.target.value), section.value)}
                    className="h-8 w-20 rounded-full border-2 bg-white pl-2"
                  />
                )}
              </div>
            ))}
          </div>
          <div className="flex h-16 items-center gap-14 px-3">
            {[
              { name: 'Peso Cobrado:', value: 'weightBilled' },
              { name: 'Transporte:', value: 'courierPrice' },
              { name: 'Insurance:', value: 'insurance' }
            ].map((section) => (
              <div className="w-36" key={section.name}>
                <span className="pr-2 font-bold">{section.name}</span>
                {section.value !== 'weightBilled' ? (
                  <span>
                    {section.value.includes('courierPrice')
                      ? formatToCLP(bill[section.value])
                      : bill[section.value]}
                  </span>
                ) : (
                  <input
                    type="number"
                    name={section.value}
                    value={currentParcel[section.value]}
                    onChange={(e) => handleParcelChanges(Number(e.target.value), section.value)}
                    className="h-8 w-20 rounded-full border-2 bg-white pl-2"
                  />
                )}
              </div>
            ))}
          </div>
          <div className="flex h-16 items-center gap-14 px-3">
            <span className="p-2 font-bold">Cobrado:</span>
            <span>{formatToCLP(bill.courierPrice)}</span>
          </div>
        </div>
      </div>
    </div>
  )

	const parcelOptionsRef = useRef(null)

	useEffect(() => {
    const closePopUp = (event) => {
      if (!parcelOptionsRef.current.contains(event.target)) {
        setOpenOptions(false)
      }
    }
    document.addEventListener('mousedown', closePopUp)
    return () => document.removeEventListener('mousedown', closePopUp)
  }, [setOpenOptions])

  return (
    <div ref={parcelOptionsRef} className="absolute left-0 z-30 mx-10 flex w-fit gap-8 rounded-xl bg-light-grey py-4 px-8 shadow-md shadow-dark-grey">
      {parcelData(billData)}
      {packageInfo(billData)}
    </div>
  )
}

export default EditParcel
