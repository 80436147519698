import React, { useState } from 'react'

import { COURIER_PLAN_NAMES } from '../../constants/couriers'
import { PINFLAG_PLAN_NAMES, WITHOUT_PLAN } from '../../constants/general'
import Select from '../shared/Input/Select'
import Table from '../shared/Table/Table'
import TableRow from '../shared/Table/TableRow'

const PricesTable = ({ couriers, courierPrices }) => {
  const [courierPlan, setCourierPlan] = useState(WITHOUT_PLAN)
  const [pinflagPlan, setPinflagPlan] = useState(WITHOUT_PLAN)

  const getHeaders = () => {
    const headers = new Array(couriers.length * 2)
      .fill('')
      .map((_, index) =>
        index % 2 === 0
          ? { text: 'Central', color: 'text-pinflag' }
          : { text: 'Periférica', color: 'text-pinflag' }
      )
    return headers
  }

  const calculateValue = (couriersPrice, cityType) => {
    if (!couriersPrice) return '-'
    const valuePrice = couriersPrice.find((courierPrice) => courierPrice.cityType === cityType)
    if (valuePrice) {
      const charge = pinflagPlan === WITHOUT_PLAN ? 0 : parseFloat(valuePrice.charges[pinflagPlan])
      const discount =
        courierPlan === WITHOUT_PLAN ? 0 : parseFloat(valuePrice.discounts[courierPlan])
      return (valuePrice.price * (1 + charge) * (1 - discount)).toFixed(1)
    }
    return '-'
  }

  const getPrices = (couriersPrice) => {
    const prices = []
    for (const courier of couriers) {
      const central = calculateValue(couriersPrice[courier.name], 'central')

      prices.push(central)
      const peripheral = calculateValue(couriersPrice[courier.name], 'peripheral')
      prices.push(peripheral)
    }
    return prices
  }

  return (
    <div className="mt-8 rounded bg-white p-6">
      <div className="mb-10 flex flex-wrap justify-evenly">
        <div className="w-1/4 min-w-[10rem]">
          <Select
            options={[WITHOUT_PLAN, ...COURIER_PLAN_NAMES]}
            label="Plan Courier"
            setter={setCourierPlan}
          />
        </div>
        <div className="w-1/4 min-w-[10rem]">
          <Select
            options={[WITHOUT_PLAN, ...PINFLAG_PLAN_NAMES]}
            label="Plan Pinflag"
            setter={setPinflagPlan}
          />
        </div>
      </div>
      <div className="overflow-x-auto">
        <Table
          headerColor="bg-white"
          textHeaderColor="text-default-font"
          doubleHeaders={[
            { text: '', colSpan: '1' },
            ...couriers.map((courier) => ({ text: courier.displayName, colSpan: '2' }))
          ]}
          headers={['Región', ...getHeaders()]}
        >
          {Object.entries(courierPrices).map(([state, courierPrice]) => (
            <TableRow key={state} items={[state, ...getPrices(courierPrice)]} />
          ))}
        </Table>
      </div>
    </div>
  )
}

export default PricesTable
