import { ASSETS_URL } from './icons'
import {
  PACKAGE_BOUGHT,
  PACKAGE_DELIVERED,
  PACKAGE_IN_TRANSIT,
  PACKAGE_ON_DELIVERY,
  PACKAGE_PROCESSED,
  PACKAGE_RECEIVED
} from './packageStatus'

export const CLIENT_STATES = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 4,
  6: 4,
  7: 5
}

export const ALL_PACKAGES = {
  name: 'Todos',
  activeIcon: `${ASSETS_URL}/filters/sales/state-1-active.svg`,
  inactiveIcon: `${ASSETS_URL}/filters/sales/state-1-inactive.svg`
}

export const PACKAGE_STATES = [
  {
    number: 1,
    name: 'Comprado',
    activeIcon: `${ASSETS_URL}/filters/sales/state-1-active.svg`,
    inactiveIcon: `${ASSETS_URL}/filters/sales/state-1-inactive.svg`
  },
  {
    number: 2,
    name: 'Procesado',
    activeIcon: `${ASSETS_URL}/filters/sales/state-2-active.svg`,
    inactiveIcon: `${ASSETS_URL}/filters/sales/state-2-inactive.svg`
  },
  {
    number: 3,
    name: 'En camino',
    activeIcon: `${ASSETS_URL}/filters/sales/state-3-active.svg`,
    inactiveIcon: `${ASSETS_URL}/filters/sales/state-3-inactive.svg`
  },
  {
    number: 4,
    name: 'Recepcionado',
    activeIcon: `${ASSETS_URL}/filters/sales/state-4-active.svg`,
    inactiveIcon: `${ASSETS_URL}/filters/sales/state-4-inactive.svg`
  },
  {
    number: 5,
    name: 'Entregado',
    activeIcon: `${ASSETS_URL}/filters/sales/state-5-active.svg`,
    inactiveIcon: `${ASSETS_URL}/filters/sales/state-5-inactive.svg`
  }
]

export const STATES = [
  {
    value: PACKAGE_BOUGHT,
    name: 'Comprado',
    activeIcon: `${ASSETS_URL}/filters/sales/state-1-active.svg`,
    inactiveIcon: `${ASSETS_URL}/filters/sales/state-1-inactive.svg`
  },
  {
    value: PACKAGE_PROCESSED,
    name: 'Procesado',
    activeIcon: `${ASSETS_URL}/filters/sales/state-2-active.svg`,
    inactiveIcon: `${ASSETS_URL}/filters/sales/state-2-inactive.svg`
  },
  {
    value: PACKAGE_IN_TRANSIT,
    name: 'Enviado',
    activeIcon: `${ASSETS_URL}/filters/sales/state-3-active.svg`,
    inactiveIcon: `${ASSETS_URL}/filters/sales/state-3-inactive.svg`
  },
  {
    value: PACKAGE_ON_DELIVERY,
    name: 'En reparto',
    activeIcon: `${ASSETS_URL}/filters/sales/state-3-active.svg`,
    inactiveIcon: `${ASSETS_URL}/filters/sales/state-3-inactive.svg`
  }
]

export const HOME_DELIVERY_STATES = [
  ...STATES,
  {
    value: PACKAGE_DELIVERED,
    name: 'Entregado',
    activeIcon: `${ASSETS_URL}/filters/sales/state-5-active.svg`,
    inactiveIcon: `${ASSETS_URL}/filters/sales/state-5-inactive.svg`
  }
]

export const CLICK_AND_COLLECT_STATES = [
  ...STATES,
  {
    value: PACKAGE_RECEIVED,
    name: 'Recepcionado',
    activeIcon: `${ASSETS_URL}/filters/sales/state-4-active.svg`,
    inactiveIcon: `${ASSETS_URL}/filters/sales/state-4-inactive.svg`
  },
  {
    value: PACKAGE_DELIVERED,
    name: 'Entregado',
    activeIcon: `${ASSETS_URL}/filters/sales/state-5-active.svg`,
    inactiveIcon: `${ASSETS_URL}/filters/sales/state-5-inactive.svg`
  }
]

export const DELIVERY_TYPES = {
  homeDelivery: 'Domicilio',
  clickAndCollect: 'Click & Collect',
  storePickup: 'Punto Propio'
}

export const PACKAGE_TYPES = ['ecommerce', 'marketplace']
