import React, { useCallback, useEffect, useState } from 'react'

import { getCourierLogs } from '../../helpers/requests/sales'
import { identifierCreator, saleStatusColorText, saleStatusIconColor } from '../../helpers/sales'
import { PACKAGE_STATUS_ICONS, SALES_DETAIL_ICONS, chevron, linkIcon } from '../../constants/icons'
import { capitalizeFirstLetter } from '../../utils/strings'
import {
  CLICK_AND_COLLECT_STATES,
  DELIVERY_TYPES,
  HOME_DELIVERY_STATES
} from '../../constants/sales'

import TimeLine from './TimeLine'
import SaleHeader from './SaleHeader'
import LogsTimeline from './LogsTimeline'
import Products from './Products'

const SaleDetail = ({ sale }) => {
  const [isOpenLogs, setIsOpenLogs] = useState(false)
  const [courierLogs, setCourierLogs] = useState([])

  const fetchLogs = useCallback(async (data) => {
    try {
      const logsData = await getCourierLogs(data.packageId)
      setCourierLogs(logsData)
    } catch {
      setCourierLogs([])
    }
  }, [])

  useEffect(() => {
    if (sale?.packageId) {
      fetchLogs(sale)
    }
  }, [sale, fetchLogs])

  const clientName = () => {
    if (!sale) return '-'
    if (!sale.clientLastName) return sale.clientName
    return `${sale.clientName} ${sale.clientLastName}`
  }

  return (
    <div className="my-4 pb-4">
      <SaleHeader
        order={`${identifierCreator(sale.companyName)}-${sale.saleOrder}`}
        packageStatus={sale.packageStatus}
        saleId={sale.packageId}
        evidencePhotos={sale.evidencePhotos}
        deliveryType={sale.deliveryType}
        stateId={sale.stateId}
        city={sale.city}
        warehouseName={sale.warehouseName}
        saleEditableData={{
          address: sale.deliveryAddress,
          cityId: sale.cityId,
          deliveryInstructions: sale.deliveryInstructions,
          customer: {
            name: sale.clientName,
            lastname: sale.clientLastName,
            email: sale.clientEmail,
            phone: sale.clientPhone,
            ssn: sale.ssn
          }
        }}
      />
      <div className="mt-4 grid grid-cols-3 gap-4">
        <div className="col-span-2">
          <div className="rounded-t border bg-white p-6">
            <div className="mb-2 flex gap-2 font-medium">
              <img src={SALES_DETAIL_ICONS.timeline} alt="" className="my-auto w-5" />
              Seguimiento
            </div>
            <TimeLine
              availableStatus={
                sale.clickAndCollect ? CLICK_AND_COLLECT_STATES : HOME_DELIVERY_STATES
              }
              history={sale.packageHistory}
              currentStatus={sale.packageStatus}
              currentColor={saleStatusColorText(sale.packageStatus)}
              currentIcon={{
                icon: PACKAGE_STATUS_ICONS[sale.packageStatus],
                style: saleStatusIconColor(sale.packageStatus)
              }}
            />
          </div>
          {isOpenLogs && (
            <div className="border-x bg-white py-2">
              <LogsTimeline steps={courierLogs} />
            </div>
          )}
          {courierLogs && courierLogs.length > 0 && (
            <button
              className="flex w-full justify-center gap-2 rounded-b border bg-white py-2 text-center text-sm"
              onClick={() => setIsOpenLogs(!isOpenLogs)}
              type="button"
            >
              <img
                src={chevron}
                alt="drop down"
                className={`my-auto w-2.5 ${isOpenLogs ? 'rotate-180' : ''}`}
              />
              {isOpenLogs ? 'Ocultar' : 'Ver'} detalle
            </button>
          )}
          <div className="mt-4 rounded border bg-white p-6">
            <div className="mb-2 flex gap-2 font-medium">
              <img src={SALES_DETAIL_ICONS.shipping} alt="" className="my-auto w-5" />
              Envío
            </div>
            {sale.courier ? (
              <>
                <div className="text-s font-medium">Courier</div>
                <div className="text-s">{sale.courier?.displayName}</div>
                <div className="text-s">
                  O. Transporte:{' '}
                  {sale.courier?.trackingUrl && sale.trackingNumber ? (
                    <button
                      type="button"
                      onClick={() =>
                        window.open(
                          `${sale.courier?.trackingUrl}${
                            ['globaltracking', 'recibelo'].includes(sale.courier.name)
                              ? ''
                              : sale.trackingNumber
                          }`
                        )
                      }
                      className="inline-flex text-pinflag underline"
                    >
                      {sale.trackingNumber}
                      <img src={linkIcon} alt="link a tracking" className="ml-1 w-3 pt-1" />
                    </button>
                  ) : (
                    sale.trackingNumber || '-'
                  )}
                </div>
                <div className="mt-2 text-s font-medium">Entrega</div>
                <div className="text-s">Envío: {DELIVERY_TYPES[sale.deliveryType]}</div>
                <div className="text-s">
                  Servicio: {capitalizeFirstLetter(sale.courierService || '-')}
                </div>
              </>
            ) : (
              <>
                <div className="text-s font-medium">Canal</div>
                <div className="text-s">{sale.channel}</div>
              </>
            )}
            <div className="mt-2 text-s font-medium">Dirección</div>
            <div className="text-s">
              {sale.deliveryAddress}, {sale.city}, {sale.state}
            </div>
            <div className="mt-2 text-s font-medium">Instrucciones</div>
            <div className="text-s">{sale.deliveryInstructions || '-'}</div>
          </div>
          <div className="mt-4 grid grid-cols-2 gap-4">
            <div className="rounded border bg-white p-6">
              <div className="mb-2 flex gap-2 font-medium">
                <img src={SALES_DETAIL_ICONS.person} alt="" className="my-auto w-4" />
                Cliente
              </div>
              <div className="text-s font-medium">Nombre</div>
              <div className="text-s">{clientName()}</div>
              <div className="mt-2 text-s font-medium">Mail cliente</div>
              <div className="text-s">{sale.clientEmail}</div>
              <div className="mt-2 text-s font-medium">Teléfono</div>
              <div className="text-s">{sale.clientPhone}</div>
            </div>
            <div className="rounded border bg-white p-6">
              <div className="mb-2 flex gap-2 font-medium">
                <img src={SALES_DETAIL_ICONS.package} alt="" className="my-auto w-4" />
                Paquete
              </div>
              <div className="text-s font-medium">Ubicacion</div>
              <div className="text-s">{sale.position || '-'}</div>
            </div>
          </div>
        </div>
        <div>
          <div className="rounded border bg-white p-6">
            <div className="mb-2 flex gap-2 font-medium">
              <img src={SALES_DETAIL_ICONS.purchase} alt="" className="my-auto w-4" />
              Compra
            </div>
            <Products
              products={sale.products}
              shippingPaid={sale.shippingPaid}
              totalProductsPrice={sale.totalProductsPrice}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SaleDetail
