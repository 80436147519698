import {
  PACKAGE_BOUGHT,
  PACKAGE_DELIVERED,
  PACKAGE_IN_TRANSIT,
  PACKAGE_ON_DELIVERY,
  PACKAGE_ON_RETURN,
  PACKAGE_PICKED_UP,
  PACKAGE_PROCESSED,
  PACKAGE_RECEIVED,
  PACKAGE_RETURNED,
  PACKAGE_STORED,
  PACKAGE_TOTAL_INCIDENT
} from '../constants/packageStatus'

export const identifierCreator = (company) => {
  let identifier = ''
  if (company) identifier = company.slice(0, 3)

  return identifier.toUpperCase()
}

export const saleStatusColor = (status) => {
  if ([PACKAGE_BOUGHT, PACKAGE_PROCESSED].includes(status)) return 'bg-[#77A6FF1A] text-[#77A6FF]'

  if (
    [
      PACKAGE_IN_TRANSIT,
      PACKAGE_ON_DELIVERY,
      PACKAGE_RECEIVED,
      PACKAGE_STORED,
      PACKAGE_PICKED_UP
    ].includes(status)
  ) {
    return 'bg-[#ECFDF3] text-[#1F8E3D]'
  }

  if (status === PACKAGE_DELIVERED) return 'bg-[#1F8E3D] text-white'

  if ([PACKAGE_ON_RETURN, PACKAGE_RETURNED].includes(status)) return 'bg-[#FFF2DF80] text-[#CF7F30]'

  if (status === PACKAGE_TOTAL_INCIDENT) return 'bg-[#FF77771A] text-[#FF7777]'

  return ''
}

export const saleStatusColorText = (status) => {
  if ([PACKAGE_BOUGHT, PACKAGE_PROCESSED].includes(status)) return 'text-[#3B9DF8]'

  return 'text-[#1F8E3D]'
}

export const saleStatusIconColor = (status) => {
  if ([PACKAGE_BOUGHT, PACKAGE_PROCESSED].includes(status)) return 'bg-[#E9F5FF] border-[#3B9DF8]'

  if (
    [
      PACKAGE_IN_TRANSIT,
      PACKAGE_ON_DELIVERY,
      PACKAGE_RECEIVED,
      PACKAGE_STORED,
      PACKAGE_PICKED_UP
    ].includes(status)
  ) {
    return 'bg-[#E9F5FF] border-[#1F8E3D]'
  }

  return 'bg-[#1F8E3D] border-[#1F8E3D]'
}
