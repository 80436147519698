import React from 'react'

const BillStatus = ({ status }) => {
  const billStatuses = {
    'under review': 'En Revision',
    billed: 'Facturado',
    sent: 'Enviada',
    paid: 'Pagada',
    approved: 'Aprobada'
  }
  const getStatus = () => {
    let mainColor = ''
    if (status === 'under review') mainColor = '#9747FF'
    if (status === 'billed') mainColor = '#33CCCC'
    if (status === 'sent') mainColor = '#34C240'
    if (status === 'paid') mainColor = '#037847'
    if (status === 'approved') mainColor = '#77A6FF'
    return (
      <div
        className="flex items-center justify-center gap-2 rounded-lg border-2 py-1 px-2"
        style={{ borderColor: mainColor, color: mainColor }}
      >
        <div className="h-3 w-3 rounded-full" style={{ backgroundColor: mainColor }} />
        {billStatuses[status]}
      </div>
    )
  }

  return getStatus()
}

export default BillStatus
