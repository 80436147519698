import React, { useState } from 'react'

import { getCourierTimes } from '../../helpers/requests/couriers'
import useFetch from '../../hooks/useFetch'
import { capitalizeFirstLetter } from '../../utils/strings'
import EditGroupButton from '../shared/EditGroupButton'
import InputTable from '../shared/Input/InputTable'
import Loader from '../shared/Loader'
import Table from '../shared/Table/Table'
import TableRow from '../shared/Table/TableRow'

const TimesTable = ({
  courier,
  stateCode,
  editing,
  setEditing,
  setOpenConfirm,
  editedTimes,
  setEditedTimes
}) => {
  const [courierTimes, setCourierTimes] = useState({})

  const { isLoading, error } = useFetch(
    getCourierTimes,
    setCourierTimes,
    `?courier=${courier}&state=${stateCode}`
  )

  const isEditedTime = (city) =>
    !!(editedTimes[city.cityId] !== undefined)

  const timeValue = (city) =>
    isEditedTime(city) ? editedTimes[city.cityId] : city.time

  const handleChange = (event, city) => {
    setEditedTimes({
      ...editedTimes,
      [city.cityId]: parseInt(event.target.value, 10)
    })
  }
  const sortStates = (times) => {
    times.deliveryTimes.sort((a, b) => a.city > b.city)
    return times
  }

  if (isLoading) return <Loader />

  if (error) return <div>Ha ocurrido un error. Inténtelo más tarde.</div>

  if (!isLoading && courierTimes.deliveryTimes.length === 0) {
    return <div>El courier seleccionado no tiene envíos a la región seleccionada</div>
  }

  return (
    <>
      <div className="max-h-1/2-screen overflow-scroll">
        <Table
          headers={['Comuna', 'Tiempo']}
          roundedTop
        >
          {sortStates(courierTimes).deliveryTimes.map((city) => (
            <TableRow
              key={city.city}
              items={[
                capitalizeFirstLetter(city.city),
                <InputTable
                  key={city.cityID}
                  value={timeValue(city)}
                  onChange={(event) => handleChange(event, city)}
                  disabled={!editing}
                  isEdited={isEditedTime(city)}
                />
              ]}
            />
          ))}
        </Table>
      </div>
      <EditGroupButton
        editing={editing}
        setEditing={setEditing}
        isEdited={Object.keys(editedTimes).length > 0}
        handleCancel={() => setEditedTimes({})}
        handleSubmit={() => setOpenConfirm(true)}
      />
    </>
  )
}

export default TimesTable
