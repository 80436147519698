import React from 'react'

const Chip = ({ status, color }) => (
    <div
      style={{backgroundColor: `${color}`}}
      className="m-auto flex h-8 w-fit items-center justify-center rounded"
    >
      <p className="p-3">{status}</p>
    </div>
  )

export default Chip
