import React from 'react'

const TotalSummary = ({ totals }) => {
  const { subtotal, discounts, extras, total } = totals
  return (
    <div className="mt-5 flex h-40 w-1/4 self-end rounded-3xl border bg-white p-6 shadow-md">
      <div className='flex flex-col justify-around'>
        <span className='text-sm font-semibold'>Subtotal:</span>
        <span className='text-sm font-semibold'>Descuentos %:</span>
        <span className='text-sm font-semibold'>Extras:</span>
        <span className='text-sm font-semibold'>Total factura:</span>
      </div>
      <div className='ml-8 flex flex-col justify-around'>
        <span className='text-sm'>{subtotal}</span>
        <span className='text-sm'>{discounts}</span>
        <span className='text-sm'>{extras}</span>
        <span className='text-sm'>{total}</span>
      </div>
    </div>
  )
}


export default TotalSummary
