import React, { useState } from 'react'

import {
  createNotification,
  deleteNotification,
  updateNotifications
} from '../../../helpers/requests/notificationCenter'
import ConfirmModal from '../ConfirmModal'
import Loader from '../../shared/Loader'
import NotificationLogs from './NotificationLogs'
import NotificationsTable from './NotificationsTable'
import { backArrow } from '../../../constants/icons'

const BillingNotifications = ({ notifications, setShouldFetch }) => {
  const [currentNotification, setCurrentNotification] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [newNotification, setNewNotification] = useState({})
  const [currentView, setCurrentView] = useState('allNotifications')

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const removeNotification = async () => {
    setLoading(true)
    await deleteNotification(currentNotification.id)
    setShouldFetch(true)
    setLoading(false)
    setCurrentNotification({})
    handleCloseModal()
  }

  const handleNotificationToggle = async (notificationId, currentActiveStatus) => {
    setLoading(true)

    const notificationToEdit = notifications.find(
      (notification) => notification.id === notificationId
    )

    await updateNotifications({
      ...notificationToEdit,
      active: !currentActiveStatus
    })
    setShouldFetch(true)

    setLoading(false)
  }

  const createNewNotification = async (newNotificationValues) => {
    await createNotification({ notificationData: newNotificationValues })
    setShouldFetch(true)
  }

  const handleStatusSelect = async (e) => {
    if (!newNotification.daysToSend || !newNotification.name) return
    setLoading(true)
    await createNewNotification({
      ...newNotification,
      type: e.target.value,
      function: 'payments'
    })
    setNewNotification({})
    setLoading(false)
  }

  const statusSelector = (name, setter, options) => (
    <select
      className="h-12 w-full cursor-pointer rounded-xl border-2 border-dark-grey bg-white px-2.5 text-sm text-gray-900 focus:border-blue-300 focus:ring-blue-300"
      onChange={async (e) => handleStatusSelect(e)}
      value={newNotification.type || 'Tipo'}
      id={`${name}-select`}
    >
      {options?.map((option) => (
        <option key={option.name} value={option.name}>
          {option.name}
        </option>
      ))}
    </select>
  )

  const handleChange = (field, value) => {
    setNewNotification((prevValues) => {
      if (field === 'daysToSend') {
        let newNotificationName = 'Emitida'
        if (value > 31) newNotificationName = 'Vencida'
        if (value > 20 && value < 31) newNotificationName = 'Por Vencer'
        return { ...prevValues, name: newNotificationName, [field]: value }
      }
      return { ...prevValues, [field]: value }
    })
  }

  return (
    <div className="relative flex h-screen flex-col bg-light-grey">
      <button
        type="button"
        className="sticky top-[70%] left-[90%] flex h-16 w-16 -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-pinflag-dark text-4xl font-thin text-white shadow-xl hover:opacity-75"
        onClick={() => setNewNotification({ daysToSend: 1 })}
      >
        +
      </button>
      <ConfirmModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleSubmit={() => removeNotification()}
        message="Deseas eliminar la notificación?"
        confirmButtonText="Eliminar"
      />
      <div className="mb-8 text-left text-xl font-semibold">
        {currentView !== 'allNotifications' && (
          <button
            type="button"
            className="mx-2 h-auto w-4"
            onClick={() => setCurrentView('allNotifications')}
          >
            <img src={backArrow} alt="volver" />
          </button>
        )}
        Configuraciones
      </div>
      {loading && <Loader />}
      {!loading && currentView === 'allNotifications' && (
        <NotificationsTable
          notifications={notifications}
          handleChange={handleChange}
          handleNotificationToggle={handleNotificationToggle}
          statusSelector={statusSelector}
          setShowModal={setShowModal}
          setCurrentNotification={setCurrentNotification}
          setCurrentView={setCurrentView}
          newNotification={newNotification}
        />
      )}
      {currentView === 'Logs' && <NotificationLogs notificationId={currentNotification.id} />}
    </div>
  )
}

export default BillingNotifications
