import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import useFetch from '../../hooks/useFetch'
import { getHoldings } from '../../helpers/requests/companies'
import ViewBar from '../../components/NotificationCenter/ViewBar'
import ConfirmModal from '../../components/NotificationCenter/ConfirmModal'
import GeneralNotifications from '../../components/NotificationCenter/GeneralNotifications/GeneralNotifications'
import BillingNotifications from '../../components/NotificationCenter/BillingNotifications/BillingNotifications'
import { getNotifications } from '../../helpers/requests/notificationCenter'
import { NOTIFICATION_SECTIONS } from '../../constants/notificationCenter'

const NotificationCenter = () => {
  const [currentView, setCurrentView] = useState(NOTIFICATION_SECTIONS[0].name)
  const [holdings, setHoldings] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [billingNotifications, setBillingNotifications] = useState([])
  const [generalNotifications, setGeneralNotifications] = useState([])
  const [shouldFetch, setShouldFetch] = useState(false)

  useFetch(getHoldings, setHoldings, '')

  const { push } = useHistory()

  const initializeBillingNotifications = useCallback((data) => {
    setBillingNotifications(data)
  }, [])

  const initializeGeneralNotifications = useCallback((data) => {
    setGeneralNotifications(data)
  }, [])

  useFetch(
    getNotifications,
    initializeBillingNotifications,
    '?type=["mail","popup","banner"]&usedFor=payments'
  )
  useFetch(getNotifications, initializeGeneralNotifications, '?type=["mail","popup","banner"]')

  useEffect(() => {
    const notificationsFetcher = {
      'Notificaciones Generales': {
        url: '?type=["mail","popup","banner"]',
        initializer: initializeGeneralNotifications
      },
      'Notificaciones Cobranza': {
        url: '?type=["mail","popup","banner"]&usedFor=payments',
        initializer: initializeBillingNotifications
      }
    }
    if (shouldFetch) {
      getNotifications(notificationsFetcher[currentView].url)
        .then(notificationsFetcher[currentView].initializer)
        .finally(() => setShouldFetch(false))
    }
  }, [shouldFetch, initializeBillingNotifications, initializeGeneralNotifications, currentView])

  const returnView = () => {
    if (currentView === NOTIFICATION_SECTIONS[0].name) {
      return (
        <GeneralNotifications
          holdings={holdings}
          notifications={generalNotifications}
          setShouldFetch={setShouldFetch}
        />
      )
    }
    return (
      <BillingNotifications notifications={billingNotifications} setShouldFetch={setShouldFetch} />
    )
  }
  return (
    <div className=" flex h-screen flex-col bg-light-grey">
      <ConfirmModal
        show={showModal}
        handleSubmit={() => push('/notificaciones/crear')}
        handleClose={() => setShowModal(false)}
        message="Deseas crear un anuncio nuevo?"
      />
      <div className="relative my-10 ml-10 mr-20">
        <div className="mb-8 text-left text-xl font-semibold">Centro de notificaciones</div>
        <div className="my-2 flex justify-between">
          <ViewBar setCurrentView={setCurrentView} currentView={currentView} />
          {currentView === 'Notificaciones Generales' && (
            <button
              type="button"
              className="justify-end rounded-lg bg-pinflag-dark py-3 px-6 font-light text-white"
              onClick={() => setShowModal(true)}
            >
              Crear anuncio
            </button>
          )}
        </div>
        {returnView()}
      </div>
    </div>
  )
}

export default NotificationCenter
