import React from 'react'

const Input = ({ editing, title, register, registration, defaultValue, error, leftText }) => (
  <div className="my-2 w-full">
    <div className="font-light text-ultra-dark-grey">{title}</div>
    {editing ? (
      <div className={leftText ? 'flex' : ''}>
        {leftText && <div className="rounded-lg border border-normal-grey p-1.5">{leftText}</div>}
        <input
          className="w-full rounded-lg border border-normal-grey p-1.5"
          {...register(...registration)}
        />
        {error && <p className="text-xs text-red">{error.message}</p>}
      </div>
    ) : (
      <div className="w-full py-1.5">{defaultValue || '-'}</div>
    )}
  </div>
)

export default Input
