import React, { useState } from 'react'

import Loader from '../../shared/Loader'
import { greenCheck } from '../../../constants/icons'
import { getDeliveryConditions, updateDeliveryConditions } from '../../../helpers/requests/deliveryConditions'
import { saveMapData } from '../../../helpers/requests/map'
import { createMapsBodies } from '../../../constants/map'

const MapAndDeliveryConditions = ({
  setCurrentStep,
  handleCloseModal,
  companyId,
  setSuccessMesssage,
  setErrorsList,
  setShowResponseModal
}) => {
  const [deliveryConditionsParams, setDeliveryConditionsParams] = useState({
    minWeight: 1,
    maxWeight: 100
  })
  const [areDeliveryConditionsReady, setAreDeliveryConditionsReady] = useState(false)
  const [areDeliveryConditionsLoading, setAreDeliveryConditionsLoading] = useState(false)
  const [isMapLoading, setIsMapLoading] = useState(false)
  const [isMapReady, setIsMapReady] = useState(false)

  const handleParams = (event, key) => {
    setDeliveryConditionsParams(prevState => ({
      ...prevState,
      [key]: event.target.value
    }))
  }

  const createMaps = async () => {
    setIsMapLoading(true)
    for (const body of createMapsBodies) {
      try {
        // eslint-disable-next-line no-await-in-loop
        await saveMapData(companyId, { ...body, force: false })
      } catch (error) {
        const { message } = error
        setErrorsList((prevState) => (
          [...prevState, `Error ${message} - ${JSON.stringify(body)} - companyId: ${companyId}`]
        ))
      }
    }
    setIsMapLoading(false)
    setIsMapReady(true)
    setSuccessMesssage('Las delivery conditions y los mapas han sido creados')
    setShowResponseModal(true)
  }

  const verifyDeliveryConditions = () => {
    const interval = setInterval(() => {
      getDeliveryConditions(companyId).then((response) => {
        if(response.data.deliveryConditions) {
          setAreDeliveryConditionsLoading(false)
          setAreDeliveryConditionsReady(true)
          clearInterval(interval)
        }
      })
    }, 2000)
  }

  const createDeliveryConditions = async () => {
    const { minWeight, maxWeight } = deliveryConditionsParams
    setAreDeliveryConditionsLoading(true)
    try {
      await updateDeliveryConditions(companyId, minWeight, maxWeight)
    } catch(error) {
      const { message } = error
      setErrorsList((prevState) => (
        [
          ...prevState,
          `Error ${message} - ${JSON.stringify({ minWeight, maxWeight })} - companyId: ${companyId}`
        ]
      ))
    }
    verifyDeliveryConditions()
  }

  return (
    <div className='mt-5 flex w-full flex-col justify-between p-3'>
      <div className='flex w-8/12 flex-col'>
        <div className='flex items-center justify-between'>
          <div className='flex'>
            {areDeliveryConditionsReady && <img alt='icon' src={greenCheck} className='mr-1 h-auto w-8' />}
            {areDeliveryConditionsLoading ? (
              <>
                <Loader sizeClassname='my-1 h-4 w-4'/>
                <h1>Se están creando las delivery conditions ...</h1>
              </>
            ) : (
              <>
                <h1>Peso min: </h1>
                <input
                  type='number'
                  className='mx-2 w-14 rounded border border-normal-grey px-1'
                  value={deliveryConditionsParams.minWeight}
                  onChange={(value) => handleParams(value, 'minWeight')}
                />
                <h1>Peso max: </h1>
                <input
                  type='number'
                  className='mx-2 w-14 rounded border border-normal-grey px-1'
                  value={deliveryConditionsParams.maxWeight}
                  onChange={(value) => handleParams(value, 'maxWeight')}
                />
              </>
            )}
          </div>
          <div className='w-1/2'>
            <button
              className='ml-5 h-10 w-auto rounded-lg bg-pinflag px-5 text-white hover:bg-pinflag-200'
              type='button'
              onClick={createDeliveryConditions}
            >
              Crear delivery conditions
            </button>
          </div>
        </div>
        <div className='mt-5 flex items-center justify-between'>
          {isMapReady && <img alt='icon' src={greenCheck} className='mr-1 h-auto w-8' />}
          {isMapLoading && (
            <>
              <Loader sizeClassname='my-1 h-4 w-4'/>
              <h1>Se están creados los mapas ...</h1>
            </>
          )}
          {areDeliveryConditionsReady && !isMapLoading && (
            <h1>Haz clic en el botón</h1>
          )}
          {!areDeliveryConditionsReady && !isMapReady && !isMapLoading && (
            <h1>Espera a que estén creadas las delivery conditions</h1>
          )}
          <div className='w-1/2'>
            <button
              className={`ml-5 h-10 w-auto rounded-lg px-5 text-white ${areDeliveryConditionsReady ? 'bg-pinflag hover:bg-pinflag-200' : 'bg-medium-gray hover:bg-medium-gray'}`}
              type='button'
              onClick={createMaps}
              disabled={!areDeliveryConditionsReady}
            >
              Crear mapas
            </button>
          </div>
        </div>
      </div>

      <div className='my-8 flex justify-end'>
        <button
          className='mx-5 h-10 w-1/5 rounded-lg bg-medium-gray px-5 text-white hover:bg-pinflag-200'
          type='button'
          onClick={() => setCurrentStep(2)}
        >
          Volver
        </button>
        <button
          className='mx-5 h-10 w-1/5 rounded-lg bg-pinflag px-5 text-white hover:bg-pinflag-200'
          type='button'
          onClick={() => handleCloseModal()}
        >
          Finalizar
        </button>
      </div>
    </div>
  )
}

export default MapAndDeliveryConditions
