import React from 'react'

const Products = ({
  products,
  refundProducts,
  totalProductsAmount,
  saleDeliveryCost,
  paidDeliveryCost,
  totalProductsCost
}) => (
  <div>
    {products.map((product) => {
      const refundProduct = refundProducts.find(
        (refundProductItem) => refundProductItem.sku === product.sku
      )
      return (
        <div
          className={`my-4 flex gap-3 rounded border p-2 text-s ${
            refundProduct ? 'border-pinflag' : ''
          }`}
        >
          {product.imageUrls?.length > 0 ? (
            <img
              src={product.imageUrls[0]}
              alt={product.productName}
              className="h-auto max-h-[6rem] w-auto max-w-[6rem]"
            />
          ) : (
            <div className="h-24 w-24 bg-semi-light-grey" />
          )}
          <div>
            <div>{product.name}</div>
            <div className="text-dark-grey">SKU: {product.sku}</div>
            <div className="text-dark-grey">Cantidad: {product.quantity}</div>
            <div className="text-dark-grey">Precio: {product.price}</div>
            {refundProduct && (
              <div className="text-xs text-pinflag">
                Cantidad devuelta: {refundProduct.quantity}
              </div>
            )}
          </div>
        </div>
      )
    })}
    <div className="mt-4 flex justify-between text-xs">
      <div className="text-medium-dark-grey">Cantidad total de productos</div>
      <div className="text-medium-dark-grey">{totalProductsAmount}</div>
    </div>
    <hr className="my-3" />
    <div className="mt-2 flex justify-between text-xs">
      <div className="text-medium-dark-grey">Envío</div>
      <div className="text-medium-dark-grey">{saleDeliveryCost}</div>
    </div>
    <div className="mt-2 flex justify-between text-xs">
      <div className="text-medium-dark-grey">Envío pagado por cliente</div>
      <div className="text-medium-dark-grey">{paidDeliveryCost}</div>
    </div>
    <div className="mt-2 flex justify-between text-xs">
      <div className="text-medium-dark-grey">Compra</div>
      <div className="text-medium-dark-grey">{totalProductsCost}</div>
    </div>
    <hr className="my-3" />
    <div className="mt-2 flex justify-between text-xs">
      <div className="text-medium-dark-grey">Total</div>
      <div className="text-medium-dark-grey">{totalProductsCost + paidDeliveryCost}</div>
    </div>
  </div>
)

export default Products
