import React from 'react'

import CheckColumn from './CheckColumn'

const CheckGeneral = ({
    optionsCheck,
    checkState,
    setCheckState,
    setSelected,
    selected,
    type,
    setHasChanged,
    keyName
  }) => {
  const handleChange = (position, id) => {
    const updatedCheckedState = checkState.map((item, index) =>
      index === position ? !item : item
    )
    setCheckState(updatedCheckedState)
    if (selected[type].has(id)) {
      selected[type].delete(id)
    } else {
      setSelected({...selected, [type]: selected[type].add(id) })
    }
    setHasChanged(true)
  }

  return (
    <CheckColumn
      checkList={optionsCheck}
      handleChange={handleChange}
      checkState={checkState}
      keyName={keyName}
    />
  )
}

export default CheckGeneral
