const ROUTES = {
  CONDITIONS: '/condiciones',
  BILLINGS: '/facturas',
  BILLINGS_DETAIL: '/facturas/:id',
  DETAILS: '/details/:id',
  DETAILS_EDIT_BILL: '/facturas/:id/:billId',
  DISCOUNT: '/descuento',
  RECHARGE: '/recarga',
  COURIER_PRICES: '/courier/tarifas',
  COURIER_PRICES_DETAIL: '/courier/tarifas/detalle',
  DELIVERY_TIMES: '/courier/tiempos',
  REGISTER_COMPANY: '/company/signup',
  COMPANIES: '/companies',
  TICKETS: '/tickets',
  QUOTING_PRICE: '/quoting',
  SHOW_TICKETS: '/tickets/:id',
  SALES: '/pedidos',
  DASHBOARD: '/dashboard',
  PERMISSIONS: '/permisos',
  SALE: '/venta',
  REFUNDS: '/indemnizaciones',
  REFUND: '/indemnizaciones/:refundId',
  CREATE_REFUND: '/indemnizaciones/crear/:packageId',
  NOTIFICATION_CENTER: '/notificaciones',
  NOTIFICATION_EDIT: '/notificaciones/edit/:notificationId/:usedFor',
  NOTIFICATION_CREATE: '/notificaciones/crear/'
}

export default ROUTES
