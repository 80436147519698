import React from 'react'

import { Link } from 'react-router-dom'

const SidebarButton = ({ data, active }) => (
  <Link to={data.path} style={{ textDecoration: 'none' }}>
    <button
      className={`flex h-10 w-full cursor-pointer items-center font-light
        ${active ? 'text-white' : 'text-white'}
           hover:bg-ultra-dark-grey hover:text-white`}
      type="button"
    >
      <div className={`h-10 w-1.5 rounded-r ${active && 'bg-white'}`}/>
      <div className="flex justify-start text-center">
        <b className="ml-3 w-full self-center text-s">{data.text}</b>
      </div>
    </button>
  </Link>
)

export default SidebarButton
