import { React } from 'react'

import { shortDate, getTimeFromFormattedDate } from '../../utils/dates'

const LogsTimeline = ({ steps }) => {
  if (!steps || steps.length === 0) {
    return <div className="py-2 text-center text-xs">No hay datos del courier</div>
  }
  return (
    <div className="flex w-full flex-col text-xs">
      <div className="my-0 ml-4 mb-2 flex flex-col p-0">
        {steps.map((step, index) => (
          <div key={step.name} className="flex items-start p-4">
            <div className="mr-2 text-end">
              <p className="my-1">{shortDate(step.createdAt)}</p>
              <p className="my-1">{getTimeFromFormattedDate(step.createdAt)}</p>
            </div>
            <div className="mr-2 h-10 w-10 min-w-max">
              <span
                className={`relative top-1 z-10 mx-auto block h-2.5 w-2.5 rounded-full ${
                  index === 0 ? 'bg-ultra-dark-grey' : 'bg-medium-dark-grey'
                }`}
              >
                {' '}
              </span>
              {index !== steps.length - 1 && (
                <div
                  className="relative left-[calc(25%_+_0.55rem)] m-0 h-[calc(100%_+_2rem)] w-0 border border-medium-dark-grey"
                >
                  {' '}
                </div>
              )}
            </div>
            <div className="flex flex-col justify-start">
              <div
                className={`${
                  index === 0 ? 'text-sm font-medium' : 'text-medium-dark-grey'
                }`}
              >
                {step.statusDescription}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default LogsTimeline
