import { ASSETS_URL } from './icons'

export const REFUND_CREATED = 'created'
export const REFUND_ON_REVIEW = 'onReview'
export const REFUND_PENDING = 'pending'
export const REFUND_COMPLETED = 'refunded'
export const REFUND_REJECTED = 'rejected'

export const REFUND_STATUSES = [
  {
    value: 'created',
    name: 'Creado'
  },
  {
    value: 'onReview',
    name: 'En revisión'
  },
  {
    value: 'pending',
    name: 'Pendiente'
  },
  {
    value: 'refunded',
    name: 'Reembolsado'
  },
  {
    value: 'rejected',
    name: 'Declinado'
  }
]

export const REGULAR_STATUSES = [
  {
    value: 'created',
    name: 'Creado'
  },
  {
    value: 'onReview',
    name: 'En revisión'
  },
  {
    value: 'pending',
    name: 'Pendiente'
  },
  {
    value: 'refunded',
    name: 'Reembolsado'
  }
]


export const REFUNDS_STATES_TRANSLATION = {
  [REFUND_CREATED]: 'Creada',
  [REFUND_ON_REVIEW]: 'En revisión',
  [REFUND_PENDING]: 'Pendiente de pago',
  [REFUND_COMPLETED]: 'Pagada',
  [REFUND_REJECTED]: 'Declinada'
}

export const STATUS_COLOR = {
  [REFUND_CREATED]: 'text-[#9747FF] bg-[#F7F0FF]',
  [REFUND_ON_REVIEW]: 'text-[#0E9AFF] bg-[#ECF7FF]',
  [REFUND_PENDING]: 'text-[#1F8E3D] bg-[#EFFBF0]',
  [REFUND_COMPLETED]: 'text-[#E9F9F9] bg-[#1F8E3D]',
  [REFUND_REJECTED]: 'text-[#D64242] bg-[#FFE9E9]'
}

export const STATUS_ICONS = {
  [REFUND_CREATED]: `${ASSETS_URL}/refunds/created.png`,
  [REFUND_ON_REVIEW]: `${ASSETS_URL}/refunds/inReview.png`,
  [REFUND_PENDING]: `${ASSETS_URL}/refunds/pending.png`,
  [REFUND_COMPLETED]: `${ASSETS_URL}/refunds/refunded.png`,
  [REFUND_REJECTED]: `${ASSETS_URL}/refunds/rejected.png`
}

export const TIMELINE_STATUS_ICONS = {
  created: `${ASSETS_URL}/refunds/timeline/created.png`,
  onReview: `${ASSETS_URL}/refunds/timeline/inReview.png`,
  pending: `${ASSETS_URL}/refunds/timeline/pending.png`,
  refunded: `${ASSETS_URL}/refunds/timeline/refunded.png`,
  rejected: `${ASSETS_URL}/refunds/timeline/rejected.png`
}

export const TIMELINE_STATUS_COLOR = {
  created: 'bg-[#F7F0FF] border-[#9747FF]',
  onReview: 'bg-[#ECF7FF] border-[#0E9AFF]',
  pending: 'bg-[#EFFBF0] border-[#1F8E3D]',
  refunded: 'bg-[#1F8E3D] border-[#1F8E3D]',
  rejected: 'bg-[#D64242] border-[#D64242]'
}

export const TIMELINE_TEXT_COLOR = {
  created: 'text-[#9747FF]',
  onReview: 'text-[#0E9AFF]',
  pending: 'text-[#1F8E3D]',
  refunded: 'text-[#1F8E3D]',
  rejected: 'text-[#D64242]'
}
