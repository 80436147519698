import React from 'react'

import { backArrow } from '../../constants/icons'

const BreadCrumbHeader = ({ goBack, title, subtitle }) => (
  <div className="mb-8 flex flex-wrap items-center gap-3 text-xl">
    <button type="button" onClick={goBack}>
      <img src={backArrow} alt="atrás" className="my-auto h-3 w-auto" />
    </button>
    <div>{title}</div>
    <div className="my-auto text-lg text-dark-grey">&rsaquo;</div>
    <div className="font-semibold">{subtitle}</div>
  </div>
)

export default BreadCrumbHeader
