import React, { useState, useCallback } from 'react'

import DropDown from '../../shared/DropDown'
import { getHoldings } from '../../../helpers/requests/companies'
import { getCouriersList } from '../../../helpers/requests/couriers'
import STATES from '../../../constants/zones'
import { DELIVERY_METHODS, DEVICES } from '../../../constants/general'
import { getActiveCouriers } from '../../../helpers/couriers'
import useFetch from '../../../hooks/useFetch'
import { getFormattedDateWithoutTimezone, substractDate } from '../../../utils/dates'
import { SERVICES_TYPES_LIST } from '../../../constants/couriers'

import CheckGeneral from './CheckFilters/CheckGeneral'
import DateFilter from './DateFilter'
import FilterTags from './FilterTags/FilterTags'

const Filters = ({
  setFilters,
  filterCourier,
  filterState,
  filterDevice,
  filterService,
  filterDelivery,
  filterCompany
}) => {
  const today = new Date().toLocaleDateString('en-CA')
  const [initialDate, setInitialDate] = useState(substractDate(new Date(), 30))
  const [finalDate, setFinalDate] = useState(today)
  const [checkedDate, setCheckedDate] = useState('30')
  const [holdings, setHoldings] = useState([])
  const [couriers, setCouriers] = useState([])
  const [companies, setCompanies] = useState([])

  const [courierState, setCourierState] = useState([])
  const [holdingsState, setHoldingsState] = useState([])
  const [devicesState, setDevicesState] = useState(new Array(DEVICES.length).fill(false))
  const [checkDeliveryState, setDeliveryState] = useState(
    new Array(DELIVERY_METHODS.length).fill(false)
  )
  const [statesState, setStatesState] = useState(new Array(STATES.length).fill(false))
  const [serviceTypeState, setServiceTypeState] = useState(
    new Array(SERVICES_TYPES_LIST.length).fill(false)
  )
  const [companyState, setCompanyState] = useState([])
  const [selectedFilters, setSelectedFilters] = useState({
    couriers: new Set(),
    holdings: new Set(),
    delivery: new Set(),
    states: new Set(),
    devices: new Set(),
    serviceType: new Set(),
    companies: new Set()
  })
  const [hasChanged, setHasChanged] = useState(false)

  const filterSetters = {
    couriers: { setter: setCourierState, optionSize: couriers.length },
    holdings: { setter: setHoldingsState, optionSize: holdings.length },
    delivery: { setter: setDeliveryState, optionSize: DELIVERY_METHODS.length },
    states: { setter: setStatesState, optionSize: STATES.length },
    devices: { setter: setDevicesState, optionSize: DEVICES.length },
    serviceType: { setter: setServiceTypeState, optionSize: SERVICES_TYPES_LIST.length },
    companies: { setter: setCompanyState, optionSize: 0 }
  }

  const courierSetter = useCallback((data) => {
    setCouriers(data)
    setCourierState(new Array(data.length).fill(false))
  }, [])

  const holdingSetter = useCallback((data) => {
    setHoldings(data)
    setHoldingsState(new Array(data.length).fill(false))
  }, [])

  useFetch(getCouriersList, courierSetter, '', getActiveCouriers)
  useFetch(getHoldings, holdingSetter)

  const isChecked = (checkedStates) => checkedStates.some((value) => value)

  const isDateSelected = () => {
    if (initialDate !== '' || finalDate !== today) return true
    return false
  }

  const handleEraseFilters = () => {
    setInitialDate(substractDate(new Date(), 30))
    setCheckedDate('30')
    setFinalDate(today)
    for (const filterSetter of Object.values(filterSetters)) {
      filterSetter.setter(new Array(filterSetter.optionSize).fill(false))
    }
    setSelectedFilters({
      couriers: new Set(),
      holdings: new Set(),
      delivery: new Set(),
      states: new Set(),
      devices: new Set(),
      serviceType: new Set(),
      companies: new Set()
    })
    setHasChanged(true)
  }

  const assembleFilter = (currentFilter, name, type, checkedStatus) => {
    if (selectedFilters[type].size > 0 && !checkedStatus.every((value) => value)) {
      if (currentFilter.length > 1) {
        return currentFilter.concat(`&${name}=`, [...selectedFilters[type]].join(','))
      }
      return currentFilter.concat(`${name}=`, [...selectedFilters[type]].join(','))
    }
    return currentFilter
  }

  const handleFilter = () => {
    let filters = '?'
    if (initialDate !== '') {
      filters = filters.concat('startDate=', getFormattedDateWithoutTimezone(initialDate))
    }
    if (finalDate !== today) {
      filters = filters.concat('&finishDate=', getFormattedDateWithoutTimezone(finalDate))
    }
    filters = assembleFilter(filters, 'holdingId', 'holdings', holdingsState)
    filters = assembleFilter(filters, 'courierName', 'couriers', courierState)
    filters = assembleFilter(filters, 'stateId', 'states', statesState)
    filters = assembleFilter(filters, 'deliveryType', 'delivery', checkDeliveryState)
    filters = assembleFilter(filters, 'serviceType', 'serviceType', serviceTypeState)
    filters = assembleFilter(filters, 'device', 'devices', devicesState)
    filters = assembleFilter(filters, 'companyId', 'companies', companyState)
    setFilters(filters)
    setHasChanged(false)
  }

  const cleanAFilter = (name) => {
    setSelectedFilters({
      ...selectedFilters,
      [name]: new Set()
    })
    filterSetters[name].setter(new Array(filterSetters[name].optionSize).fill(false))
    setHasChanged(true)
  }

  const handleHoldingChange = (updatedChecks) => {
    setHoldingsState(updatedChecks)

    if (isChecked(updatedChecks)) {
      const selectedHoldings = holdings.filter((_, index) => updatedChecks[index])
      const companiesHoldings = selectedHoldings.map((holding) => holding.companies).flat()

      setCompanyState(new Array(companiesHoldings.length).fill(false))
      setCompanies(companiesHoldings)
    } else {
      setCompanies([])
    }
  }

  return (
    <div>
      <div className="my-4 flex flex-wrap">
        <DropDown name="Fecha" isChecked={isDateSelected()}>
          <DateFilter
            initialDate={initialDate}
            setInitialDate={setInitialDate}
            finalDate={finalDate}
            setFinalDate={setFinalDate}
            today={today}
            checked={checkedDate}
            setChecked={setCheckedDate}
            setHasChanged={setHasChanged}
          />
        </DropDown>
        {filterDelivery && (
          <DropDown name="Tipo de envío" isChecked={isChecked(checkDeliveryState)}>
          <CheckGeneral
            optionsCheck={DELIVERY_METHODS}
            setCheckState={setDeliveryState}
            checkState={checkDeliveryState}
            setSelected={setSelectedFilters}
            selected={selectedFilters}
            setHasChanged={setHasChanged}
            type="delivery"
            keyName="id"
          />
        </DropDown>
        )}
        {filterCourier && (
          <DropDown name="Courier" isChecked={isChecked(courierState)}>
            <CheckGeneral
              optionsCheck={couriers}
              setCheckState={setCourierState}
              checkState={courierState}
              setSelected={setSelectedFilters}
              selected={selectedFilters}
              setHasChanged={setHasChanged}
              type="couriers"
              keyName="name"
            />
          </DropDown>
        )}
        {filterState && (
          <DropDown name="Región" isChecked={isChecked(statesState)}>
            <CheckGeneral
              optionsCheck={STATES}
              setCheckState={setStatesState}
              checkState={statesState}
              setSelected={setSelectedFilters}
              selected={selectedFilters}
              setHasChanged={setHasChanged}
              type="states"
              keyName="id"
            />
          </DropDown>
        )}
        {filterService && (
          <DropDown name="Servicio" isChecked={isChecked(serviceTypeState)}>
          <CheckGeneral
            optionsCheck={SERVICES_TYPES_LIST}
            setCheckState={setServiceTypeState}
            checkState={serviceTypeState}
            setSelected={setSelectedFilters}
            selected={selectedFilters}
            setHasChanged={setHasChanged}
            type="serviceType"
            keyName="id"
          />
        </DropDown>
        )}
        <DropDown name="Holding" isChecked={isChecked(holdingsState)}>
          <CheckGeneral
            optionsCheck={holdings}
            setCheckState={handleHoldingChange}
            checkState={holdingsState}
            setSelected={setSelectedFilters}
            selected={selectedFilters}
            setHasChanged={setHasChanged}
            type="holdings"
            keyName="id"
          />
        </DropDown>
        {filterDevice && (
          <DropDown name="Dispositivo" isChecked={isChecked(devicesState)}>
            <CheckGeneral
              optionsCheck={DEVICES}
              setCheckState={setDevicesState}
              checkState={devicesState}
              setSelected={setSelectedFilters}
              selected={selectedFilters}
              setHasChanged={setHasChanged}
              type="devices"
              keyName="id"
            />
          </DropDown>
        )}
        {filterCompany && companies.length > 1 && (
          <DropDown name="Empresa" isChecked={isChecked(companyState)}>
            <CheckGeneral
              optionsCheck={companies}
              setCheckState={setCompanyState}
              checkState={companyState}
              setSelected={setSelectedFilters}
              selected={selectedFilters}
              setHasChanged={setHasChanged}
              type="companies"
              keyName="companyId"
            />
          </DropDown>
        )}
        <button
          className={`ml-2 rounded-lg px-6 text-sm text-white ${
            hasChanged ? 'bg-pinflag hover:opacity-70' : 'cursor-default bg-medium-gray'
          }`}
          type="button"
          onClick={hasChanged ? handleFilter : undefined}
        >
          Filtrar
        </button>
        <button
          className="ml-3 rounded-lg border border-pinflag px-6 text-sm text-pinflag hover:opacity-70"
          type="button"
          onClick={handleEraseFilters}
        >
          Limpiar filtros
        </button>
      </div>
      <FilterTags
        checkedDate={checkedDate}
        initialDate={initialDate}
        finalDate={finalDate}
        selectedFilters={selectedFilters}
        holdings={holdings}
        devices={DEVICES}
        cleanTag={cleanAFilter}
        companies={companies}
      />
    </div>
  )
}

export default Filters
