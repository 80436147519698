import { checkEmail } from '../../utils/strings'

export const holdingDataValidations = {
	name: { required: 'El campo \'Nombre Holding\' es requerido'},
	defaultCourier: {required: 'El campo \'Courier por defecto\' es requerido'},
	defaultWeight: { required: 'El campo \'Peso por defecto\' es requerido',
		min: {
			value: 1,
			message: 'El peso por defecto debe ser mayor a 0'
		}
	},
	distributionCenterAddress: { required: 'El campo \'Dirección\' es requerido'},
	distributionCenterCityId: {required: 'El campo \'Comuna\' es requerido',
		min: {
			value: 1,
			message: 'Debe seleccionar una comuna'
		}}
}

export const userDataValidations = {
	email: { validate: {
		isEmail: value => checkEmail(value) || 'El campo \'Email\' debe tener un correo válido'
	},
		required: 'El campo \'Email\' es requerido'
	},
	password: { required: 'El campo \'Contraseña\' es requerido',
		minLength: {
			value: 8,
			message: 'El campo \'Contraseña\' debe tener al menos 8 caracteres'
		}}
}

export const holdingValidations = {
	holdingId: {required: 'El campo \'Holding\' es requerido'}
}

export const companyDataValidations = {
	businessLine: {required: 'El campo \'Giro\' es requerido'},
	businessName: {required: 'El campo \'Razón social\' es requerido'},
	companyType: {required: 'El campo \'Tipo de empresa\' es requerido'},
	name: {required: 'El campo \'Nombre Empresa\' es requerido'},
	phone: {required: 'El campo \'Teléfono\' es requerido',
		minLength: {
			value: 9,
			message: 'El campo \'Telefono\' debe tener 9 dígitos'
		},
		maxLength: {
			value: 9,
			message: 'El campo \'Telefono\' debe tener 9 dígitos'
		}
	},
	rut: {required: 'El campo \'Rut\' es requerido',
		pattern: {
			value: /^([0-9]{7,9}-[\dkK])/,
			message: 'Ingrese el rut sin puntos y con guión'
		}
	}
}

export const cmsValidations = {
	cms: {required: 'El campo \'CMS\' es requerido'},
	accessToken: {required: 'El campo \'Access Token\' es requerido'},
	storefrontToken: {required: 'El campo \'Storefront Token\' es requerido'},
	hostname: {
		required: 'El campo \'Nombre url\' es requerido',
		validate: {
			haveShoppify: value => !value.includes('.myshopify.com') || 'No se debe incluir \'.myshopify.com\' en el nombre de la url'
		}
	},
	secretWebhookToken: {required: 'El campo \'Secret Web token\' es requerido'},
	cityCheckoutField: {required: 'Debe seleccionar un tipo'},
	streetNameCheckoutField: {required: 'Debe seleccionar un tipo'},
	buildingNumberCheckoutField: {required: 'Debe seleccionar un tipo'},
	deliveryInstructionsCheckoutField: {required: 'Debe seleccionar un tipo'},
	appToken: {required: 'El campo \'App Token\' es requerido'},
	appKey: {required: 'El campo \'Appkey\' es requerido'},
	accountName: {required: 'El campo \'Nombre de la cuenta\' es requerido'},
	commerceUrl: {required: 'El campo \'Url del comercio\' es requerido'},
	username: {required: 'El campo \'Username\' es requerido'},
	password: {required: 'El campo \'Password\' es requerido'},
	apiUrl: {required: 'El campo \'Api Url\' es requerido'}
}
