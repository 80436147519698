import React from 'react'

const DateFilter = ({
  finalDate,
  initialDate,
  setInitialDate,
  setFinalDate,
  today
}) => {
  const handleChangeInitialDate = (event) => {
    setInitialDate(event.target.value)
  }

  const handleChangeFinalDate = (event) => {
    const lastDate = event.target.value
    if (lastDate === '') {
      setFinalDate(today)
    } else {
      setFinalDate(lastDate)
    }
  }
  return (
  <div className="my-4 flex items-center justify-evenly">
    <span className="text-pinflag-dark">Fecha:</span>
    <input
      type="date"
      name="initialDate"
      placeholder="Desde"
      max={finalDate}
      value={initialDate}
      onChange={handleChangeInitialDate}
    />
    <input
      type="date"
      name="finalDate"
      placeholder="Hasta"
      max={today}
      min={initialDate}
      value={finalDate}
      onChange={handleChangeFinalDate}
    />
  </div>
)}

export default DateFilter
