import statsApi from '../../axiosConfig/statsApi'

export const getPackagesStats = async (params) => {
  const url = params ? `/stats/packages${params}` : '/stats/packages'
  const response = await statsApi.get(url)

  return response.data
}

export const getTicketsStats = async (params) => {
  const url = params ? `/stats/tickets${params}` : '/stats/tickets'
  const response = await statsApi.get(url)

  return response.data
}

export const getSLAStats = async (params) => {
  const url = params ? `/stats/sla${params}` : '/stats/sla'
  const response = await statsApi.get(url)

  return response.data
}

export const getIncidentsStats = async (params) => {
  const url = params ? `/stats/incidents${params}` : '/stats/incidents'
  const response = await statsApi.get(url)

  return response.data
}

export const getPinmapStats = async (params) => {
  const url = params ? `/stats/checkout${params}` : '/stats/checkout'
  const response = await statsApi.get(url)

  return response.data
}
