import React from 'react'

const ActionItem = ({ action, loading, icon, text }) => (
  <button
      className='flex w-full flex-nowrap whitespace-nowrap rounded px-1 py-1.5 hover:bg-light-grey'
      type='button'
      onClick={action}
    >
      {loading ? (
        'Cargando...'
      ) : (
        <>
          {icon && <img src={icon} className='mr-2 w-4' alt={text} />}
          {text}
        </>
      )}
    </button>
)

export default ActionItem
