import React, { useEffect, useState, useCallback } from 'react'
import moment from 'moment'

import useClickOutside from '../../../hooks/useClickOutside'
import Button from '../../shared/Button'
import { getHoldings } from '../../../helpers/requests/companies'
import { getCouriersList } from '../../../helpers/requests/couriers'
import { getActiveCouriers } from '../../../helpers/couriers'
import useFetch from '../../../hooks/useFetch'
import { DELIVERY_TYPES } from '../../../constants/sales'

import CheckColumn from './CheckColumn'
import DateFilter from './DateFilter'

const CheckFilters = ({ isFiltered, setIsFiltered, handleFilterChange, sales, dataLoaded }) => {
  const [courierList, setCourierList] = useState([])
  const [checkCourierState, setCheckCourierState] = useState([])
  const [companiesList, setCompanyList] = useState([])
  const [checkCompaniesState, setCheckCompaniesState] = useState([])
  const deliveryTypes = Object.keys(DELIVERY_TYPES)
  const [checkDeliveryState, setCheckDeliveryState] = useState(
    new Array(deliveryTypes.length).fill(false)
  )

  const today = new Date()
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState(today)

  const [checkIncident, setCheckIncident] = useState(false)
  const [checkOwnPoints, setCheckOwnPoints] = useState(false)
  const [isOpen, setIsOpen, popUpRef] = useClickOutside()

  const courierSetter = useCallback((data) => {
    const couriersData = data.map((courier) => courier.name)
    setCourierList(couriersData)
    setCheckCourierState(new Array(couriersData.length).fill(false))
  }, [])

  const holdingSetter = useCallback((data) => {
    const holdingData = data.map((holding) => holding.name)
    setCompanyList(holdingData)
    setCheckCompaniesState(new Array(holdingData.length).fill(false))
  }, [])

  useFetch(getCouriersList, courierSetter, '', getActiveCouriers)
  useFetch(getHoldings, holdingSetter)

  const activeChecks = (checksState) =>
    !checksState.every((value) => value === true) && !checksState.every((value) => value === false)

  const filterSales = (salesData, checkState, listOptions, attribute) => {
    const selectedOptions = listOptions.filter((_, index) => checkState[index])
    const filteredOptions = selectedOptions.map((element) => element.toLowerCase())
    const filteredSales = salesData?.filter((sale) =>
      filteredOptions.includes(sale[attribute]?.toLowerCase())
    )
    return filteredSales
  }

  const filterByDate = (salesList) => {
    let salesFilteredByDate = salesList
    if (initialDate === '') {
      salesFilteredByDate = salesList.filter(
        (sale) =>
          moment(sale.fechacompra, 'DD-MM-YYYY, HH:mm:ss') <= moment(finalDate, 'YYYY-MM-DD')
      )
    } else {
      salesFilteredByDate = salesList.filter(
        (sale) =>
          moment(sale.fechacompra, 'DD-MM-YYYY, HH:mm:ss') >= moment(initialDate, 'YYYY-MM-DD') &&
          moment(sale.fechacompra, 'DD-MM-YYYY, HH:mm:ss') <= moment(finalDate, 'YYYY-MM-DD')
      )
    }
    return salesFilteredByDate
  }

  const filterByAllFilters = (enterpriseChanged, courierChanged, deliveryChanged, datesChanged) => {
    let filteredData = sales
    if (enterpriseChanged) {
      filteredData = filterSales(filteredData, checkCompaniesState, companiesList, 'companyName')
    }
    if (courierChanged) {
      filteredData = filterSales(filteredData, checkCourierState, courierList, 'courierName')
    }
    if (deliveryChanged) {
      filteredData = filterSales(filteredData, checkDeliveryState, deliveryTypes, 'deliveryType')
    }
    if (datesChanged) filteredData = filterByDate(filteredData)
    if (checkIncident) filteredData = filteredData.filter((sale) => sale.incident !== null)
    return filteredData
  }

  const handleSubmit = () => {
    const enterpriseChanged = activeChecks(checkCompaniesState)
    const courierChanged = activeChecks(checkCourierState)
    const deliveryChanged = activeChecks(checkDeliveryState)
    const datesChanged = initialDate !== '' || finalDate !== today

    const filteredData = filterByAllFilters(
      enterpriseChanged,
      courierChanged,
      deliveryChanged,
      datesChanged
    )
    handleFilterChange(filteredData)
    setIsFiltered(
      enterpriseChanged ||
        courierChanged ||
        deliveryChanged ||
        datesChanged ||
        checkIncident ||
        checkOwnPoints
    )
  }

  const handleChecked = (position, checkState, setCheckState) => {
    const updatedCheckedState = checkState.map((check, index) =>
      index === position ? !check : check
    )
    setCheckState(updatedCheckedState)
  }

  const handleCompanies = (position) => {
    handleChecked(position, checkCompaniesState, setCheckCompaniesState)
  }

  const handleCourier = (position) => {
    handleChecked(position, checkCourierState, setCheckCourierState)
  }

  const handleDeliveryMethods = (position) => {
    handleChecked(position, checkDeliveryState, setCheckDeliveryState)
  }

  const handleClean = () => {
    setIsOpen(false)
    setCheckCourierState(new Array(courierList.length).fill(false))
    setCheckCompaniesState(new Array(companiesList.length).fill(false))
    setCheckDeliveryState(new Array(deliveryTypes.length).fill(false))
    setCheckIncident(false)
    setCheckOwnPoints(false)
    setIsFiltered(false)
    handleFilterChange(sales)
  }

  useEffect(() => {
    handleSubmit()
  }, [dataLoaded])

  return (
    <div className="relative z-10 m-2 w-fit lg:my-auto" ref={popUpRef}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`rounded px-4 py-2 text-white ${isOpen ? 'bg-pinflag' : 'bg-pinflag-200'}`}
        role="button"
        tabIndex="0"
      >
        Filtros
      </div>
      {isOpen && (
        <div className="absolute left-0 flex w-fit flex-col bg-white p-2 drop-shadow lg:right-0 lg:left-auto">
          <form className="flex max-h-[30vh] overflow-y-auto lg:max-h-[40vh]">
            <CheckColumn
              name="Empresa"
              checkList={companiesList}
              handleChange={handleCompanies}
              checkState={checkCompaniesState}
            />
            <CheckColumn
              name="Couriers"
              checkList={courierList}
              handleChange={handleCourier}
              checkState={checkCourierState}
            />
            <CheckColumn
              name="Tipo de envío"
              checkList={deliveryTypes.map(deliveryType => DELIVERY_TYPES[deliveryType])}
              handleChange={handleDeliveryMethods}
              checkState={checkDeliveryState}
            />
          </form>
          <DateFilter
            finalDate={finalDate}
            initialDate={initialDate}
            setInitialDate={setInitialDate}
            setFinalDate={setFinalDate}
            today={today}
          />
          <div className="my-2 mx-4 flex justify-between">
            <label htmlFor="incident" className="flex">
              <input
                className="my-auto mr-1"
                type="checkbox"
                id="incident"
                onChange={() => setCheckIncident(!checkIncident)}
                checked={checkIncident}
              />
              Pedidos con incidentes
            </label>
          </div>
          <div className=" mx-4 mb-4 flex justify-center space-x-4">
            <Button color="bg-pinflag" onClick={(e) => handleSubmit(e)} small >Filtrar</Button>
            {isFiltered && (
              <Button onClick={handleClean} color="bg-medium-gray" small >Limpiar filtros</Button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default CheckFilters
