import React from 'react'
import Modal from '../shared/Modal/Modal'
import Loader from '../shared/Loader'

const ConfirmModal = ({
  show,
  handleSubmit,
  handleClose,
  isLoading,
  message = '¿Desea guardar cambios?',
  confirmButtonText = 'Confirmar'
}) => (
  <Modal show={show} handleClose={handleClose}>
    <div className="m-8">
      {message}
      {isLoading ? (
        <div className="mt-7">
          <Loader />
        </div>
      ) : (
        <div className="mt-10 flex justify-between">
          <button
            type="button"
            className="h-12 w-24 rounded-xl border-2 border-pinflag-dark font-light hover:opacity-75"
            onClick={handleClose}
          >
            Cancelar
          </button>
          <button
            type="button"
            className="h-12 w-24 rounded-xl bg-pinflag-dark font-light text-white hover:opacity-75"
            onClick={handleSubmit}
          >
            {confirmButtonText}
          </button>
        </div>
      )}
    </div>
  </Modal>
)

export default ConfirmModal
