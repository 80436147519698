import React, { useState } from 'react'

import Modal from '../../shared/Modal/Modal'
import Button from '../../shared/Button'
import FilePicker from '../../shared/FilePicker'
import { downloadChargeNote, uploadDocumentRefund } from '../../../helpers/requests/refunds'
import RawLoader from '../../shared/Loader'
import ResponseModal from '../../shared/Modal/ResponseModal'
import { downloadFileUrl } from '../../../utils/files'

const ChargeNote = ({
  refundId,
  openUploadFile,
  setOpenUploadFile,
  unsignedChargeNoteAvailable,
  signedChargeNoteAvailable
  }) => {
  const [signedChargeNote, setSignedChargeNote] = useState(null)
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [errorDownload, setErrorDownload] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [errorUpload, setErrorUpload] = useState(false)
  const [openResponseModal, setOpenResponseModal] = useState(false)

  const downloadNote = async (type) => {
    setLoadingDownload(true)
    try {
      const response = await downloadChargeNote(refundId, type)
      downloadFileUrl(response.fileUrl)
    } catch {
      setErrorDownload(true)
    }
    setLoadingDownload(false)
  }

  const handleUploadFile = async () => {
    setLoadingUpload(true)
    const formData = new FormData()
    formData.append('files', signedChargeNote)
    formData.append('type', 'unsignedChargeNote')

    try {
      await uploadDocumentRefund(refundId, formData)
      setOpenResponseModal(true)
    } catch {
      setErrorUpload(true)
    }
    setLoadingUpload(false)
  }

  if (unsignedChargeNoteAvailable && !signedChargeNoteAvailable) {
    return (
      <Modal
        show={openUploadFile}
        handleClose={() => setOpenUploadFile(false)}
        title="La nota de cobro ya está subida"
      >
        <div className="m-2 text-s">
          Si deseas ver la nota de cobro que subiste, puedes descargarla aquí
        </div>
        {loadingDownload ? (
          <RawLoader />
        ) : (
          <button
            className="mt-2 w-full rounded bg-pinflag py-1.5 text-s text-white"
            onClick={() => downloadNote('unsigned')}
            type="button"
          >
            Descargar nota de cobro
          </button>
        )}
        {errorDownload && (
          <div className="mt-2 text-xs text-red">
            Ha ocurrido un error al descargar la nota de cobro
          </div>
        )}
      </Modal>
    )
  }

  if (signedChargeNoteAvailable) {
    return (
      <Modal
        show={openUploadFile}
        handleClose={() => setOpenUploadFile(false)}
        title="La nota de cobro ya está subida"
      >
        <div className="m-2 text-s">
          Si deseas ver la nota de cobro firmada por el e-commerce,
          puedes descargarla aquí
        </div>
        {loadingDownload ? (
          <RawLoader />
        ) : (
          <button
            className="mt-2 w-full rounded bg-pinflag py-1.5 text-s text-white"
            onClick={() => downloadNote('signed')}
            type="button"
          >
            Descargar nota de cobro
          </button>
        )}
        {errorDownload && (
          <div className="mt-2 text-xs text-red">
            Ha ocurrido un error al descargar la nota de cobro
          </div>
        )}
      </Modal>
    )
  }

  return (
    <>
      <Modal
        show={openUploadFile}
        handleClose={() => setOpenUploadFile(false)}
        title="Necesitamos la nota de cobro"
      >
        <div className="m-2 text-s">
          Debes subir la nota de cobro para que el e-commerce pueda revisarla y firmarla.
          <div className="my-4 flex justify-center">
            <FilePicker
              editing
              file={signedChargeNote}
              handleFile={(fileContent) => setSignedChargeNote(fileContent[0])}
              isLoaded={!!signedChargeNote}
              download
            />
          </div>
          <div className="flex justify-end">
            {loadingUpload ? (
              <RawLoader />
            ) : (
              <Button
                border="border border-pinflag"
                textColor="text-pinflag"
                onClick={handleUploadFile}
                inactive={!signedChargeNote}
              >
                Cargar
              </Button>
            )}
          </div>
          {errorUpload && (
            <div className="mt-2 text-xs text-red">
              Ha ocurrido un error al cargar el archivo
            </div>
          )}
        </div>
      </Modal>
      <ResponseModal
        show={openResponseModal}
        handleClose={() => {
          window.location.reload()
        }}
        successMessage="Nota de cobro cargada exitosamente"
      />
    </>
  )
}

export default ChargeNote
