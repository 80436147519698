import React, { useEffect } from 'react'

import { capitalizeFirstLetter } from '../../../utils/strings'

const Select = ({ options, label, setter, withId, withName, numeric, defaultValue, disabled }) => {
  const handleChange = (event) => {
    let newValue = event.target.value
    if (withId) newValue = parseInt(newValue, 10)
    setter(newValue)
  }

  const getValue = (option) => {
    switch (true) {
      case withId:
        return option.id
      case withName:
        return option.name
      default:
        return option
    }
  }

  useEffect(() => {
    if (defaultValue) {
      setter(defaultValue)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <label className="mb-2 block whitespace-nowrap text-left text-sm font-medium text-gray-900" htmlFor="select">
        {label}
      </label>
      <select
        className="block w-full cursor-pointer rounded-lg border border-gray-300 bg-white p-2.5 text-sm text-gray-900 focus:border-blue-300 focus:ring-blue-300"
        onChange={(e) => handleChange(e)}
        id="select"
        disabled={disabled}
      >
        {options.map((option) => (
          <option key={withId || withName ? option.id : option} value={getValue(option)}>
            {numeric ? option : capitalizeFirstLetter(withId || withName ? option.name : option)}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Select
