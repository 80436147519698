import React from 'react'

const FunnelBar = ({ fill, x, y, width, height, payload }) => (
  <polygon
    fill={fill}
    points={`${x},${height + y} ${x},${y} ${x + width},${
      y + (payload.abandonmentCount || 0) * (height / payload.value)
    } ${x + width},${height + y}`}
  />
)

export default FunnelBar
