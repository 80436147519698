import React from 'react'

const PieToolTip = ({ payload, total }) => {
  if (payload.length > 0) {
    return (
      <div className="rounded-lg border-2 border-light-grey bg-white p-2 text-xs outline-none">
        <div className="flex">
        <div className="my-auto mr-1 h-1.5 w-1.5 rounded-full" style={{ backgroundColor: payload[0].payload.fill}}/>
          <div>{((payload[0].value / total) * 100).toFixed(2)}%</div>
        </div>
        <div>{payload[0].value}</div>
      </div>
    )
  }
  return null
}

export default PieToolTip
