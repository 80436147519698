import React, { useState } from 'react'

import { changeRefundStatus } from '../../../helpers/requests/refunds'
import Button from '../../shared/Button'
import Modal from '../../shared/Modal/Modal'
import ResponseModal from '../../shared/Modal/ResponseModal'
import { REFUND_STATUSES } from '../../../constants/refunds'
import Loader from '../../shared/Loader'

const ChangeRefundStatus = ({ refundId, openChangeStatus, setOpenChangeStatus }) => {
  const [selectedStatus, setSelectedStatus] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [openResponseModal, setOpenResponseModal] = useState(false)

  const changeStatus = async() => {
    setLoading(true)
    try{
      await changeRefundStatus(refundId, selectedStatus)
    }
    catch {
      setError(true)
    } finally {
      setLoading(false)
      setOpenResponseModal(true)
    }
  }

  return (
    <>
      <Modal title="Cambiar estado" show={openChangeStatus} handleClose={() => setOpenChangeStatus(false)}>
        <div className='my-2 text-s'>
          Selecciona el nuevo estado de la indemnización
          <select
            className="my-5 block w-full rounded border py-2 pl-2"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            >
              {REFUND_STATUSES.map((status) => (
                <option key={status.value} value={status.value}>{status.name }</option>
              ))}
            </select>
        </div>
        <div className="flex justify-end gap-4">
          {loading ? <Loader /> : (
            <>
              <Button color="bg-pinflag" onClick={() => changeStatus()}>
                Confirmar
              </Button>
              <Button color="bg-normal-grey" onClick={() => setOpenChangeStatus(false)}>
                Cancelar
              </Button>
            </>
          )}
        </div>
      </Modal>
      <ResponseModal
        show={openResponseModal}
        handleClose={error ? () => setOpenResponseModal(false) : () => window.location.reload()}
        error={error}
      />
    </>
  )
}

export default ChangeRefundStatus
