import { React, useState } from 'react'

import { capitalizeFirstLetter } from '../../utils/strings'
import useFormQuoting from '../../hooks/useFormQuoting'
import ShowQuotingResults from '../shared/ShowQuotingResults'
import { getCompanyQuoting } from '../../helpers/requests/quoting'

const CompanyForm = ({ states, agreements }) => {
  const [state, setState] = useState('')
  const [nameAgreement, setNameAgreement] = useState('')
  const [errorAPI, setErrorAPI] = useState(false)

  const initialForm = {
    userId: 0,
    cityName: '',
    weight: 0,
    stateId: 0,
    clickAndCollect: false
  }

  const validationForm = (form) => {
    const errors = {}
    if (form.userId === 0) {
      errors.userId = 'Holding es un campo requerido'
    }
    if (form.weight === 0) {
      errors.weight = 'Peso es un campo requerido'
    }
    if (form.stateId === 0) {
      errors.stateId = 'Región es un campo requerido'
    }
    if (form.cityName === 0) {
      errors.cityName = 'Comuna es un campo requerido'
    }
    return errors
  }

  const {
    form,
    errors,
    loading,
    response,
    handleChange,
    handleBlur,
    handleSubmit,
    setErrorsForm,
    setForm
  } = useFormQuoting(initialForm, validationForm, getCompanyQuoting, setErrorAPI)

  const handleAgreement = (event) => {
    setNameAgreement(event)
    const agreementSelected = agreements.find(agr => agr.userId === parseInt(event, 10))
    setForm({
      ...form,
      userId: agreementSelected.userId
    })
  }

  return (
    <div className="flex w-full">
      <div className="m-14 flex w-10/12 max-w-2xl flex-col items-center">
        <form className="flex w-full max-w-2xl flex-col" onSubmit={handleSubmit}>
          <div className="flex gap-x-8">
            <div className="flex w-3/6 flex-col">
              <div className="mb-4 grid">
                <div className="flex flex-col">
                  <div className="flex items-center space-x-4">
                    <div className="block w-1/6 text-sm font-bold text-gray-700">
                      Holding
                    </div>
                    <select
                      className="block w-5/6 rounded border border-light-grey p-2.5 focus:border-dark-grey"
                      name="courier_plan"
                      value={nameAgreement}
                      onChange={(event) => handleAgreement(event.target.value)}
                      onBlur={handleBlur}
                      required
                    >
                      <option value="" disabled hidden>
                        Selecciona un holding
                      </option>
                      {agreements &&
                        agreements.map((agreement) => (
                          <option key={agreement.userId} value={agreement.userId}>
                            {capitalizeFirstLetter(agreement.name)}
                          </option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                {errors.userId && <p className="text-sm text-red">{errors.userId}</p>}
              </div>
              <div className="mb-4 grid">
                <div className="flex flex-col">
                  <div className="mb-4 flex items-center space-x-4">
                    <div className="block w-1/6 text-sm font-bold text-gray-700">
                      Región
                    </div>
                    <select
                      className="block w-5/6 rounded border border-light-grey p-2.5 focus:border-dark-grey"
                      name="stateId"
                      value={form.stateId}
                      onChange={(event) => {
                        setState(event.target.value)
                        handleChange(event)
                      }}
                      required
                    >
                      <option value="" disabled hidden>
                        Selecciona una región
                      </option>
                      {Object.values(states).map((currState) => (
                        <option key={currState.stateId} value={currState.stateId}>
                          {currState.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {errors.stateId && <p className="text-sm text-red">{errors.stateId}</p>}
              </div>
              <div className="mb-4 grid">
                <div className="flex flex-col">
                  <div className="flex items-center space-x-4">
                    <div className="block w-1/6 text-sm font-bold text-gray-700">
                      Comuna
                    </div>
                    <select
                      className="block w-5/6 rounded border border-light-grey p-2.5 focus:border-dark-grey"
                      name="cityName"
                      value={form.cityName}
                      disabled={state === ''}
                      onChange={(event) => handleChange(event)}
                      onBlur={handleBlur}
                      required
                    >
                      <option value={0} disabled hidden>
                        Selecciona una comuna
                      </option>
                      {state &&
                        states[state].cities.map((city) => (
                          <option key={city.id} value={city.name}>
                            {city.name}
                          </option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                {errors.cityName && <p className="text-sm text-red">{errors.cityName}</p>}
              </div>
            </div>
            <div className="flex w-3/6 flex-col">
            <div className="mb-4 grid">
                <div className="flex items-center space-x-4">
                  <div className="block w-1/6 text-sm font-bold text-gray-700">
                    Peso (kg)
                  </div>
                  <input
                    className="block w-5/6 rounded border border-light-grey p-2.5 focus:border-dark-grey"
                    type="number"
                    name="weight"
                    value={form.weight}
                    onChange={(e) => handleChange(e)}
                    onBlur={handleBlur}
                    step="1"
                    min="1"
                    max="20"
                  />
                </div>
                {errors.weight && <p className="w-5/6 justify-self-end text-sm text-red">{errors.weight}</p>}
              </div>
              <div className="mb-4 grid">
                <div className="flex items-center space-x-4">
                  <div className="block w-1/6 text-sm font-bold text-gray-700">
                    Tipo Envío
                  </div>
                  <select
                    className="block w-5/6 rounded border border-light-grey p-2.5 focus:border-dark-grey"
                    name="clickAndCollect"
                    value={form.clickAndCollect}
                    onChange={(event) => {
                      const booleanValue = (event.target.value === 'true')
                      handleChange({target: {value: booleanValue, name: 'clickAndCollect'}})
                    }}
                    required
                  >
                    <option key="clickCollect" value>
                      Click & Collect
                    </option>
                    <option key="homeShipping" value={false}>
                      Domicilio
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="my-8 grid">
            {errorAPI && <p>Hubo un error {response.error}</p>}
            <button
              className="h-10 w-4/12 justify-self-end rounded-md bg-pinflag text-white hover:bg-pinflag-200"
              type="submit"
              onClick={setErrorsForm}
            >
              Cotizar
            </button>
          </div>
        </form>
      </div>
      <ShowQuotingResults
        response={response}
        loading={loading}
      />
    </div>
  )
}

export default CompanyForm
