import axios from 'axios'

import { API_URL } from '../constants/endpointUrls/api'

const apiClient = axios.create({
  baseURL: API_URL
})

apiClient.interceptors.request.use((config) => {
  const newConfig = config
  if (localStorage.getItem('token')) {
    newConfig.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
  }
  return newConfig
})

export default apiClient
