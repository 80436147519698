export const IMAGES_CONTENT_TYPES = {
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  svg: 'image/svg+xml'
}

export const MONTHS = [
  {id: 1, name: 'Ene'},
  {id: 2, name: 'Feb'},
  {id: 3, name: 'Mar'},
  {id: 4, name: 'Abr'},
  {id: 5, name: 'May'},
  {id: 6, name: 'Jun'},
  {id: 7, name: 'Jul'},
  {id: 8, name: 'Ago'},
  {id: 9, name: 'Sept'},
  {id: 10, name: 'Oct'},
  {id: 11, name: 'Nov'},
  {id: 12, name: 'Dic'}
]

export const MONTHS_HASH= {
  Ene: 1,
  Feb: 2,
  Mar: 3,
  Abr: 4,
  May: 5,
  Jun: 6,
  Jul: 7,
  Ago: 8,
  Sept: 9,
  Oct: 10,
  Nov: 11,
  Dic: 12
}

export const YEARS = [
  {id: 1, name: '2022'},
  {id: 2, name: '2023'},
  {id: 3, name: '2024'}
]
