import React from 'react'

const StatChip = ({ text, value, color }) => (
  <div
    className="m-4 flex justify-between rounded-lg px-4 py-1 text-sm"
    style={{ background: color }}
  >
    <div className="mr-4">{text}</div>
    <div>{value}</div>
  </div>
)

export default StatChip
