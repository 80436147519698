import React from 'react'

import { ALL_PACKAGES, PACKAGE_STATES } from '../../../constants/sales'
import StateFilterButton from './StateFilterButton'

const StateFilter = ({ filterStates, setFilterStates }) => {
  const selectState = (status, active) => {
    const currentFilters = new Set(filterStates)
    if (active) currentFilters.delete(status)
    else currentFilters.add(status)
    setFilterStates(currentFilters)
  }

  return (
    <div className="mb-4 flex overflow-y-auto">
      <div className="my-auto text-dark-grey">Estados:</div>
      {PACKAGE_STATES.map((state) => (
        <StateFilterButton
          key={state.number}
          state={state}
          onClick={() => selectState(state.number, filterStates.has(state.number))}
          active={filterStates.has(state.number)}
        />
      ))}
      <StateFilterButton
        state={ALL_PACKAGES}
        onClick={() => setFilterStates(new Set())}
        active={filterStates.size === 0}
      />
    </div>
  )
}

export default StateFilter
