import React, { useState } from 'react'

import { getFormattedDateWithoutTimezone, substractDate } from '../../../utils/dates'
import Filters from '../Filters/Filters'

import SLAAndCourier from './Groups/SLAAndCouriers'

const SLAAndCourierView = () => {
  const [currentFilters, setCurrentFilters] = useState(
    `?startDate=${getFormattedDateWithoutTimezone(substractDate(new Date(), 30))}`
  )

  return (
    <div>
      <Filters
        setFilters={setCurrentFilters}
        filterCourier
        filterState
        filterService
        filterDelivery
      />
      <SLAAndCourier currentFilters={currentFilters}/>
    </div>
  )
}

export default SLAAndCourierView
