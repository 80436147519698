export const COURIER_URL = process.env.REACT_APP_COURIER_URL

export const DISCOUNT_URL = '/price/discount'

export const RECHARGE_URL = '/price/charge'

export const COURIER_PRICES_URL = '/courier-prices'

export const COURIER_PRICES_DETAIL_URL = '/courier-price'

export const COURIER_TIMES_URL = '/delivery-time/courier'

export const QUOTING = '/price'
