import axios from 'axios'

import { STATS_API_URL } from '../constants/endpointUrls/api'

const statsApi = axios.create({
  baseURL: STATS_API_URL,
  timeout: 30000
})

statsApi.interceptors.request.use((config) => {
  const newConfig = config

  if (localStorage.getItem('token')) {
    newConfig.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
  }

  return newConfig
})

export default statsApi
