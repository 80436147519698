import React from 'react'
import { useHistory } from 'react-router-dom'

import { backArrow } from '../../constants/icons'


const SectionHeader = ({
  title,
  subtitle,
  searchBar,
  previousRoute,
  rightChildren,
  dropdown
}) => {
  const { push } = useHistory()
  return (
    <div
      className="z-10 flex h-20 min-h-0 place-content-between content-center bg-white pl-10 pr-24"
    >
      <div className="flex items-center">
        {previousRoute && (
          <div>
            <div
              className="mr-4 h-5 w-6"
              onClick={() => push(previousRoute)}
              role="button"
              tabIndex="0"
            >
              <img alt="back-arrow" src={backArrow} />
            </div>
          </div>
        )}
        {dropdown || (
          <div className="mr-2 text-base font-medium text-ultra-dark-grey">{title}</div>
        )}
        {subtitle && (
          <>
            <div className="mr-2 text-sm text-ultra-dark-grey">&gt;</div>
            <div className="text-base text-ultra-dark-grey">{subtitle}</div>
          </>
        )}
      </div>
      {rightChildren && <div className="mx-2 self-center">{rightChildren}</div>}
      {searchBar && (
        <div className="mx-2 hidden self-center overflow-hidden md:block">{searchBar}</div>
      )}
    </div>
  )}

export default SectionHeader
