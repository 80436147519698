import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import useFetch from '../../hooks/useFetch'
import {
  getNotifications,
  updateNotifications
} from '../../helpers/requests/notificationCenter'
import { getHoldings } from '../../helpers/requests/companies'
import ResponseModal from '../../components/shared/Modal/ResponseModal'
import InputNameAndHoldings from '../../components/NotificationCenter/ContentEdition/NotificationNameAndHoldings'
import { capitalizeFirstLetter } from '../../utils/strings'
import NotificationContent from '../../components/NotificationCenter/ContentEdition/NotificationContent'

const EditNotification = () => {
  const [notification, setNotification] = useState({})
  const [formValues, setFormValues] = useState({})
  const [holdings, setHoldings] = useState([])
  const [selectedHoldings, setSelectedHoldings] = useState([])
  const [showModal, setShowModal] = useState(false)

  const { notificationId, usedFor } = useParams()

  const initializeData = useCallback((data) => {
    setNotification(data[0])
    setFormValues(data[0])
    setSelectedHoldings(data[0].Holdings.map((holding) => holding.id))
  }, [])

  useFetch(getNotifications, initializeData, `?notificationId=${notificationId}`)
  useFetch(getHoldings, setHoldings, '')

  const handleChange = (type, field, event) => {
    const { value } = event.target

    setFormValues((prevValues) => {
      if (field === 'name') {
        return { ...prevValues, [field]: value }
      }
      return {
        ...prevValues,
        content: {
          ...prevValues.content,
          [type]: value
        }
      }
    })
  }

  const handleSelectAllHoldings = (isActive, filteredHoldings) => {
    if (isActive) {
      const holdingIds = filteredHoldings.map((holding) => holding.id)
      setSelectedHoldings(holdingIds)
    } else setSelectedHoldings([])
  }
  const handleHoldingSelect = (company) => {
    setSelectedHoldings((prevSelected) =>
      prevSelected.includes(company.id)
        ? prevSelected.filter((id) => id !== company.id)
        : [...prevSelected, company.id]
    )
  }

  const handleUpdateNotification = async () => {
    await updateNotifications({ ...formValues, holdingIds: selectedHoldings })
  }

  const handleCancel = () => {
    setFormValues({...notification})
    setSelectedHoldings(notification.Holdings.map((holding) => holding.id))
  }

  return (
    <div className="relative h-fit ">
      <ResponseModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        successMessage="La notificación fue guardardada con éxito"
      />
      <InputNameAndHoldings
        formValues={formValues}
        holdings={holdings}
        selectedHoldings={selectedHoldings}
        handleChange={handleChange}
        handleHoldingSelect={handleHoldingSelect}
        handleSelectAllHoldings={handleSelectAllHoldings}
        title="Editar Anuncio"
        subtitle={`Notificación - ${capitalizeFirstLetter(notification?.type || '')}`}
        usedFor={usedFor}
      >
        <NotificationContent
          notification={notification}
          formValues={formValues}
          handleChange={handleChange}
          handleUpdateNotification={handleUpdateNotification}
          setShowModal={setShowModal}
          handleCancelButton={handleCancel}
        />
      </InputNameAndHoldings>
    </div>
  )
}

export default EditNotification
