import React from 'react'

import Modal from '../../shared/Modal/Modal'

const ConfirmSignUpCompanyModal = ({
  show,
  loading,
  response,
  handleClose,
  error,
  errorList,
  errorMessage
}) => (
  <Modal show={show} handleClose={handleClose}>
    <div className="m-8">
      {loading && (
        <div className="mb-10 flex items-center justify-center">
          <p>Enviando datos...</p>
        </div>
      )}
      {!loading && response && (
        <div className="m-5">
          <p>La empresa fue registrada</p>
          {errorList.length > 0 && (
            <p className="mb-10 text-red">
              Los siguientes errores ocurrieron en la creación:
              {errorList.map((errorItem) => (
                <p>{JSON.stringify(errorItem)}</p>
              ))}
            </p>
          )}
        </div>
      )}
      {!loading && error && !response && (
        <p className="mb-10 text-red">
          Ha ocurrido un error. Inténtelo más tarde. <br/>
          {(errorMessage !== '' || (errorList && errorList.length >0)) && 'Error: '}
          {errorList && errorList.map((errorItem) => <p>{errorItem.message}</p>)}
          {errorMessage}
        </p>
      )}
    </div>
  </Modal>
)

export default ConfirmSignUpCompanyModal
