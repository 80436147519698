export const parseLastWeekIncidents = (lastWeekIncidents) =>
  Object.values(lastWeekIncidents).reverse().map((dayIncident) => ({
    name: dayIncident.weekDay,
    value: dayIncident.incidentsCount,
    date: dayIncident.date.slice(0, 5)
  }))

export const parseIncidentsByCourier = (incidentsByCourier, totalCount) =>
  Object.entries(incidentsByCourier).map(([courier, incident]) => ({
    name: courier,
    value: incident,
    percentage: totalCount === 0 ? '0%' : `${((incident / totalCount) * 100).toFixed(2)}%`
  }))

export const parseIncidentsByType = (incidentsByType, totalCount) =>
  Object.entries(incidentsByType).map(([type, incident]) => ({
    name: type,
    value: incident,
    percentage: totalCount === 0 ? '0%' : `${((incident / totalCount) * 100).toFixed(2)}%`
  }))
