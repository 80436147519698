import apiClient from '../../axiosConfig/apiClient'
import courierClient from '../../axiosConfig/courierClient'
import { COMPANY_QUOTING } from '../../constants/endpointUrls/api'
import { QUOTING } from '../../constants/endpointUrls/courier'

export const getQuoting = async (body) => {
  const response = await courierClient.post(QUOTING, body)

  return response.data
}

export const getCompanyQuoting = async (body) => {
  const response = await apiClient.post(COMPANY_QUOTING, body)

  return response.data
}
