import React from 'react'

import { getFormattedDateWithoutTimezone } from '../../../../utils/dates'
import { DELIVERY_METHODS } from '../../../../constants/general'
import STATES from '../../../../constants/zones'
import { SERVICES_TYPES_LIST } from '../../../../constants/couriers'

import FilterTag from './FilterTag'

const FilterTags = ({
  checkedDate,
  initialDate,
  finalDate,
  selectedFilters,
  holdings,
  devices,
  cleanTag,
  companies
}) => {
  const today = new Date().toLocaleDateString('en-CA')

  const datesText = () => {
    switch (true) {
      case checkedDate !== 0:
        return `Últimos ${checkedDate} días`
      case initialDate !== '' && finalDate !== today:
        return `Entre ${getFormattedDateWithoutTimezone(
          initialDate
        )} - ${getFormattedDateWithoutTimezone(finalDate)}`
      case initialDate !== '':
        return `Desde ${getFormattedDateWithoutTimezone(initialDate)}`
      default:
        return ''
    }
  }

  const deliveryText = () => {
    const deliveryOptions = [...selectedFilters.delivery].map(
      (deliveryOption) =>
        DELIVERY_METHODS.find((deliveryMethod) => deliveryMethod.id === deliveryOption).name
    )
    return deliveryOptions.join(' - ')
  }

  const courierText = () => {
    if (selectedFilters.couriers.size === 1) return [...selectedFilters.couriers][0]
    return `${selectedFilters.couriers.size} couriers`
  }

  const statesText = () => {
    if (selectedFilters.states.size === 1) return STATES[[...selectedFilters.states][0] - 1].name
    return `${selectedFilters.states.size} regiones`
  }

  const holdingsText = () => {
    if (selectedFilters.holdings.size === 1) {
      const holdingSelected = holdings.find(
        (holding) => holding.id === [...selectedFilters.holdings][0]
      )
      return holdingSelected.name
    }
    return `${selectedFilters.holdings.size} holdings`
  }

  const devicesText = () => {
    const devicesOptions = [...selectedFilters.devices].map(
      (devicesOption) => devices.find((device) => device.id === devicesOption).name
    )
    return devicesOptions.join(' - ')
  }

  const servicesText = () => {
    if (selectedFilters.serviceType.size === 1) {
      return SERVICES_TYPES_LIST.find(
        (service) => [...selectedFilters.serviceType][0] === service.id
      ).name
    }

    return `${selectedFilters.serviceType.size} servicios`
  }

  const companiesText = () => {
    if (selectedFilters.companies.size === 1) {
      return companies.find(
        (company) => company.companyId === [...selectedFilters.companies][0]
      ).name
    }

    return `${selectedFilters.companies.size} empresas`
  }

  const dynamicTags = [
    { name: 'delivery', text: deliveryText },
    { name: 'couriers', text: courierText },
    { name: 'states', text: statesText },
    { name: 'holdings', text: holdingsText },
    { name: 'devices', text: devicesText },
    { name: 'serviceType', text: servicesText },
    { name: 'companies', text: companiesText }
  ]

  return (
    <div className="my-4 flex flex-wrap gap-x-1">
      <FilterTag filterText={datesText()} />
      {dynamicTags.map(
        (dynamicTag) =>
          selectedFilters[dynamicTag.name].size !== 0 && (
            <FilterTag
              key={dynamicTag.name}
              filterText={dynamicTag.text()}
              cleanTag={() => cleanTag(dynamicTag.name)}
            />
          )
      )}
    </div>
  )
}

export default FilterTags
