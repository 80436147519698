import { React, useCallback, useState } from 'react'

import TimesTable from '../components/CourierTimes/TimesTable'
import Select from '../components/shared/Input/Select'
import Loader from '../components/shared/Loader'
import ConfirmModal from '../components/shared/Modal/ConfirmModal'
import ResponseModal from '../components/shared/Modal/ResponseModal'
import { DEFAULT_COURIER } from '../constants/general'
import REGIONS from '../constants/zones'
import { getActiveCouriers } from '../helpers/couriers'
import { getCouriersList, updateCourierTimes } from '../helpers/requests/couriers'
import useFetch from '../hooks/useFetch'

const DeliveryTimes = () => {
  const [editing, setEditing] = useState(false)
  const [courier, setCourier] = useState(DEFAULT_COURIER)
  const [stateCode, setStateCode] = useState(1)
  const [editedTimes, setEditedTimes] = useState({})
  const [isOpenConfirm, setOpenConfirm] = useState(false)
  const [openResponseModal, setOpenResponseModal] = useState(false)
  const [activeCouriers, setActiveCouriers] = useState([])
  const [isSubmitLoading, setSubmitLoading] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const setCouriers = useCallback((couriers) => {
    setActiveCouriers(couriers)
    setCourier(couriers[0].name)
  }, [])
  const { isLoading: isLoadingCouriers, error: errorCouriers } = useFetch(
    getCouriersList,
    setCouriers,
    '',
    getActiveCouriers
  )

  const parseBody = () => {
    const body = {}
    body.courier = courier
    body.times = []
    Object.entries(editedTimes).map((editedTime) => (
      body.times.push({'cityId':parseInt(editedTime[0], 10), 'time':editedTime[1]})
    ))
    return body
  }

  const handleSubmit = async () => {
    const body = parseBody()
    setSubmitLoading(true)
    try {
      updateCourierTimes(body)
      setOpenConfirm(false)
    } catch {
      setSubmitError(true)
    } finally {
      setSubmitLoading(false)
      setOpenResponseModal(true)
    }
  }

  if (isLoadingCouriers || errorCouriers || submitError) {
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          {isLoadingCouriers ? <Loader /> : <div>Ha ocurrido un error. Inténtelo más tarde.</div>}
        </div>
      </div>
    )
  }

  return (
    <div className="relative m-10">
      <div className="mb-8 text-left text-xl font-semibold">Tiempos de Envío</div>
      <div className="mb-12 flex">
        <div className="w-1/3">
          <Select
            options={activeCouriers}
            label="Courier"
            setter={(value) => {
              setEditedTimes({})
              setCourier(value)
              setEditing(false)
            }}
            withName
          />
        </div>
        <div className="mx-8 w-1/3">
          <Select
            options={REGIONS}
            label="Región"
            setter={(value) => {
              setEditedTimes({})
              setStateCode(value)
              setEditing(false)
            }}
            withId
          />
        </div>
      </div>
      <TimesTable
        courier={courier}
        stateCode={stateCode}
        editing={editing}
        setEditing={setEditing}
        setOpenConfirm={setOpenConfirm}
        editedTimes={editedTimes}
        setEditedTimes={setEditedTimes}
      />
      <ConfirmModal
        handleSubmit={handleSubmit}
        show={isOpenConfirm}
        handleClose={() => setOpenConfirm(false)}
        isLoading={isSubmitLoading}
      />
      <ResponseModal
        error={submitError}
        show={openResponseModal}
        handleClose={() => window.location.reload()}
      />
    </div>
  )
}

export default DeliveryTimes
