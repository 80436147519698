export const parseLastWeekPackages = (lastWeekPackages) =>
  Object.values(lastWeekPackages).reverse().map((dayPackage) => ({
    name: dayPackage.weekDay,
    value: dayPackage.packagesCount,
    date: dayPackage.date.slice(0, 5)
  }))

export const parsePackagesByHolding = (packagesByHolding, totalCount) => (
  Object.entries(packagesByHolding).map(([holdingName, packageHolding]) => ({
    name: holdingName,
    value: packageHolding,
    percentage: totalCount === 0 ? '0%' : `${(packageHolding / totalCount) * 100}%`
  }))
)

export const parsePackagesByState = (packagesByState, totalCount) => (
  Object.entries(packagesByState).map(([stateName, packageState]) => ({
    name: stateName,
    value: packageState,
    percentage: totalCount === 0 ? '0%' : `${(packageState / totalCount) * 100}%`
  }))
)
