import React from 'react'

import Modal from '../../shared/Modal/Modal'

const ResultMessageModal = ({
  successMessage,
  show,
  handleClose,
  errorsList
}) => (
  <Modal show={show} handleClose={handleClose}>
    <div className="m-8">
        <div className="m-5">
          {!!successMessage && <p className="mb-5">{successMessage}</p>}
          {!!errorsList.length && (
            <p className="mb-10 text-red">
              Los siguientes errores ocurrieron:
              {errorsList.map((errorItem) => (
                <p>{errorItem}</p>
              ))}
            </p>
          )}
        </div>
    </div>
  </Modal>
)

export default ResultMessageModal
