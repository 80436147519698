import React, { useState } from 'react'

import FilePicker from '../../shared/FilePicker'
import { BILLING_ICONS, errorIcon } from '../../../constants/icons'
import { uploadDocumentRefund } from '../../../helpers/requests/refunds'
import Loader from '../../shared/Loader'
import Button from '../../shared/Button'

const FileUploader = ({ refundId }) => {
  const [files, setFiles] = useState([])
  const [uploadError, setUploadError] = useState(false)

  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState(false)

  const handleFile = async (objectFiles) => {
    const newFiles = [...objectFiles]
    setFiles([...files, ...newFiles])
  }

  const removeFile = (index) => {
    const newFiles = [...files]
    newFiles.splice(index, 1)
    setFiles(newFiles)
  }

  const makeFormData = () => {
    const formData = new FormData()
    for (const file of files) {
      formData.append('files', file)
    }
    formData.append('type', 'evidence')
    return formData
  }

  const handleUpload = async () => {
    setLoading(true)
    const formData = makeFormData()

    try {
      await uploadDocumentRefund(refundId, formData)
      setStep(1)
      setLoading(false)
      setTimeout(() => window.location.reload(), 2000)
    } catch (error) {
      setLoading(false)
      setStep(1)
      setUploadError(true)
    }
  }

  return (
    <div>
      <div className="mt-4 flex flex-col items-center gap-1 bg-light-grey px-16 py-8">
        {loading ? (
          <div className="flex min-h-[138px] min-w-[288px] flex-col justify-center">
            <Loader />
          </div>
        ) : (
          <div>
            {step === 0 && (
              <div className="mt-2 flex flex-col items-center gap-4 bg-light-grey px-14 py-6">
                <FilePicker
                  files={files}
                  handleFile={handleFile}
                  removeFile={removeFile}
                  editing
                  multiple
                />
                <Button
                  color="bg-pinflag"
                  onClick={handleUpload}
                  inactive={files.length === 0}
                >
                  Subir archivos
                </Button>
              </div>
            )}
            {step === 1 && (
              <div className="flex h-full flex-col items-center rounded-lg bg-white p-12">
                <img
                  src={uploadError ? errorIcon : BILLING_ICONS.paid}
                  className="mb-4 w-12"
                  alt="success"
                />
                <div className="text-lg font-medium ">
                  {uploadError ? (
                    <span className="flex text-center">
                      Ha ocurrido un error. Inténtelo de nuevo más tarde.
                    </span>
                  ) : (
                    <span className="flex text-center">
                      ¡Tus archivos han sido añadidos a la solicitud de indemnización #{refundId}{' '}
                      con éxito!
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default FileUploader
