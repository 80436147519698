import { React, useState } from 'react'

import { COURIER_PLAN_NAMES } from '../../constants/couriers'
import { PINFLAG_PLAN_NAMES } from '../../constants/general'
import { capitalizeFirstLetter } from '../../utils/strings'
import useFormQuoting from '../../hooks/useFormQuoting'
import ShowQuotingResults from '../shared/ShowQuotingResults'
import { getActiveCouriers } from '../../helpers/couriers'
import useFetch from '../../hooks/useFetch'
import { getCouriersList } from '../../helpers/requests/couriers'
import { getQuoting } from '../../helpers/requests/quoting'

const InformationForm = ({ states }) => {
  const [state, setState] = useState('')
  const [errorAPI, setErrorAPI] = useState(false)
  const [isClickAndCollect, setIsClickAndCollect] = useState(false)
  const [activeCouriers, setActiveCouriers] = useState([])
  useFetch(getCouriersList, setActiveCouriers, '', getActiveCouriers)

  const initialForm = {
    weight: 1,
    courier_plan: '',
    company_charge: 0,
    click_and_collect_discount: 0,
    default_courier: 'blueexpress',
    pinflag_plan: '',
    destination_id: 0,
    courier: '',
    state_fee: {
      fixed: null,
      percentage: 0
    },
    courier_service: 'standard',
    company_key: 'DEFAULT'
  }

  const validationForm = (form) => {
    const errors = {}

    if (form.weight === 0) {
      errors.weight = 'Peso es un campo requerido'
    }
    if (form.courier_plan === '') {
      errors.courier_plan = 'Courier plan es un campo requerido'
    }
    if (form.pinflag_plan === '') {
      errors.pinflag_plan = 'Tamaño empresa es un campo requerido'
    }
    if (form.destination_id === 0) {
      errors.destination_id = 'Comuna es un campo requerido'
    }
    if (form.courier === '') {
      errors.courier = 'Courier es un campo requerido'
    }
    return errors
  }

  const {
    form,
    errors,
    loading,
    response,
    handleChange,
    handleBlur,
    handleSubmit,
    setErrorsForm,
    setForm
  } = useFormQuoting(initialForm, validationForm, getQuoting, setErrorAPI)

  return (
    <div className="flex w-full">
      <div className="m-14 flex w-[60%] flex-col items-center">
        <form className="flex w-full max-w-3xl flex-col" onSubmit={handleSubmit}>
          <div className="flex gap-x-8">
            <div className="flex w-3/6 flex-col">
              <div className="mb-4 flex items-center space-x-4">
                <div className="block w-1/6 text-sm font-bold text-gray-700">Región</div>
                <select
                  className="block w-5/6 rounded border border-light-grey p-2.5 focus:border-dark-grey"
                  name="state"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  required
                >
                  <option value="" disabled hidden>
                    Selecciona una región
                  </option>
                  {Object.values(states).map((currState) => (
                    <option key={currState.stateId} value={currState.stateId}>
                      {currState.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4 grid">
                <div className="flex flex-col">
                  <div className="flex items-center space-x-4">
                    <div className="block w-1/6 text-sm font-bold text-gray-700">Comuna</div>
                    <select
                      className="block w-5/6 rounded border border-light-grey p-2.5 focus:border-dark-grey"
                      name="destination_id"
                      value={form.destination_id}
                      disabled={state === ''}
                      onChange={(e) => handleChange(e)}
                      onBlur={handleBlur}
                      required
                    >
                      <option value={0} disabled hidden>
                        Selecciona una comuna
                      </option>
                      {state &&
                        states[state].cities.map((city) => (
                          <option key={city.id} value={city.id}>
                            {city.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  {errors.destination_id && (
                    <p className="text-sm text-red">{errors.destination_id}</p>
                  )}
                </div>
              </div>
              <div className="mb-4 grid">
                <div className="flex items-center space-x-4">
                  <div className="block w-1/6 text-sm font-bold text-gray-700">Peso (kg)</div>
                  <input
                    className="block w-5/6 rounded border border-light-grey p-2.5 focus:border-dark-grey"
                    type="number"
                    name="weight"
                    value={form.weight}
                    onChange={(e) => handleChange(e)}
                    onBlur={handleBlur}
                    step="1"
                    min="1"
                    max="20"
                  />
                </div>
                {errors.weight && (
                  <p className="w-5/6 justify-self-end text-sm text-red">{errors.weight}</p>
                )}
              </div>
              <div className="mb-4 grid">
                <div className="flex items-center space-x-4">
                  <div className="block w-1/6 text-sm font-bold text-gray-700">Courier</div>
                  <select
                    className="block w-5/6 rounded border border-light-grey p-2.5 focus:border-dark-grey"
                    name="courier"
                    value={form.courier}
                    required
                    onChange={(e) => handleChange(e)}
                    onBlur={handleBlur}
                  >
                    <option value="" disabled hidden>
                      Selecciona un courier
                    </option>
                    {activeCouriers.map((courier) => (
                      <option key={courier.id} value={courier.name}>
                        {capitalizeFirstLetter(courier.name)}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.courier && (
                  <p className="w-5/6 justify-self-end text-sm text-red">{errors.courier}</p>
                )}
              </div>
              <div className="mb-4 grid">
                <div className="flex items-center space-x-4">
                  <div className="block w-1/6 text-sm font-bold text-gray-700">Courier plan</div>
                  <select
                    className="block w-5/6 rounded border border-light-grey p-2.5 focus:border-dark-grey"
                    name="courier_plan"
                    value={form.courier_plan}
                    onChange={(e) => handleChange(e)}
                    onBlur={handleBlur}
                    required
                  >
                    <option value="" disabled hidden>
                      Selecciona un plan
                    </option>
                    {COURIER_PLAN_NAMES.map((plan) => (
                      <option key={plan} value={plan}>
                        {capitalizeFirstLetter(plan)}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.courier_plan && (
                  <p className="w-5/6 justify-self-end text-sm text-red">{errors.courier_plan}</p>
                )}
              </div>
            </div>
            <div className="flex w-4/6 flex-col">
              <div className="mb-4 grid">
                <div className="flex items-center space-x-4">
                  <div className="block w-2/6 text-sm font-bold text-gray-700">Tamaño empresa</div>
                  <select
                    className="block w-5/6 rounded border border-light-grey p-2.5 focus:border-dark-grey"
                    name="pinflag_plan"
                    value={form.pinflag_plan}
                    onChange={(e) => handleChange(e)}
                    onBlur={handleBlur}
                    required
                  >
                    <option value="" disabled hidden>
                      Selecciona un tamaño
                    </option>
                    {PINFLAG_PLAN_NAMES.map((courier) => (
                      <option key={courier} value={courier}>
                        {capitalizeFirstLetter(courier)}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.pinflag_plan && (
                  <p className="w-5/6 justify-self-end text-sm text-red">{errors.pinflag_plan}</p>
                )}
              </div>
              <div className="mb-4 grid">
                <div className="flex items-center space-x-4">
                  <div className="block w-2/6 text-sm font-bold text-gray-700">Tipo Envío</div>
                  <select
                    className="block w-5/6 rounded border border-light-grey p-2.5 focus:border-dark-grey"
                    name="typeShipping"
                    value={isClickAndCollect}
                    onChange={(e) => {
                      const booleanValue = e.target.value === 'true'
                      setIsClickAndCollect(booleanValue)
                    }}
                    required
                  >
                    <option key="clickCollect" value>
                      Click & Collect
                    </option>
                    <option key="homeShipping" value={false}>
                      Domicilio
                    </option>
                  </select>
                </div>
              </div>
              {isClickAndCollect ? (
                <div className="mb-4 flex items-center space-x-4">
                  <div className="block w-2/6 text-sm font-bold text-gray-700">Dcto C&C</div>
                  <input
                    className="block w-5/6 rounded border border-light-grey p-2.5 focus:border-dark-grey"
                    type="number"
                    name="click_and_collect_discount"
                    value={form.click_and_collect_discount}
                    onChange={(e) => {
                      setForm({ ...form, [e.target.name]: parseFloat(e.target.value) })
                    }}
                    onBlur={handleBlur}
                    step="0.01"
                    min="0"
                    max="2"
                    required
                  />
                </div>
              ) : null}
              <div className="mb-4 flex items-center space-x-4">
                <div className="block w-2/6 text-sm font-bold text-gray-700">Pricing Ecommerce</div>
                <input
                  className="block w-5/6 rounded border border-light-grey p-2.5 focus:border-dark-grey"
                  type="number"
                  name="state_fee"
                  value={form.state_fee.percentage}
                  onChange={(e) => {
                    handleChange({
                      target: {
                        value: { fixed: null, percentage: parseFloat(e.target.value) },
                        name: 'state_fee'
                      }
                    })
                  }}
                  onBlur={handleBlur}
                  step="0.01"
                  min="0"
                  max="2"
                />
              </div>
            </div>
          </div>
          <div className="my-8 grid">
            {errorAPI && <p>Hubo un error {response.error}</p>}
            <button
              className="h-10 w-4/12 justify-self-end rounded-md bg-pinflag text-white hover:bg-pinflag-200"
              type="submit"
              onClick={setErrorsForm}
            >
              Cotizar
            </button>
          </div>
        </form>
      </div>
      <ShowQuotingResults response={response} loading={loading} />
    </div>
  )
}

export default InformationForm
