import React from 'react'

import { blackChevron } from '../../constants/icons'
import useClickOutside from '../../hooks/useClickOutside'

const DropDown = ({ name, children, isChecked }) => {
  const [isOpen, setIsOpen, popUpRef] = useClickOutside()

  return (
    <div className="relative z-10 m-2 w-fit text-sm lg:my-auto" ref={popUpRef}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`flex rounded-3xl border-2 px-4 py-1 ${isChecked ? 'border-pinflag-200 bg-light-pinflag' : 'bg-white'}`}
        role="button"
        tabIndex="0"
      >
        {name}
        <img
          src={blackChevron}
          alt={name}
          className={`my-auto ml-2 h-1 w-1.5 transition-all ${isOpen && 'rotate-180'}`}
        />
      </div>
      {isOpen && (
        <div className="absolute left-0 flex w-fit flex-col rounded-xl border-2 bg-white p-4">
          {children}
        </div>
      )}
    </div>
  )
}

export default DropDown
