import React from 'react'
import { PACKAGE_STATUS_TRANSLATE } from '../../../constants/packageStatus'

const StatusOption = ({ changeType, setChangeType, newStatus }) => (
  <button
    className="flex w-full gap-4 rounded border px-4 py-2 text-xs hover:bg-light-grey"
    onClick={() => setChangeType(newStatus)}
    type="button"
  >
    <div
      className={`my-auto rounded-full border p-0.5 ${
        changeType === newStatus ? 'border-green' : 'border-dark-grey'
      }`}
    >
      <div
        className={`h-1.5 w-1.5 rounded-full ${changeType === newStatus ? 'bg-green' : 'bg-white'}`}
      />
    </div>
    {PACKAGE_STATUS_TRANSLATE[newStatus]}
  </button>
)

export default StatusOption
