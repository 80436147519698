import React, { useEffect, useState } from 'react'

import Loader from './Loader'

const ProgressiveImage = ({ src, alt, className }) => {
  const [currentSrc, setCurrentSrc] = useState(null)

  useEffect(() => {
    setCurrentSrc(null)
    const highResImage = new Image()
    highResImage.src = src
    highResImage.onload = () => {
      setCurrentSrc(highResImage.src)
    }
  }, [src])

  if (!currentSrc) {
    return <div className="m-auto"><Loader /></div>
  }

  return (
    <img
      src={currentSrc}
      className={`${className} animate-opacity-appear`}
      alt={alt}
      loading="lazy"
    />
  )
}

export default ProgressiveImage
