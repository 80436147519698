import { React, useState } from 'react'

import InformationForm from '../components/QuotingForms/InformationForm'
import CompanyForm from '../components/QuotingForms/CompanyForm'
import Modal from '../components/shared/Modal/Modal'
import { infoIcon, descriptionImage } from '../constants/icons'
import { getHoldingAgreements } from '../helpers/requests/companies'
import useFetch from '../hooks/useFetch'
import { getStates } from '../helpers/requests/states'

const QuotingPrice = () => {
  const [openInformation, setOpenInformation] = useState(true)
  const [openAuxPopUp, setOpenAuxPopUp] = useState(false)
  const [states, setStates] = useState({})
  const [agreements, setAgreements] = useState([])

  useFetch(getHoldingAgreements, setAgreements)
  useFetch(getStates, setStates)

  const handleClosePopUp = () => {
    setOpenAuxPopUp(false)
  }

  return (
  <div className="m-10">
    <div className="mb-8 text-left text-xl font-semibold">Cotizador</div>
    <hr />
    <div className="m-8 flex items-center justify-center">
      <p className="rounded-sm border border-pinflag p-6">Precio Comprador = Tarifa Base * (1 + Recarga Pinflag) * (1 - Desc. Courier) * (1 - Desc. Tipo Envío) * Pricing Ecommerce</p>
      <div className="mx-5">
        <button
          className="w-8 cursor-pointer"
          type="button"
          onClick={() => setOpenAuxPopUp(true)}
        >
          <img
            src={infoIcon}
            alt="info"
          />
        </button>
      </div>
    </div>
    {openAuxPopUp && (
      <Modal show={openAuxPopUp} handleClose={handleClosePopUp}>
        <div className="grid justify-items-center">
          <img className="w-10/12 pb-6" src={descriptionImage} alt="formula"/>
        </div>
      </Modal>
    )}
    <div className="flex">
      <div className="mt-8 ml-8 flex">
        <input
          type="radio"
          value={openInformation}
          className="mr-2 mt-2 h-4 w-4"
          onChange={() => setOpenInformation(true)}
          checked={openInformation}
        />
        <div className="block text-xl text-gray-700">
          Información
        </div>
      </div>
      <div className="mt-8 ml-8 flex">
        <input
          type="radio"
          value={openInformation}
          className="mr-2 mt-2 h-4 w-4"
          onChange={() => setOpenInformation(false)}
          checked={!openInformation}
        />
        <div className="block text-xl text-gray-700">
          Por empresa
        </div>
      </div>
    </div>
    { openInformation && (
      <InformationForm states={states}/>
    )}
    {!openInformation && (
      <CompanyForm states={states} agreements={agreements} />
    )}
  </div>
  )
}

export default QuotingPrice
