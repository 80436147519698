import React from 'react'
import { FILE_EXTENSION_ICONS, uploadIcon } from '../../../constants/icons'

const FileUpload = ({
  label,
  subText,
  inputId,
  inputRef,
  fetchedFiles,
  file,
  handleChange,
  handleButtonClick,
  handleFileDelete,
  handleFileUpload,
  properties
}) => {
  const onHandleDelete = (type) => {
    handleFileDelete(fetchedFiles, inputId || '', type)
  }
  const trashBinSVG = (type) => (
    <button
      type="button"
      className="flex w-16 items-center justify-center rounded-lg bg-white drop-shadow-md hover:bg-medium-gray"
      onClick={() => onHandleDelete(type)}
    >
      <div className="h-5 w-5">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g strokeWidth="0" />
          <g strokeLinecap="round" strokeLinejoin="round" />
          <g fill="#173554">
            <path d="M12 2.75a2.25 2.25 0 0 0-2.122 1.5.75.75 0 0 1-1.414-.5 3.751 3.751 0 0 1 7.073 0 .75.75 0 0 1-1.415.5A2.25 2.25 0 0 0 12 2.75M2.75 6a.75.75 0 0 1 .75-.75h17a.75.75 0 0 1 0 1.5h-17A.75.75 0 0 1 2.75 6m3.165 2.45a.75.75 0 1 0-1.497.1l.464 6.952c.085 1.282.154 2.318.316 3.132.169.845.455 1.551 1.047 2.104s1.315.793 2.17.904c.822.108 1.86.108 3.146.108h.879c1.285 0 2.324 0 3.146-.108.854-.111 1.578-.35 2.17-.904.591-.553.877-1.26 1.046-2.104.162-.814.23-1.85.316-3.132l.464-6.952a.75.75 0 0 0-1.497-.1l-.46 6.9c-.09 1.347-.154 2.285-.294 2.99-.137.685-.327 1.047-.6 1.303-.274.256-.648.422-1.34.512-.713.093-1.653.095-3.004.095h-.774c-1.35 0-2.29-.002-3.004-.095-.692-.09-1.066-.256-1.34-.512-.273-.256-.463-.618-.6-1.303-.14-.705-.204-1.643-.294-2.99z" />
            <path d="M9.425 10.254a.75.75 0 0 1 .821.671l.5 5a.75.75 0 0 1-1.492.15l-.5-5a.75.75 0 0 1 .671-.821m5.821.821a.75.75 0 0 0-1.492-.15l-.5 5a.75.75 0 0 0 1.492.15z" />
          </g>
        </svg>
      </div>
    </button>
  )

  const onHandleFileUpload = () => handleFileUpload(inputId)

  const saveIconSVG = (
    <button
      type="button"
      className="flex w-16 items-center justify-center rounded-lg bg-white drop-shadow-md hover:bg-medium-gray"
      onClick={() => onHandleFileUpload()}
    >
      <div className="h-4 w-4">
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" stroke="#173554">
          <g strokeWidth="0" />
          <g strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M19 10a1 1 0 0 0 1-1V5a1 1 0 1 0-2 0v4a1 1 0 0 0 1 1m11 18a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h2v10a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2h2a2 2 0 0 1 2 2zM8 2h16v9a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1zm20-2H4a4 4 0 0 0-4 4v24a4 4 0 0 0 4 4h24a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4"
            fillRule="evenodd"
            stroke="none"
          />
        </svg>
      </div>
    </button>
  )

  return (
    <div className="flex w-full gap-2">
      <input
        id={inputId}
        type="file"
        className="hidden"
        ref={inputRef}
        onChange={handleChange}
        {...properties}
      />
      <label
        htmlFor={inputId}
        className="w-full cursor-pointer rounded-lg bg-white drop-shadow-md hover:bg-medium-gray"
      >
        <div className="flex  justify-between ">
          <div className="mx-1 flex items-center">
            <button
              type="button"
              className={`flex h-10 w-10 items-center justify-center gap-2 rounded-lg 
                bg-pinflag-dark font-bold text-white ${
                  properties?.disabled ? 'cursor-not-allowed opacity-70' : 'hover:opacity-70'
                }`}
              onClick={handleButtonClick}
            >
              <img
                src={fetchedFiles?.[0]?.name || file ? FILE_EXTENSION_ICONS.pdf : uploadIcon}
                alt="archivos"
                className={`${
                  fetchedFiles?.[0]?.name || file ? 'h-6' : 'h-3 brightness-200 saturate-0'
                }`}
              />
            </button>
            <div className="ml-1 text-xs">
              <div className="font-semibold">{label}</div>
              <div className="text-dark-grey">{subText}</div>
              <div className="text-dark-grey">
                {fetchedFiles?.[0]?.name || file ? 'Click para reemplazar' : 'Click para subir'}
              </div>
            </div>
          </div>
        </div>
      </label>
      {(fetchedFiles?.[0]?.name && trashBinSVG('url')) ||
        (file && !fetchedFiles?.[0].url && trashBinSVG('local'))}
      {!fetchedFiles?.[0]?.name && file && saveIconSVG}
    </div>
  )
}

export default FileUpload
