import React, { useCallback, useState } from 'react'
import { ArrayParam, NumberParam, useQueryParam } from 'use-query-params'

import useFetch from '../../hooks/useFetch'
import SearchBar from '../../components/shared/SearchBar'
import ActionsBar from '../../components/shared/Table/ActionsBar'
import Pagination from '../../components/shared/Pagination'
import RefundsTable from '../../components/Refunds/AllRefunds/RefundsTable'
import { REFUND_STATUSES } from '../../constants/refunds'
import { sortArrayByKey } from '../../utils/array'
import { getRefunds } from '../../helpers/requests/refunds'
import Button from '../../components/shared/Button'
import { isSubstring } from '../../utils/strings'

const AllRefunds = () => {
  const [refunds, setRefunds] = useState([])
  const [searchBarInput, setSearchBarInput] = useState('')
  const [selectedStatus = [], setSelectedStatus] = useQueryParam('status', ArrayParam)
  const [page = 1, setPage] = useQueryParam('page', NumberParam)
  const [pageSize = 20, setPageSize] = useQueryParam('pageSize', NumberParam)
  const [searchResult, setSearchResult] = useState([])
  const [openCreate, setOpenCreate] = useState(false)

  const setRefundsData = useCallback((data) => {
    const flattenRefunds = data.map((refund) => {
      const item = { ...refund, ...refund.packageId }
      delete item.package
      return item
    })
    flattenRefunds.sort((refundA, refundB) => refundB.id - refundA.id)

    setRefunds(flattenRefunds)
    setSearchResult(flattenRefunds)
  }, [])

  const { isLoading, error } = useFetch(getRefunds, setRefundsData)

  const searchHandler = (searchTerm) => {
    setSearchBarInput(searchTerm)
    if (searchTerm !== '') {
      const filteredRefunds = refunds.filter(
        (refund) =>
          isSubstring(refund.id.toString(), searchTerm) ||
          (refund.pinflagId && isSubstring(refund.pinflagId, searchTerm)) ||
          (refund.trackingId && isSubstring(refund.trackingId, searchTerm)) ||
          (refund.orderId && isSubstring(refund.orderId, searchTerm)) ||
          (refund.companyName && isSubstring(refund.companyName, searchTerm)) ||
          (refund.courierName && isSubstring(refund.courierName, searchTerm)) ||
          (refund.packageStatus && isSubstring(refund.packageStatus, searchTerm)) ||
          (refund.refundStatus && isSubstring(refund.refundStatus, searchTerm))
        )
      setSearchResult(filteredRefunds)
    } else {
      setSearchResult(refunds)
    }
  }

  const handleSort = (sortValues, order) => {
    const sortedcurrentRefunds = sortArrayByKey(searchResult, sortValues, order)

    setSearchResult(sortedcurrentRefunds)
    const sortedRefunds = sortArrayByKey(refunds, sortValues, order)

    setRefunds(sortedRefunds)
  }

  const statusRefunds =
    selectedStatus.length === 0
      ? searchResult
      : searchResult.filter((refund) => selectedStatus.includes(refund.refundStatus))


  const currentRefunds = statusRefunds.slice(
    pageSize * (page - 1), pageSize * page
  )

  return (
    <div className="flex h-screen flex-col bg-light-grey">
      <div className="mx-10 mt-4 flex justify-between border bg-white p-4">
        <div>Indemnizaciones</div>
        <Button color="bg-pinflag" onClick={() => setOpenCreate(true)} small>
          Solicitar indemnización
        </Button>
      </div>
      <ActionsBar
        statusFilters={REFUND_STATUSES}
        selectedFilters={selectedStatus}
        setSelectedFilters={setSelectedStatus}
        rightChildren={
          <SearchBar
            searchbarInput={searchBarInput}
            searchKeyword={searchHandler}
            setSearchbarInput={(search) => {
              setSearchBarInput(search)
            }}
          />
        }
      />
      <RefundsTable
        refunds={currentRefunds}
        handleSort={handleSort}
        isLoading={isLoading}
        error={error}
        openCreate={openCreate}
        setOpenCreate={setOpenCreate}
      />
      <div className="mx-10 flex h-16 justify-between">
        {statusRefunds.length > 0 && (
          <>
            <div className="pt-4 pl-2 text-s font-light">
              {(page - 1) * pageSize + 1} - {(page - 1) * pageSize + currentRefunds.length} de{' '}
              {statusRefunds.length}
            </div>
            <Pagination
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              itemsCount={statusRefunds.length}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default AllRefunds
