import apiClient from '../../axiosConfig/apiClient'
import courierClient from '../../axiosConfig/courierClient'
import { APPROVE_COMPANY, ASSIGN_COURIER_SERVICE, COURIERS_DATA_URL, COURIER_SERVICES } from '../../constants/endpointUrls/api'
import {
  COURIER_PRICES_DETAIL_URL,
  COURIER_PRICES_URL,
  COURIER_TIMES_URL,
  DISCOUNT_URL,
  RECHARGE_URL
} from '../../constants/endpointUrls/courier'

export const getCouriersList = async () => {
  const response = await apiClient.get(COURIERS_DATA_URL)

  return response.data
}

export const getCourierPrices = async (courierService, courier, weight) => {
  const response = await courierClient.get(COURIER_PRICES_URL, {
    params: { courierService, courier, weight }
  })

  return response.data
}

export const getCourierPricesDetail = async (courierService, courier, stateId) => {
  const response = await courierClient.get(COURIER_PRICES_DETAIL_URL, {
    params: { courierService, courier, stateId }
  })

  return response.data
}

export const getCourierTimes = async (params) => {
  const response = await courierClient.get(`${COURIER_TIMES_URL}${params}`)

  return response.data
}

export const getDiscountMatrix = async () => {
  const response = await courierClient.get(DISCOUNT_URL)

  return response.data
}

export const getRechargeMatrix = async (params) => {
  const response = await courierClient.get(RECHARGE_URL, params)

  return response.data
}

export const updateCourierPrices = async (body) => {
  await courierClient.put(COURIER_PRICES_URL, body)
}

export const updateCourierTimes = async (body) => {
  await courierClient.put(COURIER_TIMES_URL, body)
}

export const updateRechargeMatrix = (body) => courierClient.patch(RECHARGE_URL, body)

export const updateDiscountMatrix = (body) => courierClient.patch(DISCOUNT_URL, body)

export const getCourierServices = async () => {
  const response = await apiClient.get(COURIER_SERVICES)

  return response.data
}

export const assignCourierService = (body) => apiClient.post(ASSIGN_COURIER_SERVICE , body)

export const approveCourierCompany =
  (companyId, body) => apiClient.post(APPROVE_COMPANY(companyId), body)
