import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'

import useFetch from '../../../hooks/useFetch'
import { getNotificationLogs } from '../../../helpers/requests/notificationCenter'
import { getFormattedDateWithoutTimezone } from '../../../utils/dates'
import TableRow from '../../shared/Table/TableRow'
import Table from '../../shared/Table/Table'
import Pagination from '../../shared/Pagination'
import { getHoldings } from '../../../helpers/requests/companies'
import Filters from '../../shared/Filters/Filters'
import Loader from '../../shared/Loader'

const NotificationLogs = ({ notificationId }) => {
  const [filterOptions, setFilterOptions] = useState([])
  const [finalDate, setFinalDate] = useState(moment().format('YYYY-MM-DD'))
  const [initialDate, setInitialDate] = useState(
    moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
  )
  const [isFiltered, setIsFiltered] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [logsCount, setLogsCount] = useState(0)
  const [notificationLog, setNotificationLog] = useState([])
  const [page, setPage] = useState(1)
  const [selectedData, setSelectedData] = useState({})
  const [shouldFetch, setShouldFetch] = useState(false)

  const initializeNotificationsData = useCallback((data) => {
    const { billingNotificationLogs, count } = data
    setNotificationLog(billingNotificationLogs)
    setLogsCount(count)
  }, [])

  const initializeHoldings = useCallback((holdings) => {
    setFilterOptions({ clientes: holdings.map((holding) => holding.name) })
  }, [])

  useFetch(
    getNotificationLogs,
    initializeNotificationsData,
    `?notificationId=${notificationId}&page=${page}&pageSize=${10}`
  )

  useFetch(getHoldings, initializeHoldings)

  useEffect(() => {
    const filtersURI = () => {
      const response =
        Object.keys(selectedData).length > 0 ? encodeURIComponent(JSON.stringify(selectedData)) : ''
      return response
    }
    if (shouldFetch) {
      setIsLoading(true)
      getNotificationLogs(
        `?notificationId=${notificationId}&page=${page}&pageSize=${10}&holdings=${filtersURI(
          selectedData
        )}&&startDate=${initialDate}&finalDate=${finalDate}`
      )
        .then(initializeNotificationsData)
        .finally(() => {
          setShouldFetch(false)
          setIsLoading(false)
        })
    }
  }, [
    finalDate,
    initialDate,
    initializeNotificationsData,
    notificationId,
    page,
    selectedData,
    shouldFetch
  ])

  const handleFilterSubmit = () => {
    setShouldFetch(true)
    setIsFiltered(true)
    setPage(1)
  }

  const handleFilterClean = () => {
    setSelectedData({})
    setInitialDate(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'))
    setFinalDate(moment().format('YYYY-MM-DD'))
    setIsFiltered(false)
    setShouldFetch(true)
    setPage(1)
  }

  return (
    <div className="relative flex h-screen flex-col bg-light-grey">
      <div className="mx-10 flex justify-end gap-1 rounded-t border-x border-t bg-white px-3 py-2">
        <Filters
          filterOptions={filterOptions}
          selectedCouriers={selectedData}
          setSelectedCouriers={setSelectedData}
          today={moment().format('YYYY-MM-DD')}
          initialDate={initialDate}
          finalDate={finalDate}
          setInitialDate={setInitialDate}
          setFinalDate={setFinalDate}
          handleSubmit={handleFilterSubmit}
          handleClean={handleFilterClean}
          isFiltered={isFiltered}
        />
      </div>
      {isLoading && <Loader />}
      <Table headers={['Nombre de cliente', 'Fecha de notificación', 'N° Factura']}>
        {!isLoading &&
          notificationLog?.map((log) => (
            <TableRow
              key={log.id}
              onClick={() => {}}
              items={[
                <div className="flex h-9 w-48 items-center text-base">
                  {log.holdingName || '-'}
                </div>,
                <div className="flex h-9 w-48 items-center text-base">
                  {getFormattedDateWithoutTimezone(log.createdAt) || '-'}
                </div>,
                <div className="flex h-9 w-48 items-center text-base">
                  {log.invoiceNumber || '-'}
                </div>
              ]}
            />
          ))}
      </Table>
      <div className="mx-10 flex h-24 place-content-end">
        <Pagination
          page={page}
          setPage={setPage}
          pageSize={10}
          setPageSize={() => {}}
          itemsCount={logsCount}
        />
      </div>
    </div>
  )
}

export default NotificationLogs
