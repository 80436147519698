import React from 'react'

const SelectProducts = ({ products, selectedProducts, setSelectedProducts }) => {

  const handleChangeQuantity = (value, product) => {
    const number = parseInt(value, 10)

    if (number === 0) {
      const newProductsList = { ... selectedProducts}

      delete newProductsList[product.sku]
      setSelectedProducts(newProductsList)
    } else{
      setSelectedProducts({
        ...selectedProducts,
        [product.sku]: {...product, quantity: number}
      })
    }
  }

  return (
    <div className="flex flex-col gap-2">
      {products.map((product) => (
        <div key={product.sku} className="my-4 flex w-72 gap-3 rounded border p-2 text-s">
          {product.photoUrl.length > 0 && product.photoUrl[0].URLIMAGEN ? (
            <img
              src={product.photoUrl[0].URLIMAGEN}
              alt={product.productName}
              className="h-auto max-h-[6rem] w-auto max-w-[6rem]"
            />
          ) : (
            <div className="h-24 w-24 bg-semi-light-grey" />
          )}
          <div>
            <div className="font-medium">{product.productName}</div>
            <div>SKU: {product.sku}</div>
            <div>Precio: {product.price}</div>
            Cantidad:
            <input
              max={product.quantity}
              min="0"
              type="number"
              className="mx-1 w-8 rounded border pl-1"
              value={selectedProducts[product.sku]?.quantity || 0}
              onChange={(event) => handleChangeQuantity(event.target.value, product)}
            />
            de {product.quantity}
          </div>
        </div>
      ))}
    </div>
  )
}

export default SelectProducts
