import apiClient from '../../axiosConfig/apiClient'
import opsClient from '../../axiosConfig/opsClient'
import {
  PROCESS_PACKAGES_URL,
  HANDLE_PACKAGES_URL,
  HANDLE_BILLS_URL,
  HANDLE_FILES_URL_OPS,
  HANDLE_MISSING_PACKAGES_URL
} from '../../constants/endpointUrls/api'

export const savePackagesToDB = async (body) => {
  const response = await apiClient.post(PROCESS_PACKAGES_URL, body)
  return response.data
}

export const processOrders = async (body) => {
  const response = await apiClient.post(PROCESS_PACKAGES_URL, body)
  return response.data
}

export const savePackages = async (body) => {
  const { data, status } = await opsClient.post(HANDLE_PACKAGES_URL, body)
  return { data, status }
}

export const updatePackages = async (body) => {
  const { data, status } = await opsClient.put(HANDLE_PACKAGES_URL, body)
  return { data, status }
}

export const getBilledPackages = async (queryParams) => {
  const response = await opsClient.get(`${HANDLE_PACKAGES_URL}${queryParams}`)
  return response.data
}

export const getMissingPackages = async (queryParams) => {
  const response = await opsClient.get(`${HANDLE_MISSING_PACKAGES_URL}${queryParams}`)
  return response.data
}

export const updateMissingPackages = async (body) => {
  const { data, status } = await opsClient.put(HANDLE_MISSING_PACKAGES_URL, body)
  return { data, status }
}

export const createBills = async (body) => {
  const { data, status } = await opsClient.post(HANDLE_BILLS_URL, body)
  return { data, status }
}

export const getBills = async (queryParams) => {
  const response = await opsClient.get(
    `${HANDLE_BILLS_URL}${queryParams}`
  )
  return response.data
}

export const updateBills = async (body) => {
  const { data, status } = await opsClient.put(HANDLE_BILLS_URL, body)
  return { data, status }
}

export const getBillFile = async (fileName) => {
  const { data, status } = await opsClient.get(`${HANDLE_FILES_URL_OPS}?fileName=${fileName}`)
  return { data, status }
}

export const uploadFile = async (body) => {
  const { data, status } = await opsClient.post(HANDLE_FILES_URL_OPS, body)
  return { data, status }
}

export const removeFile = async (body) => {
  const { data, status } = await opsClient.delete(`${HANDLE_FILES_URL_OPS}?fileName=${body}`)
  return { data, status }
}
