/* eslint-disable react/jsx-props-no-spreading */
import { React, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  CMS_TYPES,
  CHECKOUT_OPTIONS,
  DEFAULT_COURIERS
} from '../../../constants/registerCompany/registerOptions'
import { capitalizeFirstLetter } from '../../../utils/strings'
import {
  holdingDataValidations,
  userDataValidations,
  holdingValidations,
  companyDataValidations,
  cmsValidations
} from '../../../constants/registerCompany/registrationValidations'

import { signUp } from '../../../helpers/requests/signUpCompany'

import ConfirmSignUpCompanyModal from './ConfirmSignUpCompanyModal'

const RegisterForm = ({ states, agreements, setCurrentStep, setCompanyData, companyData }) => {
  const countryCode = 56
  const [state, setState] = useState('')
  const [haveHolding, setHaveHolding] = useState(false)
  const [needCMS, setNeedCMS] = useState(false)
  const [companyType, setCompanyType] = useState('')
  const [cms, setCms] = useState('')
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur'
  })
  const [showModal, setShowModal] = useState(false)
  const [errorAPI, setErrorAPI] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorList, setErrorList] = useState([])
  const [errorMessage, setErrorMessage] = useState('')

  const signUpCompany = async (data) => {
    if (companyData.length) setCurrentStep(1)
    else {
      setErrorAPI(false)
      setLoading(true)
      setShowModal(true)
      await signUp(data, setErrorAPI, setCompanyData, setErrorList, setErrorMessage)
      setLoading(false)
    }
  }

  const onSubmit = (data) => {
    const finalData = {
      companiesData: [{ ...data.companyData, companyType, cmsData: data.cmsData }]
    }

    if (needCMS) finalData.companiesData[0].cmsData.cms = cms

    if (haveHolding) finalData.holdingId = data.holdingId
    else {
      finalData.userData = data.userData
      finalData.holdingData = data.holdingData
    }

    signUpCompany(finalData)
  }

  const handleErrors = () => {}

  const handleNeedCMS = (value) => {
    if (value === 'ecommerce') setNeedCMS(true)
    if (value === 'bodega') setNeedCMS(false)
    setCompanyType(value)
  }

  return (
    <div className="m-auto flex w-full max-w-2xl flex-col items-center">
      <h2 className="mt-10 mb-7 text-xl font-bold text-sky-600">Registrar Empresa</h2>
      <div className="flex">
        <div className="my-8 flex">
          <input
            type="radio"
            value={haveHolding}
            className="mr-2 mt-1 h-4 w-4"
            onChange={() => setHaveHolding(true)}
            checked={haveHolding}
          />
          <div className="block text-gray-700">Holding existente</div>
        </div>
        <div className="mt-8 ml-8 flex">
          <input
            type="radio"
            value={haveHolding}
            className="mr-2 mt-1 h-4 w-4"
            onChange={() => setHaveHolding(false)}
            checked={!haveHolding}
          />
          <div className="block text-gray-700">Holding Nuevo</div>
        </div>
      </div>
      <form className="w-full max-w-2xl" onSubmit={handleSubmit(onSubmit, handleErrors)}>
        <div className="flex flex-col">
          {!haveHolding ? (
            <div className="-mx-3 mb-6 flex flex-wrap">
              <div className="w-6/12 p-3 ">
                <div className="text-left text-sm">Email</div>
                <input
                  className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                  type="email"
                  name="email"
                  placeholder="Email"
                  {...register('userData.email', userDataValidations.email)}
                />
                {errors.userData?.email && (
                  <p className="text-sm text-red">{errors.userData.email.message}</p>
                )}
              </div>
              <div className="w-6/12 p-3">
                <div className="text-left text-sm">Contraseña</div>
                <input
                  className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                  type="password"
                  name="password"
                  placeholder="Contraseña"
                  {...register('userData.password', userDataValidations.password)}
                />
                {errors.userData?.password && (
                  <p className="text-sm text-red">{errors.userData.password.message}</p>
                )}
              </div>
              <div className="w-6/12 p-3">
                <div className="text-left text-sm">Nombre Holding</div>
                <input
                  className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                  type="text"
                  name="holdingName"
                  placeholder="Nombre Holding"
                  {...register('holdingData.name', holdingDataValidations.name)}
                />
                {errors.holdingData?.name && (
                  <p className="text-sm text-red">{errors.holdingData.name.message}</p>
                )}
              </div>
              <div className="w-6/12 p-3">
                <div className="text-left text-sm">Courier por defecto</div>
                <select
                  className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                  name="defaultCourier"
                  {...register('holdingData.defaultCourier', holdingDataValidations.defaultCourier)}
                >
                  <option value="" disabled hidden>
                    Selecciona una opción
                  </option>
                  {DEFAULT_COURIERS.map((courier) => (
                    <option key={courier} value={courier}>
                      {capitalizeFirstLetter(courier)}
                    </option>
                  ))}
                </select>
                {errors.holdingData?.defaultCourier && (
                  <p className="text-sm text-red">{errors.holdingData.defaultCourier.message}</p>
                )}
              </div>
              <div className="w-6/12 p-3">
                <div className="text-left text-sm">Peso por defecto (kg)</div>
                <input
                  className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                  type="number"
                  min={0}
                  name="defaultWeight"
                  placeholder="Peso por defecto"
                  {...register('holdingData.defaultWeight', holdingDataValidations.defaultWeight)}
                />
                {errors.holdingData?.defaultWeight && (
                  <p className="text-sm text-red">{errors.holdingData.defaultWeight.message}</p>
                )}
              </div>
              <div className="w-6/12 p-3 ">
                <div className="text-left text-sm">Nombre de contacto</div>
                <input
                  className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                  type="text"
                  name="contactName"
                  placeholder="Nombre de contacto"
                  {...register('userData.contactName')}
                />
              </div>
              <div className="w-6/12 p-3 ">
                <div className="text-left text-sm">Email de contacto</div>
                <div className="flex w-full">
                  <input
                    className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey "
                    type="text"
                    name="contactEmail"
                    placeholder="Email de contacto"
                    {...register('userData.contactEmail')}
                  />
                </div>
              </div>
              <div className="w-6/12 p-3 ">
                <div className="text-left text-sm">Número de contacto</div>
                <div className="flex w-full">
                  <div className="flex items-center border border-solid bg-light-grey">
                    <span className="p-1">+{countryCode}</span>
                  </div>
                  <input
                    className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey "
                    type="text"
                    name="contactPhone"
                    placeholder="Número de contacto"
                    {...register('userData.contactPhone')}
                  />
                </div>
              </div>
              <div className="w-full p-3">
                <div className="text-left text-sm">Dirección centro de distribución</div>
                <input
                  className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                  type="text"
                  name="distributionCenterAddress"
                  placeholder="Calle y número"
                  {...register(
                    'holdingData.distributionCenterAddress',
                    holdingDataValidations.distributionCenterAddress
                  )}
                />
                {errors.holdingData?.distributionCenterAddress && (
                  <p className="text-sm text-red">
                    {errors.holdingData.distributionCenterAddress.message}
                  </p>
                )}
              </div>
              <div className="w-6/12 p-3">
                <div className="text-left text-sm">Región centro de distribución</div>
                <select
                  className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  required
                >
                  <option hidden value="" disabled>
                    Selecciona una opción
                  </option>
                  {Object.values(states).map((currentState) => (
                    <option key={currentState.stateId} value={currentState.stateId}>
                      {currentState.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-6/12 p-3">
                <div className="text-left text-sm">Comuna centro de distribución</div>
                <select
                  className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                  name="distributionCenterCityId"
                  disabled={!state}
                  {...register(
                    'holdingData.distributionCenterCityId',
                    holdingDataValidations.distributionCenterCityId
                  )}
                >
                  <option hidden value="0">
                    Selecciona una opción
                  </option>
                  {state &&
                    states[state].cities.map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    ))}
                </select>
                {errors.holdingData?.distributionCenterCityId && (
                  <p className="text-sm text-red">
                    {errors.holdingData.distributionCenterCityId.message}
                  </p>
                )}
              </div>
            </div>
          ) : (
            <div className="-mx-3 mb-6 flex flex-wrap">
              <div className="w-6/12 p-3">
                <div className="text-left text-sm">Holding</div>
                <select
                  className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                  name="holdingId"
                  {...register('holdingId', holdingValidations.holdingId)}
                >
                  <option value="" disabled hidden>
                    Selecciona una opción
                  </option>
                  {agreements.map((agreement) => (
                    <option key={agreement.holdingId} value={agreement.holdingId}>
                      {capitalizeFirstLetter(agreement.name)}
                    </option>
                  ))}
                </select>
                {errors.holdingId && <p className="text-sm text-red">{errors.holdingId.message}</p>}
              </div>
            </div>
          )}
          <div className="flex flex-col">
            <div className="self-start">
              <p className="text-lg">Datos empresa</p>
            </div>
            <div className="-mx-3 mb-6 flex flex-wrap">
              <div className="w-6/12 p-3">
                <div className="text-left text-sm">Giro</div>
                <input
                  className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                  type="text"
                  name="businessLine"
                  placeholder="Giro"
                  {...register('companyData.businessLine', companyDataValidations.businessLine)}
                />
                {errors.companyData?.businessLine && (
                  <p className="text-sm text-red">{errors.companyData.businessLine.message}</p>
                )}
              </div>
              <div className="w-6/12 p-3">
                <div className="text-left text-sm">Razón Social</div>
                <input
                  className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                  type="text"
                  name="businessName"
                  placeholder="Razón Social"
                  {...register('companyData.businessName', companyDataValidations.businessName)}
                />
                {errors.companyData?.businessName && (
                  <p className="text-sm text-red">{errors.companyData.businessName.message}</p>
                )}
              </div>
              <div className="w-6/12 p-3">
                <div className="text-left text-sm">Tipo de empresa</div>
                <select
                  className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                  value={companyType}
                  onChange={(e) => handleNeedCMS(e.target.value)}
                >
                  <option value="" disabled hidden>
                    Selecciona una opción
                  </option>
                  <option key="ecommerce" value="ecommerce">
                    E-commerce
                  </option>
                  <option key="bodega" value="bodega">
                    Bodega
                  </option>
                </select>
                {companyType === '' && (
                  <p className="text-sm text-red">El campo Tipo de compañía es requerido</p>
                )}
              </div>
              <div className="w-6/12 p-3">
                <div className="text-left text-sm">Nombre empresa</div>
                <input
                  className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                  type="text"
                  name="name"
                  placeholder="Nombre empresa"
                  {...register('companyData.name', companyDataValidations.name)}
                />
                {errors.companyData?.name && (
                  <p className="text-sm text-red">{errors.companyData.name.message}</p>
                )}
              </div>
              <div className="w-6/12 p-3">
                <div className="text-left text-sm">Teléfono de la empresa</div>
                <div className="flex w-full">
                  <div className="flex items-center border border-solid bg-light-grey">
                    <span className="p-1">+{countryCode}</span>
                  </div>
                  <input
                    className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey "
                    type="text"
                    name="phone"
                    placeholder="Teléfono contacto"
                    {...register('companyData.phone', companyDataValidations.phone)}
                  />
                </div>
                {errors.companyData?.phone && (
                  <p className="text-sm text-red">{errors.companyData.phone.message}</p>
                )}
              </div>
              <div className="w-6/12 p-3">
                <div className="text-left text-sm">RUT</div>
                <input
                  className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                  type="text"
                  name="rut"
                  placeholder="Sin puntos y con guión"
                  {...register('companyData.rut', companyDataValidations.rut)}
                />
                {errors.companyData?.rut && (
                  <p className="text-sm text-red">{errors.companyData.rut.message}</p>
                )}
              </div>
            </div>
          </div>
          {needCMS && (
            <div className="flex flex-col">
              <div className="self-start">
                <p className="text-lg">Datos CMS</p>
              </div>
              <div className="-mx-3 mb-6 flex flex-wrap">
                <div className="w-6/12 p-3">
                  <div className="text-left text-sm">CMS</div>
                  <select
                    className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                    name="cms"
                    value={cms}
                    onChange={(e) => setCms(e.target.value)}
                  >
                    <option value="" disabled hidden>
                      Selecciona una opción
                    </option>
                    {CMS_TYPES.map((currentCms) => (
                      <option value={currentCms} key={currentCms}>
                        {capitalizeFirstLetter(currentCms)}
                      </option>
                    ))}
                  </select>
                  {cms === '' && <p className="text-sm text-red">El campo CMS es requerido</p>}
                </div>
                {cms === 'shopify' && (
                  <div className="mb-6 flex flex-wrap">
                    <div className="w-6/12 p-3">
                      <div className="text-left text-sm">Access Token</div>
                      <input
                        className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                        type="text"
                        name="accessToken"
                        placeholder="Access Token"
                        {...register('cmsData.accessToken', cmsValidations.accessToken)}
                      />
                      {errors.cmsData?.accessToken && (
                        <p className="text-sm text-red">{errors.cmsData.accessToken.message}</p>
                      )}
                    </div>
                    <div className="w-6/12 p-3">
                      <div className="text-left text-sm">Storefront Token</div>
                      <input
                        className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                        type="text"
                        name="storefrontToken"
                        placeholder="Storefront Token"
                        {...register('cmsData.storefrontToken', cmsValidations.storefrontToken)}
                      />
                      {errors.cmsData?.storefrontToken && (
                        <p className="text-sm text-red">{errors.cmsData.storefrontToken.message}</p>
                      )}
                    </div>
                    <div className="w-6/12 p-3">
                      <div className="text-left text-sm">Host name</div>
                      <input
                        className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                        type="text"
                        name="hostname"
                        placeholder="Host Name"
                        {...register('cmsData.hostname', cmsValidations.hostname)}
                      />
                      {errors.cmsData?.hostname && (
                        <p className="text-sm text-red">{errors.cmsData.hostname.message}</p>
                      )}
                    </div>
                    <div className="w-6/12 p-3">
                      <div className="text-left text-sm">Secret Web token</div>
                      <input
                        className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                        type="text"
                        name="secretWebhookToken"
                        placeholder="Web Token"
                        {...register(
                          'cmsData.secretWebhookToken',
                          cmsValidations.secretWebhookToken
                        )}
                      />
                      {errors.cmsData?.secretWebhookToken && (
                        <p className="text-sm text-red">
                          {errors.cmsData.secretWebhookToken.message}
                        </p>
                      )}
                    </div>
                    <div className="w-6/12 p-3">
                      <div className="text-left text-sm">Nombre campo comuna</div>
                      <select
                        className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                        name="cityCheckoutField"
                        {...register('cmsData.cityCheckoutField', cmsValidations.cityCheckoutField)}
                      >
                        <option value="" disabled hidden>
                          Selecciona una opción
                        </option>
                        {CHECKOUT_OPTIONS.map((name) => (
                          <option key={name} value={name}>
                            {name}
                          </option>
                        ))}
                      </select>
                      {errors.cmsData?.cityCheckoutField && (
                        <p className="text-sm text-red">
                          {errors.cmsData.cityCheckoutField.message}
                        </p>
                      )}
                    </div>
                    <div className="w-6/12 p-3">
                      <div className="text-left text-sm">Nombre campo calle</div>
                      <select
                        className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                        name="streetNameCheckoutField"
                        {...register(
                          'cmsData.streetNameCheckoutField',
                          cmsValidations.streetNameCheckoutField
                        )}
                      >
                        <option value="" disabled hidden>
                          Selecciona una opción
                        </option>
                        {CHECKOUT_OPTIONS.map((name) => (
                          <option key={name} value={name}>
                            {name}
                          </option>
                        ))}
                      </select>
                      {errors.cmsData?.streetNameCheckoutField && (
                        <p className="text-sm text-red">
                          {errors.cmsData.streetNameCheckoutField.message}
                        </p>
                      )}
                    </div>
                    <div className="w-6/12 p-3">
                      <div className="text-left text-sm">Nombre campo número dirección</div>
                      <select
                        className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                        name="buildingNumberCheckoutField"
                        {...register(
                          'cmsData.buildingNumberCheckoutField',
                          errors.buildingNumberCheckoutField
                        )}
                      >
                        <option value="" disabled hidden>
                          Selecciona una opción
                        </option>
                        {CHECKOUT_OPTIONS.map((name) => (
                          <option key={name} value={name}>
                            {name}
                          </option>
                        ))}
                      </select>
                      {errors.cmsData?.buildingNumberCheckoutField && (
                        <p className="text-sm text-red">
                          {errors.cmsData.buildingNumberCheckoutField.message}
                        </p>
                      )}
                    </div>
                    <div className="w-6/12 p-3">
                      <div className="text-left text-sm">
                        Nombre campo instrucciones de despacho
                      </div>
                      <select
                        className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                        name="deliveryInstructionsCheckoutField"
                        {...register(
                          'cmsData.deliveryInstructionsCheckoutField',
                          cmsValidations.deliveryInstructionsCheckoutField
                        )}
                      >
                        <option value="" disabled hidden>
                          Selecciona una opción
                        </option>
                        {CHECKOUT_OPTIONS.map((name) => (
                          <option key={name} value={name}>
                            {name}
                          </option>
                        ))}
                      </select>
                      {errors.cmsData?.deliveryInstructionsCheckoutField && (
                        <p className="text-sm text-red">
                          {errors.cmsData.deliveryInstructionsCheckoutField.message}
                        </p>
                      )}
                    </div>
                  </div>
                )}
                {cms === 'magento' && (
                  <div className="mb-6 flex flex-wrap">
                    <div className="w-6/12 p-3">
                      <div className="text-left text-sm">Url del comercio</div>
                      <input
                        className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                        type="text"
                        name="commerceUrl"
                        placeholder="Url del comercio"
                        {...register('cmsData.commerceUrl', cmsValidations.commerceUrl)}
                      />
                      {errors.cmsData?.commerceUrl && (
                        <p className="text-sm text-red">{errors.cmsData.commerceUrl.message}</p>
                      )}
                    </div>
                    <div className="w-6/12 p-3">
                      <div className="text-left text-sm">Api Url</div>
                      <input
                        className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                        type="text"
                        name="apiUrl"
                        placeholder="Api Url"
                        {...register('cmsData.apiUrl', cmsValidations.apiUrl)}
                      />
                      {errors.cmsData?.apiUrl && (
                        <p className="text-sm text-red">{errors.cmsData.apiUrl.message}</p>
                      )}
                    </div>
                    <div className="w-6/12 p-3">
                      <div className="text-left text-sm">Username</div>
                      <input
                        className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                        type="text"
                        name="username"
                        placeholder="Username"
                        {...register('cmsData.username', cmsValidations.username)}
                      />
                      {errors.cmsData?.username && (
                        <p className="text-sm text-red">{errors.cmsData.username.message}</p>
                      )}
                    </div>
                    <div className="w-6/12 p-3">
                      <div className="text-left text-sm">Password</div>
                      <input
                        className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                        type="text"
                        name="password"
                        placeholder="Password"
                        {...register('cmsData.password', cmsValidations.password)}
                      />
                      {errors.cmsData?.password && (
                        <p className="text-sm text-red">{errors.cmsData.password.message}</p>
                      )}
                    </div>
                  </div>
                )}
                {cms === 'vtex' && (
                  <div className="mb-6 flex flex-wrap">
                    <div className="w-6/12 p-3">
                      <div className="text-left text-sm">App token</div>
                      <input
                        className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                        type="text"
                        name="appToken"
                        placeholder="App Token"
                        {...register('cmsData.appToken', cmsValidations.appToken)}
                      />
                      {errors.cmsData?.appToken && (
                        <p className="text-sm text-red">{errors.cmsData.appToken.message}</p>
                      )}
                    </div>
                    <div className="w-6/12 p-3">
                      <div className="text-left text-sm">App key</div>
                      <input
                        className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                        type="text"
                        name="appKey"
                        placeholder="App Key"
                        {...register('cmsData.appKey', cmsValidations.appKey)}
                      />
                      {errors.cmsData?.appKey && (
                        <p className="text-sm text-red">{errors.cmsData.appKey.message}</p>
                      )}
                    </div>
                    <div className="w-6/12 p-3">
                      <div className="text-left text-sm">Nombre de la cuenta</div>
                      <input
                        className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                        type="text"
                        name="accountName"
                        placeholder="Nombre de la cuenta"
                        {...register('cmsData.accountName', cmsValidations.accountName)}
                      />
                      {errors.cmsData?.accountName && (
                        <p className="text-sm text-red">{errors.cmsData.accountName.message}</p>
                      )}
                    </div>
                  </div>
                )}
                {cms === 'woocommerce' && (
                  <div className="mb-6 flex flex-wrap">
                    <div className="w-6/12 p-3">
                      <div className="text-left text-sm">Url del comercio</div>
                      <input
                        className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                        type="text"
                        name="commerceUrl"
                        placeholder="Url del comercio"
                        {...register('cmsData.commerceUrl', cmsValidations.commerceUrl)}
                      />
                      {errors.cmsData?.commerceUrl && (
                        <p className="text-sm text-red">{errors.cmsData.commerceUrl.message}</p>
                      )}
                    </div>
                    <div className="w-6/12 p-3">
                      <div className="text-left text-sm">Consumer Key</div>
                      <input
                        className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                        type="text"
                        name="consumerKey"
                        placeholder="Consumer Key"
                        {...register('cmsData.consumerKey', cmsValidations.consumerKey)}
                      />
                      {errors.cmsData?.consumerKey && (
                        <p className="text-sm text-red">{errors.cmsData.consumerKey.message}</p>
                      )}
                    </div>
                    <div className="w-6/12 p-3">
                      <div className="text-left text-sm">Consumer Secret</div>
                      <input
                        className="block w-full rounded border border-normal-grey p-2.5 focus:border-dark-grey"
                        type="text"
                        name="consumerSecret"
                        placeholder="Consumer Secret"
                        {...register('cmsData.consumerSecret', cmsValidations.consumerSecret)}
                      />
                      {errors.cmsData?.consumerSecret && (
                        <p className="text-sm text-red">{errors.cmsData.consumerSecret.message}</p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="my-8 flex justify-end">
          <button
            className="mx-5 h-10 w-1/5 rounded-lg bg-pinflag px-5 text-white hover:bg-pinflag-200"
            type="submit"
          >
            Continuar
          </button>
        </div>
      </form>
      <ConfirmSignUpCompanyModal
        show={showModal}
        loading={loading}
        response={!!companyData.length}
        error={errorAPI}
        errorList={errorList}
        errorMessage={errorMessage}
        handleClose={() => setShowModal(false)}
      />
    </div>
  )
}

export default RegisterForm
