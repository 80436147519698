import { SLA_BY_DELIVERY_TYPE } from '../../constants/stats'

export const parseSLAByDeliveryType = (SLAByDeliveryType) =>
  SLA_BY_DELIVERY_TYPE.map((deliveryType) => [
    {
      name: deliveryType.name,
      value: parseInt(SLAByDeliveryType[deliveryType.paramName] * 100, 10),
      percentage: `${(SLAByDeliveryType[deliveryType.paramName] * 100).toFixed(2)}%`,
      fill: deliveryType.fill
    }
  ])

export const parseSLAByCourier = (SLAByCourier) =>
  Object.entries(SLAByCourier).map(([courier, SLA]) => ({
    name: courier,
    value: SLA * 100,
    percentage: `${(SLA * 100).toFixed(2)}%`
  }))

export const parseSLABySection = (SLABySection) =>
  Object.values(SLABySection).map((SLA, index) => ({
    name: `Tramo ${index + 1}`,
    value: SLA * 100,
    percentage: `${(SLA * 100).toFixed(2) || 0}%`
  }))

export const getSum = (stat) => (
  Object.values(stat).reduce(
    (sum, average) => sum + average
  ).toFixed(2)
)
