import React from 'react'

import { getFormattedDate } from '../../../utils/dates'

const IncidentsTable = ({ incidents }) => (
  <div className="overflow-hidden rounded-xl text-xs">
    <table>
      <tr>
        <td>
          <table className="w-full">
            <thead className="bg-dark-background text-sm">
              <tr>
                <th className="py-3 font-medium">PinfagId</th>
                <th className="py-3 font-medium">O. de transporte</th>
                <th className="py-3 px-8 font-medium">Descripción</th>
                <th className="py-3 pr-4 font-medium">Fecha</th>
              </tr>
            </thead>
          </table>
        </td>
      </tr>
      <tr>
        <td>
          <div className="max-h-44 overflow-y-scroll">
            <table>
              <tbody className="bg-white">
                {incidents.map((incident) => (
                  <tr key={incident.packageId}>
                    <td className="py-2.5 px-3">{incident.pinflagId}</td>
                    <td className="py-2.5 px-3">{incident.trackingId}</td>
                    <td className="py-2.5 px-3">{incident.statusDescription}</td>
                    <td className="py-2.5 px-3">{getFormattedDate(incident.createdAt)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
        </td>
      </tr>
    </table>
  </div>
)

export default IncidentsTable
