import React, { useState } from 'react'

import { getHoldingAgreements } from '../../../helpers/requests/companies'
import { getCourierServices } from '../../../helpers/requests/couriers'
import { getStates } from '../../../helpers/requests/states'
import useFetch from '../../../hooks/useFetch'
import Modal from '../../shared/Modal/Modal'
import CourierPointSelection from '../CourierPointsSelection/CourierPointSelection'
import CourierServiceAssignment from '../CourierServiceAssignment/CourierServiceAssignment'
import MapAndDeliveryConditions from '../MapAndDeliveryConditions/MapAndDeliveryConditions'
import RegisterForm from '../RegisterCompanyInfo/RegisterForm'
import ProgressBar from '../../shared/ProgressBar'

import ResultMessageModal from './ResultMessageModal'

const RegisterCompanyModal = ({ show, handleClose }) => {
  const [states, setStates] = useState({})
  const [agreements, setAgreements] = useState([])
  const [courierServices, setCourierServices] = useState({})
  const [currentStep, setCurrentStep] = useState(0)
  const [companyData, setCompanyData] = useState([])
  const [successMessage, setSuccessMesssage] = useState('')
  const [errorsList, setErrorsList] = useState('')
  const [showResponseModal, setShowResponseModal] = useState(false)

  const steps = [
    'Llene el formulario con la información del cliente y la empresa',
    'Configura los couriers y tipos de servicio por región',
    'Selecciona la red de puntos a incorporar',
    'Para finalizar crea los mapas y delivery conditions'
  ]

  const handleCloseResultModal = () => {
    setSuccessMesssage('')
    setErrorsList([])
    setShowResponseModal(false)
  }

  useFetch(getHoldingAgreements, setAgreements)
  useFetch(getStates, setStates)
  useFetch(getCourierServices, setCourierServices)

  return (
    <>
      <Modal show={show} handleClose={handleClose}>
        <div className='h-[80vh] w-[80vw]'>
          <div className='container mx-auto'>
            <div className='w-full pb-4'>
              <h1 className='font-light'>{steps[currentStep]}</h1>
              <span className='font-light'>{currentStep + 1} de {steps.length}</span>
            </div>
            <ProgressBar steps={steps.length} currentStep={currentStep}/>
          </div>
          <div className='flex h-4/5 w-full overflow-auto'>
            {currentStep === 0 &&
              (
                <RegisterForm
                  states={states}
                  agreements={agreements}
                  setCurrentStep={setCurrentStep}
                  setCompanyData={setCompanyData}
                  companyData={companyData}
                />
              )
            }
            {currentStep === 1 &&
              (
                <CourierServiceAssignment
                  states={states}
                  setCurrentStep={setCurrentStep}
                  courierServices={courierServices}
                  holdingId={companyData.find(model => model.holdingId)?.holdingId}
                  setSuccessMesssage={setSuccessMesssage}
                  setErrorsList={setErrorsList}
                  setShowResponseModal={setShowResponseModal}
                />
              )
            }
            {currentStep === 2 &&
              (
                <CourierPointSelection
                  setCurrentStep={setCurrentStep}
                  holdingId={companyData.find(model => model.holdingId)?.holdingId}
                  setSuccessMesssage={setSuccessMesssage}
                  setErrorsList={setErrorsList}
                  setShowResponseModal={setShowResponseModal}
                />
              )
            }
            { currentStep === 3 &&
              (
                <MapAndDeliveryConditions
                  setCurrentStep={setCurrentStep}
                  handleCloseModal={handleClose}
                  companyId={companyData.find(model => model.companyId)?.companyId}
                  setSuccessMesssage={setSuccessMesssage}
                  setErrorsList={setErrorsList}
                  setShowResponseModal={setShowResponseModal}
                />
              )
            }
          </div>
        </div>
      </Modal>
      <ResultMessageModal
        errorsList={errorsList}
        successMessage={successMessage}
        show={showResponseModal}
        handleClose={handleCloseResultModal}
      />
    </>
  )
}

export default RegisterCompanyModal
